import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";

export const SpecialDayFlex = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 8px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        gap: 24px;
    }
`;

export const DetailMonthFlex = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 8px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        gap: 24px;
    }
`;

export const Label = styled(Text)`
    color: #252424;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
export const Hours = styled(Text)`
    color: var(#242424);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const GraphLabelFlex = styled(Flex)`
    @media ${DEVICE.mobileS} {
        gap: 16px;
    }

    @media ${DEVICE.tablet} {
        gap: 32px;
    }
`;

export const DeclaredMonth = styled(Flex)`
    border-left: 4px solid #4f52b2;
    height: 40px;
    @media ${DEVICE.mobileS} {
        padding-left: 8px;
    }
    @media ${DEVICE.tablet} {
        padding-left: 16px;
    }
`;
export const ContractualHours = styled(DeclaredMonth)`
    border-color: #e8ebfa;
`;
export const Left = styled(Flex)`
    width: 396px;
`;
export const Right = styled(Flex)``;
export const Title = styled(Text)`
    color: #242424;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;
export const CardDetailWeek = styled(Flex)`
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    width: 176px;
    max-height: 70px;
    border-radius: 4px;
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);

    /* shadow-4 */
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
`;
export const Dates = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;
export const TotalHours = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Brand-Foreground-2, #4f52b2);
    text-align: right;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
`;
export const WrapperCards = styled(Flex)`
    /* row-gap: 1rem; */
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        /* gap: 8px; */
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        gap: 24px;
        flex-wrap: wrap;
    }
`;

export const Graph = styled(Flex)`
    height: ${({ progress }) => progress + "px"};
    background: ${({ primary }) =>
        primary ? "var(--color-primary)" : " #E8EBFA"};
    @media ${DEVICE.mobileS} {
        width: 62px;
    }
    @media ${DEVICE.tablet} {
        width: 88px;
    }
`;
