import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import EmployeeTable from "./EmployeeTable";

export default function DynamicTabContent({
    users,
    TabView,
    AgencyId,
    activeTab,
    userIdSelected,
    setActiveTab,
    isLoading = false,
}) {
    const getUsersByTab = () => {
        switch (activeTab) {
            case 0:
                return users?.waiting_preonboarding_users;
            case 1:
                return users?.preonboarding_users;
            case 2:
                return users?.onboarding_users;
            case 3:
                return users?.end_onboarding_users;
            case 4:
                return users?.active_users;
            default:
                return users?.waiting_preonboarding_users;
        }
    };

    const list_users_depending_tab = getUsersByTab(activeTab);
    const userId = userIdSelected || list_users_depending_tab;

    // Fusionner tous les utilisateurs dans un seul tableau
    const allUsers = [
        users?.waiting_preonboarding_users,
        users?.preonboarding_users,
        users?.onboarding_users,
        users?.end_onboarding_users,
        users?.active_users,
    ];

    const findUserById = (data, userId) => {
        for (const group of data) {
            const user = group?.find((user) => user?.id === userId);
            if (user) return user;
        }
        return null; // Si l'utilisateur n'est pas trouvé
    };

    const user = findUserById(allUsers, userId);

    const findUserAndSetActiveTab = (userId) => {
        // Chercher dans les utilisateurs en attente de préonboarding
        const waitingUser = users?.waiting_preonboarding_users?.find(
            (user) => user?.id === userId
        );
        if (waitingUser) {
            setActiveTab(0); // Onglet 0 correspond aux utilisateurs en attente de préonboarding
            return waitingUser;
        }

        // Chercher dans les utilisateurs de préonboarding
        const preOnboardingUser = users?.preonboarding_users?.find(
            (user) => user?.id === userId
        );
        if (preOnboardingUser) {
            setActiveTab(1); // Onglet 1 correspond aux utilisateurs de préonboarding
            return preOnboardingUser;
        }

        // Chercher dans les utilisateurs en onboarding
        const onboardingUser = users?.onboarding_users?.find(
            (user) => user?.id === userId
        );
        if (onboardingUser) {
            setActiveTab(2); // Onglet 2 correspond aux utilisateurs en onboarding
            return onboardingUser;
        }

        // Chercher dans les utilisateurs ayant terminé l'onboarding
        const endOnboardingUser = users?.end_onboarding_users?.find(
            (user) => user?.id === userId
        );
        if (endOnboardingUser) {
            setActiveTab(3); // Onglet 3 correspond aux utilisateurs ayant terminé l'onboarding
            return endOnboardingUser;
        }

        // Chercher dans les utilisateurs actifs
        const activeUser = users?.active_users?.find(
            (user) => user?.id === userId
        );
        if (activeUser) {
            setActiveTab(4); // Onglet 4 correspond aux utilisateurs actifs
            return activeUser;
        }

        // Si aucun utilisateur n'est trouvé, retourner null
        return null;
    };

    const user199 = findUserAndSetActiveTab(userId);

    return (
        <MainContentOnboarding column>
            <Header fill space="between">
                <LeftText content="Liste des collaborateurs" />
                <Flex vAlign="center" gap="gap.smaller">
                    <Text content="Collaborateurs en " />
                    {activeTab !== 4 ? (
                        <RightText content="période d'essai" />
                    ) : (
                        <RightText content="suivi" />
                    )}
                    <TaskCount>{list_users_depending_tab?.length}</TaskCount>
                </Flex>
            </Header>
            <Flex fill>
                <EmployeeTable
                    users={
                        user199 ? [{ ...user199 }] : list_users_depending_tab
                    }
                    TabView={TabView}
                    AgencyId={AgencyId}
                    activeTab={activeTab}
                    userIdSelected={userIdSelected}
                    isLoading={isLoading}
                />
            </Flex>
        </MainContentOnboarding>
    );
}
const TaskCount = styled(Text)`
    background-color: #0067b8;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 6px 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainContentOnboarding = styled(Flex)`
    border-top: 4px solid #9299f7;
    background: #fff;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
    margin-block: 24px;
    overflow-y: scroll;

    /* Cacher la barre de défilement pour les navigateurs Webkit (Chrome, Safari, etc.) */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Cacher la barre de défilement pour Firefox */
    scrollbar-width: none;

    /* Pour Internet Explorer et Edge */
    -ms-overflow-style: none;
`;

const Header = styled(Flex)`
    padding: 32px 24px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
`;

const LeftText = styled(Text)`
    color: #242424;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const RightText = styled(Text)`
    color: #0067b8;
    font-family: "Segoe UI";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
