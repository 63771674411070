import { Flex, InfoIcon, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

export default function ContentDialogInterruptionAction({ description }) {
    return (
        <Content fill column>
            <Flex vAlign="center" gap="gap.small">
                <InfoIcon />
                <Text
                    content={
                        "Ce statut a pour conséquences sur le collaborateur :"
                    }
                />
            </Flex>
            <Flex column styles={{ marginLeft: "25px" }}>
                {description?.description?.contentDescription?.map(
                    (item, index) => (
                        <li key={index}>
                            <Text content={item} />
                        </li>
                    )
                )}
            </Flex>
            {/* <Text content={description?.header} /> */}
        </Content>
    );
}

const Content = styled(Flex)`
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    background: #e0e0e0;
    /* width: 502px; */
    padding: 6px 0px 6px 16px;
    margin-top: 40px;
`;
