import React, { useState } from "react";
import {
    CardBody,
    CardHeader,
    Container,
    Day,
    HoursCounter,
    HoursText,
    WrapperHours,
} from "../CardWeek/index.style";
import Pills from "../Pills";
import DayType from "../DayType";
import DaySlots from "../DaySlots";
import DialogModifyWeek from "../DialogModifyWeek";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { Flex, Text } from "@fluentui/react-northstar";
import CardWeekSkeleton from "../CardWeekSkeleton";
import DialogModifyWeekTypeTemplate from "../DialogModifyWeekTypeTemplate";
import { useReducer } from "react";
import { reducerTypeTemplate } from "../../reducers/reducer.template_week";
import OverlayEditableDay from "../OverlayEditableDay";

/**
 * @typedef {Object} PropType
 * @property {boolean} is_today
 * @property {{value: number}} type_day
 * @property {Array<>} shifts
 * @property {string} day_name - format Lun. 02
 * @property {number} status_day
 * @property {boolean} is_saved_week
 * @property {number} hours_count
 * @property {boolean} mode_editable_activated
 * @property {boolean} is_loading
 * @property {boolean} can_modify_day
 */

/**
 * CardWeek pour afficher les détail d'une journée.
 * @param {PropType} PropType
 * @returns
 */
const CardWeekTypeTemplate = ({
    is_today = false,
    type_day,
    day_id,
    shifts,
    day_name,
    status_day,
    hours_count,
    is_loading = false,
    can_modify_day,
    data_day,
    days,
    dispatchTypeTemplate,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const [isHoverBody, setIsHoverBody] = useState(false);
    const [openDefault, setOpenDefault] = useState(false);

    if (is_loading) {
        return <CardWeekSkeleton />;
    }

    const isEmptySlot = type_day.value === 0 && shifts?.length === 0;
    const isLeave = type_day.value === 2;
    const isNotWorking = type_day.value === 3;
    const isHoliday = type_day.value === 1;
    const isWeeklyRest = type_day.value === 4;

    return (
        <Container
            fill
            column
            styles={{
                width: "175px",
                position: "relative",
            }}
        >
            <CardHeader is_today={is_today} vAlign="center" space="between">
                <Day content={day_name} is_today={is_today} />
                {can_modify_day && !isWeeklyRest && (
                    <DialogModifyWeekTypeTemplate
                        shifts={shifts}
                        day_id={day_id}
                        dispatchTypeTemplate={dispatchTypeTemplate}
                        openDefault={openDefault}
                        onCancel={() => {
                            setOpenDefault(false);
                        }}
                    />
                )}
            </CardHeader>
            <Flex
                fill
                column
                styles={{ position: "relative" }}
                onMouseEnter={() => {
                    return setIsHoverBody(true);
                }}
                onMouseLeave={() => {
                    return setIsHoverBody(false);
                }}
            >
                {can_modify_day &&
                    isHoverBody &&
                    !isWeeklyRest &&
                    !isHoliday &&
                    !isLeave && (
                        <OverlayEditableDay
                            day_data={data_day}
                            shifts={shifts}
                            day_id={day_id}
                            onConfirm={() => setIsHoverBody(false)}
                            onCancel={() => setIsHoverBody(false)}
                            dispatchTypeTemplate={dispatchTypeTemplate}
                            onClickTrigger={() => setOpenDefault(true)}
                        />
                    )}
                <Flex hAlign="center" styles={{ height: "80px" }}>
                    <WrapperHours vAlign="end" hAlign="center">
                        <HoursCounter content={hours_count} />
                        <HoursText content={t("v2.home.labels.hours_count")} />
                    </WrapperHours>
                </Flex>
                <CardBody fill hAlign="center" column>
                    {/* pills empty slots */}
                    {isHoliday && <Pills is_holiday={true} />}
                    {isEmptySlot && <Pills />}
                    {/* repos hebdo, jour non travaillé */}
                    {(isNotWorking || isWeeklyRest) && (
                        <DayType
                            is_not_working={isNotWorking}
                            is_weekly_rest={isWeeklyRest}
                        />
                    )}
                    {/* jour de travail */}
                    {shifts?.length !== 0 && (
                        <DaySlots
                            shifts={shifts}
                            value_day_type={type_day?.value}
                            status_day={status_day}
                            data_day={data_day}
                        />
                    )}
                </CardBody>
            </Flex>
        </Container>
    );
};

export default CardWeekTypeTemplate;
