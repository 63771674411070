import React from "react";
import { TextLeave, Wrapper } from "./index.style.js";

const SlotNotWorking = ({ start_time = null, end_time = null }) => {
    return (
        <Wrapper gap="gap.small" vAlign="center" hAlign="center">
            <TextLeave
                content={`Non-travaillé : ${start_time} - ${end_time}`}
            />
        </Wrapper>
    );
};

export default SlotNotWorking;
