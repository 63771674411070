import React, { useEffect } from "react";
import { Alert, CloseIcon } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { CalendarEdit16Regular } from "@fluentui/react-icons";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { ButtonModify } from "../CardWeek/index.style";
import { DialogStyled } from "./index.style";
import Content from "./Content";
import { useReducer } from "react";
import { ACTION_TYPES_SLOTS, reducerSlots } from "../../reducers/reducer.slots";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { _api_update_day_slot } from "../../api";
import { toast } from "sonner";
import { useMediaQuery } from "react-responsive";

const DialogModifyWeek = ({
    shifts,
    openDefault,
    day_id,
    day_data,
    update_button = null,
    onConfirm = () => null,
    onCancel = () => null,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const [open, setOpen] = useState(false);
    const [state, dispatch] = useReducer(reducerSlots, []);
    const [commentDay, setCommentDay] = useState(day_data.comment ?? "");
const [commentDayError, setCommentDayError] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

useEffect(() => {
        dispatch({
            type: ACTION_TYPES_SLOTS.set_shifts,
            payload: {
                shifts,
            },
        });
    }, [shifts]);

    useEffect(() => {
        setOpen(openDefault);
    }, [openDefault]);

    const queryClient = useQueryClient();
    // remove the BREAK slot
    const shifts_worked = state.filter((shift) => shift?.type?.value !== -1);

    const isFieldsContainError = shifts_worked.filter(
        (field) => field?.errors?.f1 || field?.errors?.f2
    )?.length;

    const { mutate, isLoading } = useMutation((data) =>
        _api_update_day_slot(data)
    );

    const shifts_from_badgeuse = day_data?.shifts_from_badge;

    return (
        <DialogStyled
            open={open}
            cancelButton={{
                content: t("common.cancel"),
                flat: true,
                style: { order: isMobile ? 2 : 0, gap: "24px" },
            }}
            confirmButton={{
                content: t("v2.home.dialogs.buttons.save_this_day"),
                flat: true,
                loading: isLoading,
                disabled: isFieldsContainError,
                style: { order: 1 },
            }}
            onConfirm={(e) => {
                e.preventDefault();
                // il a utilisé la badgeuse sur ce jour.
                // donc le commentaire est obligatoire.
                const trim_comment = commentDay?.trim();
                if (shifts_from_badgeuse && !trim_comment) {
                    return setCommentDayError(true);
                }

                const worked_hours = state.filter(
                    (shift) => shift.type.value === 0
                );

                mutate(
                    {
                        day_id,
                        data: {
                            slots: worked_hours.map((wh) => {
                                return {
                                    id: wh.errors ? 0 : wh?.id,
                                    start_hour: wh.start_time,
                                    end_hour: wh.end_time,
                                };
                            }),
                            comment: commentDay,
                        },
                    },
                    {
                        onSuccess: (data) => {
                            if (data?.success) {
                                setOpen(false);
                                queryClient.refetchQueries([
                                    "time_reporting::get_time_reporting",
                                ]);
                                onConfirm();
                            } else {
                                console.error("add toast alert !", data);
                            }
                        },
                    }
                );
            }}
            onCancel={() => {
                onCancel();
                return setOpen(false);
            }}
            content={
                <Content
                    day_data={day_data}
                    shifts={shifts_worked}
                    dispatch={dispatch}
                    shifts_from_badgeuse={shifts_from_badgeuse}
                    comment={commentDay}
                    setComment={setCommentDay}
                    commentError={commentDayError}
                    setError={setCommentDayError}
                />
            }
            header={t("v2.home.dialogs.titles.modify_one_day")}
            headerAction={{
                icon: <CloseIcon />,
                onClick: () => {
                    onCancel();
                    return setOpen(false);
                },
            }}
            trigger={
                update_button ? (
                    update_button
                ) : (
                    <ButtonModify
                        icon={<CalendarEdit16Regular />}
                        iconOnly
                        flat
                        size="small"
                        onClick={() => setOpen(true)}
                    />
                )
            }
        />
    );
};

export default DialogModifyWeek;
