/* eslint-disable no-constant-condition */
import React, { useReducer } from "react";
import {
    Container,
    HoursPlanning,
    MainHeader,
    ProgressBar,
    Title,
    WrappedHeader,
} from "./index.style";
import {
    Button,
    Checkbox,
    ChevronStartIcon,
    Flex,
    Skeleton,
    Text,
} from "@fluentui/react-northstar";
import { WrapperWeek } from "../Home/index.style";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { ACTION_TYPES } from "../../reducers/reducer.weeks";
import {
    ContractualHours,
    DeclaredMonth,
    Hours,
    Label,
} from "../../components/DialogSubmitMonth/index.style";
import { useMutation, useQuery } from "react-query";
import { _api_get_template_week, _api_save_template_week } from "../../api";
import { _is_today, _str_capitalize } from "../../../../utils/functions";
import CardWeekTypeTemplate from "../../components/CardWeekTypeTemplate";
import {
    ACTION_TYPES_TEMPLATE,
    reducerTypeTemplate,
} from "../../reducers/reducer.template_week";
import useToast from "../../../../hooks/useToast";
import Toast from "../../../../components/Alerts";
import CardWeekSkeleton from "../../components/CardWeekSkeleton";
import { useShowWeekend } from "../../stores";
import { use } from "i18next";
import { useMediaQuery } from "react-responsive";

const ConfigureWeekTemplate = ({ dispatch }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const ctx_setShow = useShowWeekend((state) => state.setShow);

    const [visible, setVisible] = useToast();
    const [state, dispatchTypeTemplate] = useReducer(reducerTypeTemplate, null);

    const { data, isLoading, isFetching } = useQuery(
        ["TIME_REPORTING::get_template_week"],
        () => _api_get_template_week(),
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                const res = data?.days?.map((day) => ({
                    ...day,
                    slots: day?.slots?.map((slot) => ({
                        ...slot,
                        day_id: day.id,
                    })),
                }));
                return dispatchTypeTemplate({
                    type: ACTION_TYPES_TEMPLATE.init,
                    payload: {
                        data: res,
                        display_weekend: data?.display_weekend,
                    },
                });
            },
        }
    );

    const {
        mutate: mutate_save_template_week,
        isLoading: is_saving_template_week,
    } = useMutation((data) => {
        return _api_save_template_week(data);
    });

    const expected_hours = data?.expected_hours;
    const display_weekend = data?.display_weekend;
    const duration = state?.data?.reduce(
        (prev, curr) => {
            return { duration: prev?.duration + curr?.duration };
        },
        {
            duration: 0,
        }
    )?.duration;

    const progress_bar = ((duration * 100) / Number(expected_hours)).toFixed(2);

    const is_button_save_disabled = !state?.is_dirty;

    return (
        <Flex column fill>
            <Container column fill gap="gap.large">
                <Toast
                    {...visible}
                    onStatusChange={() => {
                        return setVisible((state) => {
                            return {
                                ...state,
                                visible: false,
                            };
                        });
                    }}
                />
                <WrappedHeader space="between" vAlign="center">
                    <Flex fill hAlign="start" gap="gap.small" vAlign="center">
                        <Button
                            icon={
                                <ChevronStartIcon
                                    styles={{
                                        color: "var(--color-primary)",
                                        cursor: "pointer",
                                    }}
                                />
                            }
                            onClick={() => {
                                return dispatch({
                                    type: ACTION_TYPES.mode_update_week_template,
                                    payload: {
                                        value: false,
                                    },
                                });
                            }}
                            text
                            iconOnly
                            primary
                        />
                        <Title content={t("v2.update_template_week.title")} />
                    </Flex>
                    {!isMobile && (
                        <Flex hAlign="center">
                            <Button
                                content={t(
                                    "v2.update_template_week.buttons.save_template_week"
                                )}
                                primary
                                flat
                                loading={is_saving_template_week}
                                disabled={is_button_save_disabled}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    mutate_save_template_week(
                                        {
                                            display_weekend:
                                                state.display_weekend,
                                            slots: state?.data
                                                ?.map((day) => day.slots)
                                                .flat()
                                                .filter((day) => !day?.type)
                                                .map((day) => {
                                                    return {
                                                        day_id: day.day_id,
                                                        start_hour:
                                                            day.start_time,
                                                        end_hour: day.end_time,
                                                    };
                                                }),
                                        },
                                        {
                                            onSuccess: (data) => {
                                                if (data?.success) {
                                                    const show_weekends =
                                                        data?.week_template
                                                            ?.display_weekend;
                                                    setVisible({
                                                        visible: true,
                                                        content: t(
                                                            "v2.update_template_week.toast.week_saved"
                                                        ),
                                                        level: "success",
                                                    });
                                                    dispatch({
                                                        type: ACTION_TYPES.toggle_weekend,
                                                        payload: {
                                                            value: data
                                                                ?.week_template
                                                                ?.display_weekend,
                                                        },
                                                    });
                                                    ctx_setShow(show_weekends);
                                                    return dispatchTypeTemplate(
                                                        {
                                                            type: ACTION_TYPES_TEMPLATE.set_is_dirty,
                                                            payload: {
                                                                value: false,
                                                            },
                                                        }
                                                    );
                                                }
                                            },
                                        }
                                    );
                                    return;
                                }}
                            />
                        </Flex>
                    )}
                </WrappedHeader>
                <WrapperWeek
                    // styles={{ outline: "2px dashed red" }}
                    fill
                    column
                    gap="gap.large"
                >
                    <Flex styles={{ padding: isMobile ? "16px 8px" : "0px" }}>
                        <MainHeader
                            fill
                            // space="between"

                            vAlign="center"
                            gap="gap.large"
                        >
                            <HoursPlanning
                                content={t("v2.update_template_week.sub_title")}
                            />
                            {!isMobile && (
                                <ProgressBar progress={progress_bar} />
                            )}
                            <Flex vAlign="center" gap="gap.large">
                                <DeclaredMonth column>
                                    {isLoading || isFetching ? (
                                        <Skeleton animation="pulse">
                                            <Skeleton.Line
                                                width="50px"
                                                height="22px"
                                            />
                                        </Skeleton>
                                    ) : (
                                        <Hours content={`${duration}h`} />
                                    )}
                                    <Label
                                        content={t(
                                            "v2.update_template_week.labels.configured_text"
                                        )}
                                    />
                                </DeclaredMonth>
                                <ContractualHours column>
                                    {isLoading || isFetching ? (
                                        <Skeleton animation="pulse">
                                            <Skeleton.Line
                                                width="50px"
                                                height="22px"
                                            />
                                        </Skeleton>
                                    ) : (
                                        <Hours content={`${expected_hours}h`} />
                                    )}
                                    <Label
                                        content={t(
                                            "v2.home.labels.hours_contractuals"
                                        )}
                                    />
                                </ContractualHours>
                            </Flex>
                        </MainHeader>
                        {!isMobile && (
                            <Flex>
                                {isLoading || isFetching ? (
                                    <Skeleton animation="pulse">
                                        <Flex gap="gap.small" vAlign="center">
                                            <Skeleton.Line
                                                width="15px"
                                                height="15px"
                                            />
                                            <Skeleton.Line
                                                width="300px"
                                                height="25px"
                                            />
                                        </Flex>
                                    </Skeleton>
                                ) : (
                                    <Checkbox
                                        label={t(
                                            "v2.update_template_week.checkboxs.hide_weekends"
                                        )}
                                        defaultChecked={display_weekend}
                                        value={state.display_weekend}
                                        toggle
                                        onChange={(_, { checked }) => {
                                            return dispatchTypeTemplate({
                                                type: ACTION_TYPES_TEMPLATE.set_display_weekend,
                                                payload: {
                                                    value: checked,
                                                },
                                            });
                                        }}
                                    />
                                )}
                            </Flex>
                        )}
                    </Flex>
                    <Flex
                        styles={{
                            // outline: "2px dashed cyan",
                            paddingInline: isMobile ? "8px" : "0px",
                            overflowX: "scroll",
                            justifyContent: isMobile ? "flex-start" : "center",
                            gap: state?.display_weekend ? "8px" : "24px",
                        }}
                        // gap={display_weekend ? "gap.small" : "gap.large"}
                        // hAlign="center"
                    >
                        {isLoading ? (
                            <CardWeekSkeleton />
                        ) : (
                            state?.data?.map((day, index) => {
                                if (
                                    !state.display_weekend &&
                                    [5, 6].includes(index)
                                ) {
                                    return;
                                }

                                const {
                                    id,
                                    slots,
                                    type: type_day,
                                    duration,
                                    name,
                                } = day;

                                const can_modify_day = type_day.value !== 3;

                                return (
                                    <Flex styles={{ paddingBottom: "24px" }}>
                                        <CardWeekTypeTemplate
                                            is_today={false}
                                            key={id}
                                            day_id={id}
                                            hours_count={duration}
                                            shifts={slots}
                                            type_day={type_day}
                                            day_name={name}
                                            status_day={0}
                                            can_modify_day={can_modify_day}
                                            data_day={day}
                                            is_loading={isLoading}
                                            days={state}
                                            dispatchTypeTemplate={
                                                dispatchTypeTemplate
                                            }
                                        />
                                    </Flex>
                                );
                            })
                        )}
                    </Flex>
                </WrapperWeek>
            </Container>
            <Flex fill>
                {isMobile && (
                    <Button
                        fluid
                        content={t(
                            "v2.update_template_week.buttons.save_template_week"
                        )}
                        primary
                        flat
                        loading={is_saving_template_week}
                        disabled={is_button_save_disabled}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            mutate_save_template_week(
                                {
                                    display_weekend: state.display_weekend,
                                    slots: state?.data
                                        ?.map((day) => day.slots)
                                        .flat()
                                        .filter((day) => !day?.type)
                                        .map((day) => {
                                            return {
                                                day_id: day.day_id,
                                                start_hour: day.start_time,
                                                end_hour: day.end_time,
                                            };
                                        }),
                                },
                                {
                                    onSuccess: (data) => {
                                        if (data?.success) {
                                            const show_weekends =
                                                data?.week_template
                                                    ?.display_weekend;
                                            setVisible({
                                                visible: true,
                                                content: t(
                                                    "v2.update_template_week.toast.week_saved"
                                                ),
                                                level: "success",
                                            });
                                            dispatch({
                                                type: ACTION_TYPES.toggle_weekend,
                                                payload: {
                                                    value: data?.week_template
                                                        ?.display_weekend,
                                                },
                                            });
                                            ctx_setShow(show_weekends);
                                            return dispatchTypeTemplate({
                                                type: ACTION_TYPES_TEMPLATE.set_is_dirty,
                                                payload: {
                                                    value: false,
                                                },
                                            });
                                        }
                                    },
                                }
                            );
                            return;
                        }}
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default ConfigureWeekTemplate;
