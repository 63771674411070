import { Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const TextLabel = styled(Text)`
    color: #424242;
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
`;
export const GlobalLabel = styled(Text)`
    color: #424242;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
`;
export const TextDate = styled(Text)`
    color: #242424;
    text-align: center;
    font-size: clamp(12px, 2vw, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const TextType = styled(Text)`
    color: black;
    width: 100%;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
`;
