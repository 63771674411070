import { useAuthStore } from "../stores/useAuthStore";
import { logout, refreshToken } from "../api/auth";
import i18next from "./i18n";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";

const _originalRequest = async (url, config) => {
    let response = await fetch(url, config);
    let data = await response.json();
    return {
        response,
        data,
    };
};

let isRefreshingToken = false;

const sendOrgFetch = async (url, config) => {
    const user = useAuthStore.getState().infos;
    // console.log("USER => ", { user });
    const lang = i18next.language;
    // console.log("LANG => ", { lang });
    var isExpiredToken = false;
    var access_token = "";
    if (!user) {
        // console.log("je suis dans le !user => ", { user });
        isExpiredToken = true;
    } else {
        // console.log("le user exist", { user });
        // verifier dabord le token si il est expiré ou pas.
        access_token = jwtDecode(user?.access);
        // console.log("jwt_decode => ", { access_token });
        isExpiredToken = dayjs.unix(access_token?.exp).diff(dayjs()) < 1;
        // console.log("isExpiredToken => ", { isExpiredToken });
    }

    if (isExpiredToken) {
        isRefreshingToken = true;
        // console.log("set the isRefreshing to True => ", { isRefreshingToken });
        // console.log("⚡waiting data from refreshToken ", { isRefreshingToken });
        const { data: newRefreshData } = await refreshToken();
        // console.log("🆗 response refresh token ", { newRefreshData });
        config["headers"] = {
            Authorization: `Bearer ${newRefreshData?.access}`,
            "Content-type": "application/json",
            "Accept-Language": lang,
        };
        // console.log("⚡waiting response from original request ");
        let newResponseAfterRefresh = await _originalRequest(url, config);
        // console.log("🆗 response originalRequest DONE ", {
        //     newResponseAfterRefresh,
        // });
        newResponseAfterRefresh.data.res = newResponseAfterRefresh.response;
        isRefreshingToken = false;
        // console.groupEnd();
        return newResponseAfterRefresh?.data;
    } else {
        // console.log("🆗 le token est valide ", { isExpiredToken });
        // send request, the token is valid
        config["headers"] = {
            Authorization: `Bearer ${user?.access}`,
            "Content-type": "application/json",
            "Accept-Language": lang,
        };
        let new_data = await _originalRequest(url, config);
        // console.log("🆗 response originalRequest DONE ", {
        //     new_data,
        // });
        if (new_data?.response?.status === 400) {
            // console.log("le status is 400 => throw error", {
            //     message: new_data?.data?.message,
            // });
            // console.groupEnd();
            return {
                success: false,
                error: true,
                message: new_data?.data?.message,
            };
            // throw Error(new_data?.data?.message);
        }
        if (
            new_data?.response?.status === 401 &&
            new_data?.data?.code === "no_refresh_token_found"
        ) {
            // console.log("le status is 401 => LOGOUT USER");
            // console.groupEnd();
            await logout();
            return console.log("logout the user");
        }
        new_data.data.res = new_data?.response;
        // console.log("Send response to the user => ", {
        //     new_data,
        // });
        // console.groupEnd();
        return new_data?.data;
    }
};

export const fetcher = async (url, config = {}) => {
    // console.group("FETCHER ********************************");
    // console.log("PARAM recu => ", { url, config });
    if (isRefreshingToken) {
        // console.log("je suis dans le isRefreshingToken", { isRefreshingToken });
        // le token est entrain de se refresh.
        const promise = new Promise((resolve, reject) => {
            const itv = setInterval(() => {
                if (isRefreshingToken === false) {
                    // the refresh is finish
                    resolve();
                    clearInterval(itv);
                }
            }, 500);
        });
        await promise;
        // console.log("Promise resolved !!");
        return sendOrgFetch(url, config);
    } else {
        return sendOrgFetch(url, config);
    }
};

// download any type of file
/**
 * @typedef {Object} Args
 * @property {String} url - URL
 * @property {Object} headers - all/some headers from fetch
 * @property {Object} method - any methods GET-POST-etc..
 * @property {Function} cbOnEndDownload - callback function when success or not
 *
 * @param {Args} parametres
 * @returns
 */
export const _api_download_file = async ({
    url = "",
    headers,
    method = "GET",
    cbOnEndDownload = () => console.log("downloaded"),
}) => {
    if (!url) {
        cbOnEndDownload(null, true, "No URL found");
        return new Error("No URL found");
    }
    const detail = useAuthStore.getState().infos;

    return await fetch(url, {
        headers: {
            Authorization: "Bearer " + detail.access,
            ...headers,
        },
        method: method,
        credentials: "include",
    })
        .then(async (res) => {
            if (res.status === 400) {
                const json_response = await res.json();
                cbOnEndDownload(null, true, json_response.message);
                throw new Error(json_response.message);
            }
            const contentDisposition = res.headers.get("Content-Disposition");
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            let filename = "export_onboarding.xlsx";
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
            }

            const blob = await res.blob();
            return { blob, filename };
        })
        .then(({ blob, filename }) => {
            let a = document.createElement("a");
            if (window.URL && window.URL.createObjectURL) {
                cbOnEndDownload(true, null);
                let objectUrl = window.URL.createObjectURL(blob);
                a.href = objectUrl;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(objectUrl);
            } else {
                // Fallback for browsers that do not support createObjectURL
                // You may need to handle this case differently based on your requirements
                cbOnEndDownload(null, true);
                alert(
                    "Your browser does not support this feature. Please try another browser."
                );
            }
        })
        .catch((error) => {
            cbOnEndDownload(null, true, error.message);
            console.error("Error fetching or processing data:", error.message);
        });
};

export default fetcher;
