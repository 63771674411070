import React from "react";
import { Flex, Loader, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import LabelView from "../../../LabelView";
import {
    ACTIVITY_RATE,
    PACKAGE_TYPE,
    RTT_POLICY,
    SALARY_PERIOD,
    SALARY_TYPE,
} from "../../../../../../constants/lists";
import { useQuery } from "react-query";
import { QUERY_KEY } from "../../../../constants";
import { _api_get_leave_policy } from "../../../../api";
import { get_symbol_currency } from "../../../../../expenses/functions";
import { useState } from "react";
import Planning from "./Planning";

const TimeJobAndSalaryContent = ({
    activity_rate,
    part_time_percentage,
    package_type,
    hour_count_per_week,
    day_count_per_year,
    rtt_policy,
    leave_policy,
    salary,
    contract_country,
    timetable = [],
}) => {
    // GET leave policy
    const { data: leave_policy_data, isLoading: leave_policy_data_loading } =
        useQuery(
            [QUERY_KEY + "::get_leave_policy"],
            () => _api_get_leave_policy(),
            {
                refetchOnWindowFocus: false,
            }
        );

    if (leave_policy_data_loading) {
        return <Loader content="chargement ..." />;
    }

    const format_activity_rate = ACTIVITY_RATE.find(
        (ar) => ar.id === activity_rate?.id
    );

    const format_package_type = PACKAGE_TYPE.find(
        (pt) => pt.id === package_type?.id
    );

    // const format_rtt_policy = RTT_POLICY.find(
    //     (rtt) => rtt.id === rtt_policy?.id
    // );

    const format_leave_policy = leave_policy_data.find(
        (lp) => lp.id === leave_policy?.id
    );

    // const format_salary_type = SALARY_TYPE.find(
    //     (st) => st.id === salary?.salary_type
    // );

    // const format_salary_period = SALARY_PERIOD.find(
    //     (sp) => sp.id === salary?.salary_period
    // );

    // const format_currency = get_symbol_currency(salary?.currency);

    const is_fr = contract_country?.toLowerCase() === "fr";
    const is_ch = contract_country?.toLowerCase() === "ch";

    const isTypePerHours = format_package_type?.id === 1;

    return (
        <Flex fill column gap="gap.medium">
            <Title content="Temps de travail" />
            {/* planning */}
            <div>
                <Planning mode="reading" timetable={timetable} />
            </div>
            {/* rest */}
            <LabelView
                label="Activité contractuelle"
                content={format_activity_rate?.name}
            />
            <LabelView
                label="Taux d’activité"
                content={part_time_percentage + " %"}
            />
            {is_fr && (
                <>
                    <LabelView
                        label="Forfait"
                        content={format_package_type?.name}
                    />
                    {isTypePerHours ? ( // heures
                        <>
                            <LabelView
                                label="Heures travaillées par semaine"
                                content={hour_count_per_week}
                            />
                        </>
                    ) : (
                        // jours
                        <>
                            <LabelView
                                label="Nombre de jours travaillés par an"
                                content={day_count_per_year}
                            />
                        </>
                    )}
                </>
            )}
            {is_ch && (
                <>
                    <LabelView
                        label="Heures travaillées par semaine"
                        content={hour_count_per_week}
                    />
                </>
            )}
            <LabelView
                label="Politique d’absence"
                content={format_leave_policy?.name}
            />
            {/* salaire */}
            {/* <Title content="Salaire" />
            <LabelView
                label="Valeur du salaire fixe"
                content={format_salary_type?.name}
            />

            <LabelView
                label="Salaire fixe"
                content={`${salary?.amount} ${format_currency}`}
            />
            <LabelView
                label="Temporalité du salaire"
                content={format_salary_period?.name}
            /> */}
        </Flex>
    );
};

export default TimeJobAndSalaryContent;

export const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`;
