import React, { useState } from "react";
import styled from "styled-components";
import { Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import { AppsListDetailFilled } from "@fluentui/react-icons";

import { useQuery } from "react-query";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
    get_theme_levels_color,
    LEVELS_BACKEND,
} from "../../components/Badges/Status";
import { LEVELS } from "../../components/Badges/StatusFilled";
import { I18N_NS_EXPENSE, QUERY_KEY, TYPE_FORM } from "../../constants";
import { _api_get_detail_report_manager } from "../../api";
import { Container, ContainerFluid } from "../../../../common/styles";
import RowsExpenseDetail from "../../components/Tables/RowsExpenseDetail";
import RowTotalApproved from "../ExpensesDetailReport/RowTotalApproved";
import RowTotal from "../ExpensesDetailReport/RowTotal";
import RowCodeBudget from "../ExpensesDetailReport/RowCodeBudget";
import EvolutionCard from "../ExpensesDetailReport/EvolutionCard";
import RefuseExpense from "./Dialogs/RefuseExpense";
import AcceptExpense from "./Dialogs/AcceptExpense";
import RowTotalRefused from "../ExpensesDetailReport/RowTotalRefused";
import { get_symbol_currency } from "../../functions";
import AcceptAnotherAmountExpense from "./Dialogs/AcceptAnotherAmount";
import { ROUTES } from "../../../../constants/routes";
import { STATUS_ROLE } from "../../components/Badges/StatusActionsDependRole";
import HeaderDetailExpenseReport from "../ExpensesDetailReport/HeaderDetailExpenseReport/HeaderDetailExpenseReport";
import EmbedAndCommentsCard from "../../components/EmbedAndCommentsCard/EmbedAndCommentsCard";
import Toast from "../../../../components/Alerts";
import useToast from "../../../../hooks/useToast";
import { useMediaQuery } from "react-responsive";
import { DEVICE, SIZES } from "../../../../constants/responsive";
import Reinvoiceable from "../../components/Badges/Reinvoiceable";

const ExpensesDetailReportAccountingManager = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const navigate = useNavigate();
    const { id_report, id_user } = useParams();

    // states
    const [toggleComment, setToggleComment] = useState(false);
    const [comments, setComments] = useState([]); // used for toggle between comments card.
    const [embed, setEmbed] = useState(null);
    const [selectedExpense, setSelectedExpense] = useState(null);

    const on_click_comment = (expense) => {
        const { histories, id } = expense;
        setComments(histories);
        setToggleComment(false);
        setSelectedExpense(id);
        return;
    };

    const on_click_view_document = (url_document) => {
        setToggleComment(false);
        return setEmbed(url_document);
    };

    const _goback = () => {
        return navigate(-1);
    };

    const {
        data: detail,
        isLoading,
        isFetching,
        refetch: refetch_detail_report,
    } = useQuery([QUERY_KEY + "::report_detail"], () =>
        _api_get_detail_report_manager({
            id_expense_report: id_report,
            id_user,
        })
    );

    const _onAcceptRefuseExpense = ([data, errors]) => {
        if (data?.success) {
            const redirect_url = `${ROUTES.expenses.home}/${ROUTES.expenses.accounting_manager.home.route}`;
            // afficher un Toast success here
            return navigate(redirect_url, {
                state: {
                    visible: true,
                    content: "Note de frais approuvée",
                    level: "success",
                },
            });
            // refetch_detail_report();
        } else {
            // show alert message here !
            console.log({ errors });
        }
        return;
    };

    if (isLoading) {
        return <Loader />;
    }

    const {
        name,
        created_date,
        reinvoiceable,
        client,
        expenses,
        history,
        status,
        amount_total,
        currency,
        amount_approved,
        user,
        object_code,
    } = detail?.expense_report;

    const { textColor } = get_theme_levels_color(LEVELS_BACKEND[status?.value]); // this is used for showing colors : red, green, yellow

    const currency_symbol = get_symbol_currency(currency);

    return (
        <ContainerFluid styles={{ marginTop: "24px" }}>
            <ContainerStyle gap="gap.large">
                <Wrapper
                    fill
                    column
                    gap="gap.small"
                    styles={{
                        height: "fit-content",
                        // border: "3px solid cyan",
                        paddingInline: "10px",
                    }}
                >
                    {isFetching && <Loader size={"smallest"} />}
                    <Flex column fill gap="gap.medium">
                        <Flex fill space="between" vAlign="center">
                            <Flex vAlign="center" gap="gap.small">
                                <Title content={name} />
                                {reinvoiceable && (
                                    <Reinvoiceable client_name={client?.name} />
                                )}
                            </Flex>
                            <Button
                                content={t("common.back")}
                                onClick={_goback}
                                text
                                primary
                                iconOnly
                            />
                        </Flex>
                        <Flex space="between" fill vAlign="center">
                            <Flex column gap="gap.smaller">
                                <CreatedAt
                                    content={
                                        t("report.header.create_at") +
                                        moment(created_date).format(
                                            "DD/MM/YYYY"
                                        )
                                    }
                                />
                            </Flex>
                            <Flex column vAlign="end">
                                <ButtonEvolution
                                    content={history?.length}
                                    icon={<AppsListDetailFilled />}
                                    onClick={() => {
                                        setToggleComment((state) => !state);
                                        setComments([]);
                                        setEmbed(null);
                                        return;
                                    }}
                                    iconPosition={"after"}
                                    active={toggleComment}
                                    text
                                    primary
                                    iconOnly
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                    {/* white section */}
                    <WhiteBoard fill column gap="gap.large">
                        {/* header */}
                        <Flex styles={{ padding: "16px 16px 0px 16px" }}>
                            <HeaderDetailExpenseReport
                                status={status}
                                user={user}
                                role={STATUS_ROLE.accounting_manager}
                            />
                        </Flex>
                        <FlexBody>
                            <RowsExpenseDetail
                                selectedExpense={selectedExpense}
                                is_title_clickable={
                                    detail?.expense_report?.status?.value === 2
                                }
                                expenses={expenses}
                                refetch_get_all_expenses={() =>
                                    console.log("refesh")
                                }
                                can_i_delete={false}
                                on_click_comment={on_click_comment}
                                id_expense_of_selected_comment={null}
                                is_accounting_manager={true}
                                note_status={status?.value}
                                id_report={id_report}
                                onClickTitle={({
                                    id_expense,
                                    form_type,
                                    id_report,
                                }) => {
                                    const expense_status =
                                        detail?.expense_report?.status?.value;

                                    if (expense_status === 3) {
                                        // en cours de création
                                        return navigate(
                                            ROUTES.expenses.update_form.custom_route(
                                                id_report,
                                                id_expense,
                                                TYPE_FORM[form_type]
                                            )
                                        );
                                    }
                                }}
                                on_click_view_document={on_click_view_document}
                            />
                        </FlexBody>
                        <FlexFooter column gap="gap.medium">
                            <RowTotal
                                total={amount_total}
                                currency={currency_symbol}
                            />
                            {LEVELS_BACKEND[status?.value] ===
                                LEVELS.refuse && (
                                <RowTotalRefused
                                    total={"--,--"}
                                    currency={currency_symbol}
                                    color={textColor}
                                />
                            )}
                            {amount_approved && (
                                <RowTotalApproved
                                    total={amount_approved}
                                    currency={currency_symbol}
                                    color={textColor}
                                />
                            )}
                            {object_code?.name && (
                                <RowCodeBudget code={object_code?.name} />
                            )}
                            {/* le status est a : "A verifier" */}
                            {status?.value === 3 && (
                                <Flex
                                    hAlign="center"
                                    fill
                                    column
                                    gap="gap.medium"
                                    styles={{ marginTop: "30px" }}
                                >
                                    <Flex gap="gap.small">
                                        {/* refuse expense */}
                                        <RefuseExpense
                                            id_report={id_report}
                                            id_user={user?.id}
                                            onAccept={_onAcceptRefuseExpense}
                                            isAccountingManager={true}
                                        />
                                        <AcceptExpense
                                            id_report={id_report}
                                            id_user={user?.id}
                                            amount_number={amount_total}
                                            amount={
                                                amount_total +
                                                " " +
                                                currency_symbol
                                            }
                                            client={client}
                                            reinvoiceable={reinvoiceable}
                                            isAccountingManager={true}
                                            onAccept={_onAcceptRefuseExpense}
                                        />
                                    </Flex>
                                    <Flex>
                                        <AcceptAnotherAmountExpense
                                            id_report={id_report}
                                            id_user={user?.id}
                                            amount={amount_total}
                                            currency={currency_symbol}
                                            isAccountingManager={true}
                                            onAccept={_onAcceptRefuseExpense}
                                        />
                                    </Flex>
                                </Flex>
                            )}
                        </FlexFooter>
                    </WhiteBoard>
                </Wrapper>
                {/* right section */}
                <Flex>
                    {toggleComment && (
                        <Flex
                            fill
                            styles={{
                                marginTop: "8px",
                            }}
                        >
                            <EvolutionCard
                                history={history}
                                amount_approved={amount_approved}
                                currency={currency_symbol}
                                setToggleComment={setToggleComment}
                            />
                        </Flex>
                    )}
                </Flex>
                <ExpensesCommentCard fill>
                    {(comments.length || embed) && (
                        <EmbedAndCommentsCard
                            comments={comments}
                            embed={embed}
                            onClose={() => {
                                setEmbed(null);
                                setComments([]);
                                setSelectedExpense(null);
                            }}
                        />
                    )}
                </ExpensesCommentCard>
            </ContainerStyle>
        </ContainerFluid>
    );
};

export default ExpensesDetailReportAccountingManager;
const ContainerStyle = styled(Flex)`
    max-width: 1440px;
    margin: 0 auto;
    @media ${DEVICE.mobileS || DEVICE.mobileM || DEVICE.mobileL} {
        width: 100%;
        max-width: auto;
        flex-direction: column;
        padding: 0px;
        gap: 12px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 6px;
        padding: 12px;
    }
    @media ${DEVICE.laptopL} {
        flex-direction: row;
        padding: 12px;
    }
`;
const ExpensesCommentCard = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 300px;
        margin: 0 auto;
    }
    @media ${DEVICE.mobileM} {
        width: 355px;
        margin: 0 auto;
    }
    @media ${DEVICE.mobileL} {
        width: 405px;
        margin: 0 auto;
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        margin: 0;
    }
    @media ${DEVICE.laptopL} {
        margin: 0;
    }
`;

const ButtonEvolution = styled(Button)`
    background-color: ${({ active }) => (!active ? "transparent" : "#EBEBEB")};
    padding-inline: 8px;
`;

const RefacturableClient = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #616161;
`;

const RefacturableText = styled(Text)`
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #242424;
`;

const WhiteBoard = styled(Flex)`
    background-color: white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    /* padding: 16px 16px 32px 16px; */
    border-radius: 8px;
    padding-bottom: 16px;
    /* max-width: 600px;
    width: 100%; */
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 600px;
    }
    @media ${DEVICE.laptopL} {
        width: 800px;
    }
`;

const FlexFooter = styled(Flex)`
    padding-inline: 16px;
`;
const FlexBody = styled(Flex)``;
const FlexHeader = styled(Flex)``;

const CreatedAt = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;

    color: #616161;
`;

const Wrapper = styled(Flex)`
    max-width: 820px;
    width: 100%;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #242424;
`;
