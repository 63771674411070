import { Avatar, ContactCardIcon, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { DEVICE } from "../../../../constants/responsive";

const Card = ({
    title,
    description,
    icon,
    avatar,
    fullname,
    type,
    isDelegated,
}) => {
    const navigate = useNavigate();
    const navigate_to_delegation = (type) => {
        return navigate(ROUTES.delegation_roles.custom_route(type));
    };
    return (
        <WrapperCard
            vAlign="center"
            gap="gap.small"
            onClick={() => navigate_to_delegation(type)}
        >
            <WrapperIcon hAlign="center">{icon}</WrapperIcon>

            <Flex
                column
                fill
                vAlign="center"
                styles={{
                    maxWidth: "90%",
                    overflow: "hidden",
                }}
            >
                <TypeText truncated content={title} />
                <Description content={description} />
            </Flex>
            {isDelegated && (
                <Flex>
                    <AvatarStyle name={fullname} image={avatar} size="large" />
                </Flex>
            )}
        </WrapperCard>
    );
};

export default Card;

const AvatarStyle = styled(Avatar)`
    > img {
        border: 2px solid var(--Colors-Pastels-violet, #c5cbfa);
    }
`;

const WrapperIcon = styled(Flex)`
    position: relative;
    &::before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="18" viewBox="0 0 40 18" fill="none"><path d="M40 14.0893L40 17.5179L13.0818 17.5179C5.86163 17.5179 -4.29543e-06 11.3728 -3.96456e-06 3.80358L-3.8147e-06 0.375006L26.9182 0.375007C34.1384 0.375007 40 6.52006 40 14.0893Z" fill="%23F5F5F5"/></svg>');
        position: absolute;
        bottom: -10px;
    }
`;

const Description = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Default-Foreground-2, #616161);

    /* Small/400 */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const TypeText = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );
    font-family: "Baloo Bhaijaan 2";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
`;

const WrapperCard = styled(Flex)`
    display: flex;
    min-width: 288px;
    width: 288px;
    height: 80px;
    gap: 8px;
    border-radius: 4px;
    padding: 0px 12px 0px 21px;
    /* shadow-8 */
    box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
    cursor: pointer;
`;
