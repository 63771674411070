import { Box, Flex } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";
import Contrat from "./components/Contrat";
import Identity from "./components/Identity";
import Resume from "./components/Resume";
const TitleStyle = styled.p`
    background-image: none;
    font-weight: 600;
    background-color: #333333;
    color: white;
    padding: 2px 20px;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    width: 100%;
    display: flex;
    @media ${DEVICE.mobileS} {
        background-color: #333333;
    }
    @media ${DEVICE.mobileM} {
        background-color: #333333;
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        max-width: 876px;
        margin: 0 auto;
    }
`;

const AccordionStyle = styled(Box)`
    margin: 0;
    padding: 16px 16px 30px 0px;
    background-color: #ebebeb;
    gap: 16px;
    display: flex;
    flex-direction: column;
    @media ${DEVICE.mobileS} {
        background-color: #ebebeb;
    }
    @media ${DEVICE.mobileM} {
        background-color: #ebebeb;
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        max-width: 876px;
        margin: 0 auto;
    }
`;
export const PANELS = [
    {
        key: 1,
        title: <TitleStyle>Résumé</TitleStyle>,
        content: <Resume />,
    },
    {
        key: 2,
        title: <TitleStyle>Identité</TitleStyle>,
        content: (
            <AccordionStyle>
                <Flex column key="flex2">
                    <Identity />
                </Flex>
            </AccordionStyle>
        ),
    },
    {
        key: 3,
        title: <TitleStyle>Contrat</TitleStyle>,
        content: (
            <AccordionStyle>
                <Flex column key="flex3">
                    <Contrat />
                </Flex>
            </AccordionStyle>
        ),
    },
];
