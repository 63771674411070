import styled from "styled-components";
import { Button, Flex, Text } from "@fluentui/react-northstar";

export const Wrapper = styled(Flex)``;
export const Left = styled(Flex)``;
export const Right = styled(Flex)``;
export const Center = styled(Flex)``;
export const HoursPerformedCount = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    font-size: 18px;
    font-style: normal;
    font-weight: 350;
    line-height: 24px; /* 133.333% */
`;
export const HoursPerformed = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;
export const ButtonArrow = styled(Button)`
    min-width: 32px;
    max-width: 32px;
    padding: 0;
`;
export const TextWeekFromTo = styled(Text)`
    color: #242424;
    text-align: start;
    width: 400px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
`;
export const MobileTextWeekFromTo = styled(Text)`
    color: #242424;
    text-align: center;
    width: 200px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
`;
