import React, { useEffect, useReducer, useRef } from "react";
import { Container, ContainerFluid } from "../../../../common/styles";

import {
    AlertFixed,
    Body,
    Header,
    HeaderLeft,
    HeaderRight,
    Title,
    WrapperCard,
    WrapperCardHeader,
    WrapperCardTitle,
    WrapperChevronSlider,
    WrapperWeek,
} from "./index.style";
import {
    AddIcon,
    Alert,
    Button,
    ChevronEndIcon,
    ChevronStartIcon,
    CloseIcon,
    Dialog,
    Divider,
    EditIcon,
    Flex,
    InfoIcon,
    Loader,
    SaveIcon,
    SendIcon,
    Skeleton,
    SplitButton,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import {
    CalendarArrowDown24Regular,
    CalendarAssistant24Regular,
} from "@fluentui/react-icons";
import WeekActions from "../../components/WeekActions";
import CardWeek from "../../components/CardWeek";
import moment from "moment";
import Joyride, { STATUS } from "react-joyride";
import styled from "styled-components/macro";

import { _is_today, _str_capitalize } from "../../../../utils/functions";
import {
    ACTION_TYPES,
    INIT_REDUCER_WEEK,
    reducer,
} from "../../reducers/reducer.weeks";
import { useMutation, useQuery } from "react-query";
import {
    _api_apply_week_type,
    _api_get_time_reporting,
    _api_post_tips,
    _api_save_week,
    _api_send_month,
} from "../../api";
import Toast from "../../../../components/Alerts";
import DialogSubmitMonth from "../../components/DialogSubmitMonth";
import { useState } from "react";
import ConfigureWeekTemplate from "../ConfigureWeekTemplate";
import CardWeekSkeleton from "../../components/CardWeekSkeleton";
import { useShowWeekend } from "../../stores";
import WeeksTabsChecker from "../../components/WeeksTabsChecker/WeeksTabsChecker";
import { toast as toastSonner } from "sonner";

import { BASE_URL_ILLUSTRATIONS } from "../../../../constants";
import { steps } from "../../components/TutorialGuide/steps";
import { Tooltips } from "../../components/TutorialGuide/Tooltip";
import useToast from "../../../../hooks/useToast";
import { queryClient } from "../../../..";
import { useMediaQuery } from "react-responsive";
import { DEVICE } from "../../../../constants/responsive";

const TimeReportingHome = () => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    const joyrideRef = useRef();

    // responsive
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const [state, dispatch] = useReducer(reducer, INIT_REDUCER_WEEK);
    const [toast, setToast] = useToast();
    const [toastNoWeekConfigured, setToastNoWeekConfigured] = useToast();

    const [displayMonthSubmission, setDisplayMonthSubmission] = useState(false);
    const [run, setRun] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const ctx_show_weekend = useShowWeekend((state) => state.show);
    const ctx_setShow = useShowWeekend((state) => state.setShow);
    const cache_timeclock_data = queryClient.getQueriesData("timeclock");
    console.log({ isMobile12: isMobile });
    const {
        data,
        isLoading,
        isFetching,
        refetch: refetch_get_time_reporting,
    } = useQuery(
        ["time_reporting::get_time_reporting", state.start_date_week],
        () => _api_get_time_reporting({ date: state.start_date_week }),
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                const display_weekend = data?.display_weekend;
                const tips_seen = data?.tips_seen;
                if (!tips_seen) {
                    setOpenDialog(true);
                }
                if (ctx_show_weekend === null) {
                    ctx_setShow(display_weekend);
                }
                if (data?.planning_updated) {
                    setToast((t) => {
                        return {
                            ...t,
                            visible: true,
                            content:
                                "Votre planning a été modifié et cela sera reflété dans vos prochaines feuilles de temps.",
                            level: "warning",
                            auto_hide_timeout: 5000,
                            dismissible: true,
                        };
                    });
                }
                return dispatch({
                    type: ACTION_TYPES.toggle_weekend,
                    payload: {
                        value: display_weekend,
                    },
                });
            },
        }
    );
    const isLoading2 = true;
    const handleKeyboardNavigation = (event) => {
        if (event.keyCode === 37 || event.keyCode === 39) {
            // left and right arrow keys
            event.preventDefault(); // Prevent default behavior of arrow keys
            if (run) {
                // If Joyride is running, handle navigation
                if (event.keyCode === 37) {
                    // Left arrow key
                    // Go to the previous step
                    if (joyrideRef.current.state.index === 0) {
                        return;
                    } else {
                        joyrideRef.current.helpers.prev();
                    }
                } else if (event.keyCode === 39) {
                    // Right arrow key
                    // Go to the next step
                    joyrideRef.current.helpers.next();
                }
            }
        }
    };

    // Add event listener for keyboard navigation
    useEffect(() => {
        document.addEventListener("keydown", handleKeyboardNavigation);
        return () => {
            document.removeEventListener("keydown", handleKeyboardNavigation);
        };
    }, [run]);

    const { mutate: mutate_save_week, isLoading: is_saving_week } = useMutation(
        (data) => _api_save_week(data)
    );

    const { mutate: mutate_finish_tips, isLoading: is_loading_finish_tips } =
        useMutation(() => _api_post_tips());

    const { mutate: mutate_apply_type_week, isLoading: is_applying_week_type } =
        useMutation((data) => _api_apply_week_type(data));

    const { mutate: mutate_send_month, isLoading: is_sending_month } =
        useMutation((data) => _api_send_month(data));

    const handle_click_apply_week_template = (e) => {
        e.preventDefault();
        mutate_apply_type_week(
            {
                week: week_id,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        return refetch_get_time_reporting();
                    }
                },
            }
        );
    };

    const handleJoyrideCallback = (data) => {
        const { status, action } = data;
        if (action === "close") {
            setRun(false);
            mutate_finish_tips();
            return;
        }
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRun(false);
        }
    };

    const handle_click_update_week_template = (e) => {
        e.preventDefault();
        dispatch({
            type: ACTION_TYPES.mode_update_week_template,
            payload: {
                value: true,
            },
        });
        setToastNoWeekConfigured((t) => {
            return {
                ...t,
                visible: false,
            };
        });
    };

    const close_dialog_tuto = () => {
        setRun(false);
        setOpenDialog(false);
        return;
    };

    useEffect(() => {
        if (isFetching) {
            toastSonner.loading("Mise à jour ...");
        }
        if (!isFetching) {
            toastSonner.dismiss();
        }
    }, [isFetching]);

    const week_id = data?.id;
    const data_days = data?.days;
    const data_week_status = data?.status?.value;
    const disable_button_save_week = data?.days?.every(
        (day) => day.shifts?.length === 0
    );
    const week_from_to = `${state.dates.start} - ${state.dates.end}`;
    const Mobile_week_from_to = `${moment(
        state.dates.start,
        "DD MMMM YYYY"
    ).format("DD MMMM")} - ${moment(state.dates.end, "DD MMMM YYYY").format(
        "DD MMMM"
    )}`;
    const is_saved_week = [1, 2].includes(data_week_status);
    const is_closed_week = [2].includes(data_week_status);

    const declared_hours_count = data?.declared_hours_count;
    const weekly_difference = data?.weekly_difference;

    const display_month_submission =
        displayMonthSubmission || data?.month?.display_month_submission;

    const month_id = data?.month?.id;
    const month_name = _str_capitalize(
        moment().set("month", state.month_indexed).format("MMMM")
    );
    const year_name = _str_capitalize(
        moment().set("year", state.year).format("YYYY")
    );

    const summary_weekly_detail = data?.month?.summary?.weeks?.map((week) => {
        return {
            number: `S.${week.week_number}`,
            hours_count: week.declared_hours_count,
        };
    });
    const summary_declared_hours_count =
        data?.month?.summary?.declared_hours_count;
    const summary_expected_hours_count =
        data?.month?.summary?.expected_hours_count;
    const week_template_exists = data?.week_template_exists;

    useEffect(() => {
        if (week_template_exists === false) {
            setToastNoWeekConfigured((state) => ({
                ...state,
                visible: true,
                content:
                    "Semaine type inexistante, la semaine type facilite la saisie du temps",
                level: "info",
            }));
            return;
        }
    }, [week_template_exists]);

    return (
        <ContainerFluid>
            {/* <TourGuide /> */}
            {/* TOASTS */}
            <Container column gap="gap.small" fill>
                <Toast
                    {...toast}
                    onStatusChange={() => {
                        return setToast((t) => {
                            return {
                                ...t,
                                visible: false,
                            };
                        });
                    }}
                />
                <AlertFixed
                    // actions={[
                    //     {
                    //         content: "Paramétrer la semaine type",
                    //         secondary: true,
                    //         key: "go_to_config_week",
                    //         flat: true,
                    //         onClick: handle_click_update_week_template,
                    //         styles: {
                    //             marginLeft: "35px",
                    //         },
                    //     },
                    // ]}
                    // content={toastNoWeekConfigured.content}
                    content={
                        !isMobile ? (
                            <Flex gap="gap.large" vAlign="center" fill>
                                <Flex
                                    fill
                                    styles={{
                                        justifyContent: "space-between",
                                        maxWidth: "100%",
                                    }}
                                    vAlign="center"
                                    gap="gap.medium"
                                >
                                    <Flex gap="gap.small" vAlign="center">
                                        <InfoIcon />
                                        <Text
                                            content={
                                                toastNoWeekConfigured.content
                                            }
                                        />
                                    </Flex>

                                    <Button
                                        secondary
                                        flat={true}
                                        onClick={
                                            handle_click_update_week_template
                                        }
                                        content={"Paramétrer la semaine type"}
                                    />

                                    <CloseIcon
                                        onClick={() => {
                                            return setToastNoWeekConfigured(
                                                (t) => {
                                                    return {
                                                        ...t,
                                                        visible: false,
                                                    };
                                                }
                                            );
                                        }}
                                    />
                                </Flex>
                            </Flex>
                        ) : (
                            <Flex column fill gap="gap.medium">
                                <Flex vAlign="center" space="between">
                                    <Flex gap="gap.small" vAlign="center">
                                        <InfoIcon />
                                        <Text
                                            content={"Semaine type inexistante"}
                                        />
                                    </Flex>
                                    <CloseIcon
                                        onClick={() => {
                                            return setToastNoWeekConfigured(
                                                (t) => {
                                                    return {
                                                        ...t,
                                                        visible: false,
                                                    };
                                                }
                                            );
                                        }}
                                    />
                                </Flex>

                                <Flex hAlign="center">
                                    <Button
                                        styles={{ width: "200px" }}
                                        size="small"
                                        secondary
                                        flat={true}
                                        onClick={
                                            handle_click_update_week_template
                                        }
                                        content={"Paramétrer la semaine type"}
                                    />
                                </Flex>
                            </Flex>
                        )
                    }
                    visible={
                        toastNoWeekConfigured.visible &&
                        !isLoading &&
                        !isFetching
                    }
                    // icon={<InfoIcon />}
                    // dismissible
                    // dismissAction={{
                    //     "aria-label": "close",
                    //     onClick: () => {
                    //         return setToastNoWeekConfigured((t) => {
                    //             return {
                    //                 ...t,
                    //                 visible: false,
                    //             };
                    //         });
                    //     },
                    // }}
                />
                {state.mode_update_week_template ? (
                    <ConfigureWeekTemplate dispatch={dispatch} />
                ) : (
                    <Flex fluid column gap="gap.large">
                        <Dialog
                            cancelButton={{
                                content: "Passer cette étape",
                                flat: true,
                            }}
                            confirmButton={{
                                content: "Commencer le tutoriel",
                                flat: true,
                            }}
                            onConfirm={() => {
                                setRun(true);
                                setOpenDialog(false);
                            }}
                            onCancel={close_dialog_tuto}
                            content={
                                <Flex
                                    fill
                                    hAlign="center"
                                    column
                                    gap="gap.large"
                                >
                                    <HeaderText content="Comment faire ma déclaration du temps ?" />
                                    <img
                                        src={`${BASE_URL_ILLUSTRATIONS}/time_reporting/pana.svg`}
                                        alt="illustration explain"
                                    />
                                    <SubText
                                        content={
                                            <span>
                                                Explorez notre tutoriel pour{" "}
                                                <b>
                                                    faciliter votre saisie
                                                    d'heures.
                                                </b>
                                            </span>
                                        }
                                    />
                                </Flex>
                            }
                            open={openDialog}
                            headerAction={{
                                icon: <CloseIcon />,
                                title: "Close",
                                onClick: close_dialog_tuto,
                            }}
                        />
                        {!isMobile && (
                            <Joyride
                                ref={joyrideRef}
                                callback={handleJoyrideCallback}
                                run={run}
                                continuous
                                steps={steps}
                                tooltipComponent={Tooltips}
                                disableOverlayClose={true}
                                showSkipButton
                                spotlightPadding={10}
                                styles={{
                                    options: {
                                        arrowColor: "var(--color-primary)",
                                        backgroundColor: "black",
                                        overlayColor: "rgba(0,0,0,0.5)",
                                        primaryColor: "#000",
                                        textColor: "white",
                                        width: 900,
                                        zIndex: 1000,
                                    },
                                }}
                            />
                        )}
                        {isMobile ? (
                            <Flex fill hAlign="center">
                                <MobileTitle
                                    content={` Mois de ${month_name}  ${year_name} `}
                                />
                            </Flex>
                        ) : (
                            <Header fill space="between" vAlign="center">
                                <HeaderLeft
                                    fill
                                    gap="gap.medium"
                                    vAlign="center"
                                >
                                    <Title content={t("v2.home.title")} />
                                    <Tooltip
                                        content={t("v2.home.tooltip_content")}
                                        pointing
                                        subtle={false}
                                        align="top"
                                        position={"after"}
                                        trigger={
                                            <InfoIcon outline size="large" />
                                        }
                                        autoSize="width-always"
                                    />
                                </HeaderLeft>

                                {!isMobile && (
                                    <Flex
                                        styles={{
                                            // outline: "2px solid red",
                                            background: "#fafafa",
                                        }}
                                        hAlign="center"
                                    >
                                        <WeeksTabsChecker
                                            weeks={
                                                data?.month?.summary?.weeks ??
                                                []
                                            }
                                            loading={isLoading}
                                            currentWeek={state.iso_week}
                                            dispatch={dispatch}
                                        />
                                    </Flex>
                                )}

                                <HeaderRight fill vAlign="center" hAlign="end">
                                    {/* DIALOG SUBMIT MONTH */}
                                    <DialogSubmitMonth
                                        // open={
                                        //     showButtonSubmissionMonth &&
                                        //     !isLoading &&
                                        //     !isFetching
                                        // }
                                        dataMonthSummary={data?.month?.summary}
                                        weeklyDetail={summary_weekly_detail}
                                        month_name={month_name}
                                        total={summary_declared_hours_count}
                                        declared_hours_count={
                                            summary_declared_hours_count
                                        }
                                        expected_hours_count={
                                            summary_expected_hours_count
                                        }
                                        month_id={month_id}
                                        is_sending_month={is_sending_month}
                                        mutate_send_month={mutate_send_month}
                                        onMonthSent={() => {
                                            setDisplayMonthSubmission(false);
                                            setToast((toast) => {
                                                return {
                                                    ...toast,
                                                    visible: true,
                                                    level: "success",
                                                    content: t(
                                                        "v2.home.toasts.month_sent"
                                                    ),
                                                };
                                            });
                                            dispatch({
                                                type: ACTION_TYPES.increment_week,
                                            });
                                        }}
                                        onClose={() => {
                                            setDisplayMonthSubmission(false);
                                        }}
                                        disabled={!display_month_submission}
                                        trigger={
                                            <Button
                                                primary
                                                iconPosition="after"
                                                flat
                                                icon={<SendIcon />}
                                                content={t(
                                                    "v2.home.buttons.send_time_reporting",
                                                    {
                                                        month: month_name,
                                                    }
                                                )}
                                                disabled={
                                                    !display_month_submission
                                                }
                                                styles={{
                                                    minWidth: "fit-content",
                                                }}
                                            />
                                        }
                                    />
                                </HeaderRight>
                            </Header>
                        )}

                        <Body vAlign="start" fill>
                            <WrapperCard
                                styles={{
                                    padding: !isMobile ? "24px 16px" : "0px",
                                }}
                                column
                                gap="gap.medium"
                            >
                                {!isMobile && (
                                    <WrapperCardHeader
                                        space="between"
                                        vAlign="center"
                                        fill
                                    >
                                        <WrapperCardTitle
                                            content={t(
                                                "v2.home.title_declation_hebdo"
                                            )}
                                        />
                                        <Flex vAlign="center" gap="gap.medium">
                                            <Button
                                                id="modify_type_week"
                                                content={t(
                                                    "v2.home.buttons.modify_type_week"
                                                )}
                                                icon={
                                                    <CalendarAssistant24Regular />
                                                }
                                                onClick={
                                                    handle_click_update_week_template
                                                }
                                                text
                                                primary
                                            />
                                            {isLoading || isFetching ? (
                                                <div>
                                                    <Skeleton
                                                        animation={"pulse"}
                                                    >
                                                        <Skeleton.Line
                                                            width="215px"
                                                            height="32px"
                                                        />
                                                    </Skeleton>
                                                </div>
                                            ) : (
                                                // appliquer la semaine type button
                                                <Button
                                                    id="apply_type_week"
                                                    content={t(
                                                        "v2.home.buttons.apply_type_week"
                                                    )}
                                                    icon={
                                                        <CalendarArrowDown24Regular />
                                                    }
                                                    onClick={
                                                        handle_click_apply_week_template
                                                    }
                                                    loading={
                                                        is_applying_week_type
                                                    }
                                                    disabled={
                                                        is_saved_week &&
                                                        !state.mode_editable_activated
                                                    }
                                                    secondary
                                                    flat
                                                    styles={{
                                                        isolation: "isolate",
                                                    }}
                                                />
                                            )}
                                        </Flex>
                                        {isLoading || isFetching ? (
                                            <div>
                                                <Skeleton animation={"pulse"}>
                                                    <Skeleton.Line
                                                        width="190px"
                                                        height="32px"
                                                    />
                                                </Skeleton>
                                            </div>
                                        ) : is_saved_week &&
                                          !state.mode_editable_activated ? (
                                            <Button
                                                primary
                                                content={t(
                                                    "v2.home.buttons.modify_week"
                                                )}
                                                icon={<EditIcon />}
                                                iconPosition="after"
                                                flat
                                                disabled={is_closed_week}
                                                onClick={() =>
                                                    dispatch({
                                                        type: ACTION_TYPES.toggle_mode_editable,
                                                        payload: {
                                                            value: true,
                                                        },
                                                    })
                                                }
                                                className="save_or_modify_week"
                                            />
                                        ) : (
                                            <Button
                                                primary
                                                content={t(
                                                    "v2.home.buttons.save_week"
                                                )}
                                                icon={<SaveIcon />}
                                                iconPosition="after"
                                                flat
                                                disabled={
                                                    disable_button_save_week
                                                }
                                                loading={is_saving_week}
                                                onClick={() => {
                                                    return mutate_save_week(
                                                        {
                                                            week: week_id,
                                                        },
                                                        {
                                                            onSuccess: (
                                                                data
                                                            ) => {
                                                                if (
                                                                    data?.success
                                                                ) {
                                                                    if (
                                                                        !data
                                                                            ?.week_details
                                                                            ?.month
                                                                            ?.display_month_submission
                                                                    ) {
                                                                        setToast(
                                                                            (
                                                                                toast
                                                                            ) => {
                                                                                return {
                                                                                    ...toast,
                                                                                    visible: true,
                                                                                    level: "success",
                                                                                    content:
                                                                                        t(
                                                                                            "v2.home.toasts.week_saved"
                                                                                        ),
                                                                                };
                                                                            }
                                                                        );
                                                                        // dispatch({
                                                                        //     type: ACTION_TYPES.increment_week,
                                                                        // });
                                                                    } else {
                                                                        setToast(
                                                                            (
                                                                                toast
                                                                            ) => {
                                                                                return {
                                                                                    ...toast,
                                                                                    visible: true,
                                                                                    level: "success",
                                                                                    content:
                                                                                        t(
                                                                                            "v2.home.toasts.week_saved"
                                                                                        ),
                                                                                };
                                                                            }
                                                                        );
                                                                    }
                                                                    dispatch({
                                                                        type: ACTION_TYPES.toggle_mode_editable,
                                                                        payload:
                                                                            {
                                                                                value: false,
                                                                            },
                                                                    });
                                                                    refetch_get_time_reporting();
                                                                }
                                                            },
                                                        }
                                                    );
                                                }}
                                                className="save_or_modify_week"
                                            />
                                        )}
                                    </WrapperCardHeader>
                                )}

                                <WrapperWeek column fill gap="gap.small">
                                    {isMobile && (
                                        <ResponsiveHeader
                                            fill
                                            hAlign="center"
                                            column
                                        >
                                            <Flex fill hAlign="center">
                                                <WeeksTabsChecker
                                                    weeks={
                                                        data?.month?.summary
                                                            ?.weeks ?? []
                                                    }
                                                    loading={isLoading}
                                                    currentWeek={state.iso_week}
                                                    dispatch={dispatch}
                                                />
                                            </Flex>
                                        </ResponsiveHeader>
                                    )}
                                    <Flex
                                        styles={{
                                            padding: "0px 24px 0px 24px",
                                        }}
                                    >
                                        <WeekActions
                                            Mobile_week_from_to={
                                                Mobile_week_from_to
                                            }
                                            isMobile={isMobile}
                                            week_status={data_week_status}
                                            week={state.iso_week}
                                            week_from_to={week_from_to}
                                            declared_hours_count={
                                                declared_hours_count
                                            }
                                            weekly_difference={
                                                weekly_difference
                                            }
                                            dispatch={dispatch}
                                            state={state}
                                            is_saved_week={is_saved_week}
                                            mode_editable_activated={
                                                state.mode_editable_activated
                                            }
                                            month_status={
                                                data?.month?.status?.value
                                            }
                                            display_weekend={
                                                state.toggle_weekend
                                            }
                                        />
                                    </Flex>
                                    {/* appliquer une semaine type pour mobile  */}
                                    <Flex fill hAlign="center">
                                        {isMobile &&
                                            (isLoading || isFetching ? (
                                                <div>
                                                    <Skeleton
                                                        animation={"pulse"}
                                                    >
                                                        <Skeleton.Line
                                                            width="360px"
                                                            height="30px"
                                                        />
                                                    </Skeleton>
                                                </div>
                                            ) : (
                                                <Button
                                                    fluid
                                                    id="apply_type_week"
                                                    content={t(
                                                        "v2.home.buttons.apply_type_week"
                                                    )}
                                                    icon={
                                                        <CalendarArrowDown24Regular />
                                                    }
                                                    onClick={
                                                        handle_click_apply_week_template
                                                    }
                                                    loading={
                                                        is_applying_week_type
                                                    }
                                                    disabled={
                                                        is_saved_week &&
                                                        !state.mode_editable_activated
                                                    }
                                                    secondary
                                                    flat
                                                    styles={{
                                                        isolation: "isolate",
                                                        fontSize: isMobile
                                                            ? "12px"
                                                            : "14px",
                                                        maxWidth: "336px",
                                                    }}
                                                />
                                            ))}
                                    </Flex>

                                    <Flex
                                        fill
                                        column
                                        styles={{
                                            position: !isMobile && "relative",
                                        }}
                                    >
                                        {/* desktop  */}
                                        {!isMobile && (
                                            <WrapperChevronSlider
                                                fill
                                                space="between"
                                            >
                                                <Button
                                                    circular
                                                    className="left"
                                                    icon={<ChevronStartIcon />}
                                                    title="semaine précédente"
                                                    flat
                                                    disabled={
                                                        state.mode_editable_activated
                                                    }
                                                    onClick={() =>
                                                        dispatch({
                                                            type: ACTION_TYPES.decrement_week,
                                                        })
                                                    }
                                                />
                                                <Button
                                                    circular
                                                    icon={<ChevronEndIcon />}
                                                    title="semaine suivante"
                                                    className="right"
                                                    disabled={
                                                        state.mode_editable_activated
                                                    }
                                                    flat
                                                    onClick={() =>
                                                        dispatch({
                                                            type: ACTION_TYPES.increment_week,
                                                        })
                                                    }
                                                />
                                            </WrapperChevronSlider>
                                        )}

                                        <FlexContainer
                                            hAlign="center"
                                            fill
                                            styles={{
                                                overflowX: "scroll",
                                            }}
                                            // gap={
                                            //     ctx_show_weekend
                                            //         ? "gap.small"
                                            //         : "gap.large"
                                            // }
                                        >
                                            {isLoading && isMobile ? (
                                                <Flex
                                                    styles={{ height: "300px" }}
                                                    hAlign="center"
                                                    vAlign="center"
                                                    fill
                                                >
                                                    <Loader label="Chargement" />
                                                </Flex>
                                            ) : isLoading && !isMobile ? (
                                                <CardWeekSkeleton />
                                            ) : (
                                                <Flex
                                                    hAlign={
                                                        !isMobile && "center"
                                                    }
                                                    fill
                                                    // gap: "18px",
                                                    gap={
                                                        ctx_show_weekend
                                                            ? "gap.medium"
                                                            : "gap.large"
                                                    }
                                                    style={{
                                                        justifyContent:
                                                            !ctx_show_weekend
                                                                ? "center"
                                                                : isMobile
                                                                ? "flex-start"
                                                                : "space-between",

                                                        padding:
                                                            "12px 16px 12px 6px",
                                                        overflowX: "scroll",
                                                    }}
                                                >
                                                    {data_days?.map(
                                                        (day, index) => {
                                                            const {
                                                                id,
                                                                shifts,
                                                                type: type_day,
                                                                date,
                                                                status,
                                                                hours_count,
                                                            } = day;
                                                            const array_date =
                                                                date.split("-");

                                                            const is_today =
                                                                _is_today({
                                                                    day: array_date.at(
                                                                        2
                                                                    ),
                                                                    month: array_date.at(
                                                                        1
                                                                    ),
                                                                    year: array_date.at(
                                                                        0
                                                                    ),
                                                                });
                                                            const day_name =
                                                                _str_capitalize(
                                                                    moment(
                                                                        date
                                                                    ).format(
                                                                        "ddd DD"
                                                                    )
                                                                );
                                                            const can_modify_day =
                                                                [0, 1].includes(
                                                                    data_week_status
                                                                ) &&
                                                                [
                                                                    0, 1, 3, 4,
                                                                ].includes(
                                                                    type_day?.value
                                                                ) &&
                                                                [0, 1].includes(
                                                                    status?.value
                                                                );

                                                            const not_display_weekend =
                                                                !ctx_show_weekend &&
                                                                index > 4;

                                                            if (
                                                                not_display_weekend
                                                            ) {
                                                                return;
                                                            }

                                                            return (
                                                                <Flex>
                                                                    <CardWeek
                                                                        ctx_show_weekend={
                                                                            ctx_show_weekend
                                                                        }
                                                                        isMobile={
                                                                            isMobile
                                                                        }
                                                                        is_today={
                                                                            is_today
                                                                        }
                                                                        key={id}
                                                                        day_index={
                                                                            index
                                                                        }
                                                                        day_id={
                                                                            id
                                                                        }
                                                                        hours_count={
                                                                            hours_count
                                                                        }
                                                                        shifts={
                                                                            shifts
                                                                        }
                                                                        type_day={
                                                                            type_day
                                                                        }
                                                                        day_name={
                                                                            day_name
                                                                        }
                                                                        status_day={
                                                                            status?.value
                                                                        }
                                                                        is_saved_week={
                                                                            is_saved_week
                                                                        }
                                                                        mode_editable_activated={
                                                                            state.mode_editable_activated
                                                                        }
                                                                        can_modify_day={
                                                                            can_modify_day
                                                                        }
                                                                        data_day={
                                                                            day
                                                                        }
                                                                        onConfirmModifyDay={() => {
                                                                            return setToast(
                                                                                (
                                                                                    t
                                                                                ) => {
                                                                                    return {
                                                                                        ...t,
                                                                                        visible: true,
                                                                                        content:
                                                                                            "Jour modifié",
                                                                                        level: "success",
                                                                                    };
                                                                                }
                                                                            );
                                                                        }}
                                                                        useTimerClockSlots={
                                                                            cache_timeclock_data
                                                                        }
                                                                    />
                                                                </Flex>
                                                            );
                                                        }
                                                    )}
                                                </Flex>
                                            )}
                                        </FlexContainer>

                                        {/* Display Enregistrer la semaine version mobile here  */}
                                        {isMobile && (
                                            <SaveWeekButtonFlex
                                                styles={{
                                                    marginTop: "22px",
                                                    padding: "16px 8px",
                                                }}
                                                fill
                                                gap="gap.small"
                                                column
                                                hAlign="center"
                                            >
                                                {is_saved_week &&
                                                !state.mode_editable_activated ? (
                                                    <Button
                                                        primary
                                                        fluid
                                                        content={t(
                                                            "v2.home.buttons.modify_week"
                                                        )}
                                                        icon={<EditIcon />}
                                                        iconPosition="after"
                                                        flat
                                                        disabled={
                                                            is_closed_week
                                                        }
                                                        onClick={() =>
                                                            dispatch({
                                                                type: ACTION_TYPES.toggle_mode_editable,
                                                                payload: {
                                                                    value: true,
                                                                },
                                                            })
                                                        }
                                                        className="save_or_modify_week"
                                                    />
                                                ) : (
                                                    <Button
                                                        fluid
                                                        primary
                                                        content={t(
                                                            "v2.home.buttons.save_week"
                                                        )}
                                                        icon={<SaveIcon />}
                                                        iconPosition="after"
                                                        flat
                                                        disabled={
                                                            disable_button_save_week
                                                        }
                                                        loading={is_saving_week}
                                                        onClick={() => {
                                                            return mutate_save_week(
                                                                {
                                                                    week: week_id,
                                                                },
                                                                {
                                                                    onSuccess: (
                                                                        data
                                                                    ) => {
                                                                        if (
                                                                            data?.success
                                                                        ) {
                                                                            if (
                                                                                !data
                                                                                    ?.week_details
                                                                                    ?.month
                                                                                    ?.display_month_submission
                                                                            ) {
                                                                                setToast(
                                                                                    (
                                                                                        toast
                                                                                    ) => {
                                                                                        return {
                                                                                            ...toast,
                                                                                            visible: true,
                                                                                            level: "success",
                                                                                            content:
                                                                                                t(
                                                                                                    "v2.home.toasts.week_saved"
                                                                                                ),
                                                                                        };
                                                                                    }
                                                                                );
                                                                                // dispatch({
                                                                                //     type: ACTION_TYPES.increment_week,
                                                                                // });
                                                                            } else {
                                                                                setToast(
                                                                                    (
                                                                                        toast
                                                                                    ) => {
                                                                                        return {
                                                                                            ...toast,
                                                                                            visible: true,
                                                                                            level: "success",
                                                                                            content:
                                                                                                t(
                                                                                                    "v2.home.toasts.week_saved"
                                                                                                ),
                                                                                        };
                                                                                    }
                                                                                );
                                                                            }
                                                                            dispatch(
                                                                                {
                                                                                    type: ACTION_TYPES.toggle_mode_editable,
                                                                                    payload:
                                                                                        {
                                                                                            value: false,
                                                                                        },
                                                                                }
                                                                            );
                                                                            refetch_get_time_reporting();
                                                                        }
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                        className="save_or_modify_week"
                                                    />
                                                )}
                                                <div style={{ width: "100%" }}>
                                                    <DialogSubmitMonth
                                                        // open={
                                                        //     showButtonSubmissionMonth &&
                                                        //     !isLoading &&
                                                        //     !isFetching
                                                        // }
                                                        dataMonthSummary={
                                                            data?.month?.summary
                                                        }
                                                        weeklyDetail={
                                                            summary_weekly_detail
                                                        }
                                                        month_name={month_name}
                                                        total={
                                                            summary_declared_hours_count
                                                        }
                                                        declared_hours_count={
                                                            summary_declared_hours_count
                                                        }
                                                        expected_hours_count={
                                                            summary_expected_hours_count
                                                        }
                                                        month_id={month_id}
                                                        is_sending_month={
                                                            is_sending_month
                                                        }
                                                        mutate_send_month={
                                                            mutate_send_month
                                                        }
                                                        onMonthSent={() => {
                                                            setDisplayMonthSubmission(
                                                                false
                                                            );
                                                            setToast(
                                                                (toast) => {
                                                                    return {
                                                                        ...toast,
                                                                        visible: true,
                                                                        level: "success",
                                                                        content:
                                                                            t(
                                                                                "v2.home.toasts.month_sent"
                                                                            ),
                                                                    };
                                                                }
                                                            );
                                                            dispatch({
                                                                type: ACTION_TYPES.increment_week,
                                                            });
                                                        }}
                                                        onClose={() => {
                                                            setDisplayMonthSubmission(
                                                                false
                                                            );
                                                        }}
                                                        disabled={
                                                            !display_month_submission
                                                        }
                                                        trigger={
                                                            <ButtonStyle
                                                                fluid
                                                                primary
                                                                iconPosition="after"
                                                                flat
                                                                icon={
                                                                    <SendIcon />
                                                                }
                                                                content={
                                                                    "Envoyer la feuille de temps"
                                                                }
                                                                disabled={
                                                                    !display_month_submission
                                                                }
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </SaveWeekButtonFlex>
                                        )}

                                        {/* fin here  */}
                                    </Flex>
                                </WrapperWeek>
                            </WrapperCard>
                        </Body>
                    </Flex>
                )}
            </Container>
        </ContainerFluid>
    );
};

export default TimeReportingHome;

const ButtonStyle = styled(Button)`
    width: 100%;
`;

const SaveWeekButtonFlex = styled(Flex)`
    background: #fff;
    /* margin-top: 22px; */
    width: 100%;
    box-shadow: 0px -1.2px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px -6.4px 14.4px 0px rgba(0, 0, 0, 0.13);
`;

const FlexContainer = styled(Flex)`
    max-width: 100%;
    padding: 0px 20px 0px 20px;

    @media ${DEVICE.mobileS} {
        width: 100%;
    }

    @media ${DEVICE.mobileM} {
        width: 100%;
    }

    @media ${DEVICE.mobileL} {
        width: 100%;
    }

    /* @media ${DEVICE.tablet} {
        width: 768px;
    }

    @media ${DEVICE.laptop} {
        width: 1024px;
    }

    @media ${DEVICE.laptopL} {
        width: 1350px;
    } */
`;

const DividerStyled = styled.div`
    height: 6px;
    width: 100%;
    background-color: #9299f7;
    margin-top: 16px;
`;

const HeaderText = styled(Text)`
    color: #242424;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`;
const SubText = styled(Text)`
    color: #242424;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const MobileTitle = styled(Text)`
    color: #242424;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* outline: 2px dashed blue; */
    padding-block: 12px;
    margin-bottom: -24px;
`;

const ResponsiveHeader = styled(Flex)`
    padding: 16px 24px 0px 24px;
`;
