import React, { useState } from "react";
import {
    Avatar,
    Box,
    Breadcrumb,
    ChevronEndIcon,
    ContactGroupIcon,
    EditIcon,
    Flex,
    FlexItem,
    Menu,
    MoreIcon,
    Text,
    menuAsToolbarBehavior,
    EmailIcon,
} from "@fluentui/react-northstar";
import { Button } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";

import { Tab, TabList } from "@fluentui/react-components";
import { useQuery, useQueryClient } from "react-query";
import { getEmployeeById, getMyInformations } from "../../../../api/user";
import LoaderPage from "../../../../components/loaders/LoaderPage";
import Resume from "./Tab/Resume";
import Identity from "./Tab/Identity";
import Contrat from "./Tab/Contrat";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useAuthDetailStore } from "../../../../stores/useAuthStore";
import { profile_redirect_depending_role } from "../../../../constants/roles/profile";
import Toast from "../../../../components/Alerts";
import { useEffect } from "react";
import Consulting from "./Tab/Consulting";
import { ROLES_CODE, roles_access } from "../../../../constants/roles";
import CustomBreadCrumb from "../../../../components/breadcrumb";
import { BREADCRUMB_HUMBERGER_GLOBAL } from "../../../../constants/routes/drawer";
import { Container, ContainerFluid } from "../../../../common/styles";
import { BREADCRUMB_SHOW_PROFILE } from "../constants";

const TABS = {
    RESUME: "RESUME",
    IDENTITE: "IDENTITY",
    CONTRAT: "CONTRAT",
    PIECE_IDENTITY: "PIECE_IDENTITY",
};
export default function ShowProfil() {
    const [selectedTab, setSelectedTab] = useState(TABS.RESUME);

    const { id_user } = useParams();

    let [searchParams, setSearchParams] = useSearchParams();
    const isAlert = searchParams.get("alert"); // verify if we will show the alert or not
    const [visible, setVisible] = useState(isAlert === "true" ? true : false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isAlert === "true") {
            const timeout = setTimeout(() => {
                setVisible(false);
            }, 3000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isAlert, setVisible]);

    const onTabSelect = (event, data) => {
        setSelectedTab(data.value);
    };

    const navigate_profile_depend_roles = () => {
        const { roles } = useAuthDetailStore.getState().detail;
        const role_code = roles.map((el) => el?.code);

        if (id_user !== undefined) {
            // RH can only modify employee
            const isManager = ifUserHasAccessToModifyManager({ location });

            // si il est manager redirection vers une page pour modifier que le tab consulting
            if (isManager) {
                return navigate(`/employee/${id_user}/manager/update`);
                // return navigate(`/me/rh/update`);
            }
            const isRH = ifUserHasAccessToModify({ location });
            if (isRH) {
                return navigate(`/employee/${id_user}/update`);
                // return navigate(`/me/rh/update`);
            }
        }

        const redirect_depend_role = profile_redirect_depending_role(role_code);
        navigate(redirect_depend_role);
    };

    const items = [
        {
            icon: (
                <MoreIcon
                    {...{
                        outline: true,
                    }}
                />
            ),
            key: "menuButton2",
            "aria-label": "More options",
            indicator: false,
            menu: {
                items: [
                    {
                        key: "5",
                        content: (
                            <Flex
                                // calling function modify profile for collaborator
                                onClick={navigate_profile_depend_roles}
                                style={{
                                    gap: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <FlexItem>
                                    <EditIcon outline />
                                </FlexItem>
                                <FlexItem>
                                    <Button
                                        text
                                        circular
                                        iconOnly
                                        icon={<MoreIcon />}
                                        flat
                                    >
                                        Modifier le profil
                                    </Button>
                                </FlexItem>
                            </Flex>
                        ),
                        // icon: <EditIcon outline />,
                    },
                ],
            },
        },
    ];

    const _renderTabs = (me) => {
        switch (selectedTab) {
            case TABS.RESUME:
                return <Resume me={me} />;
            case TABS.IDENTITE:
                return <Identity me={me} />;
            case TABS.CONTRAT:
                return <Contrat me={me} />;
            case TABS.CONSULTING:
                return <Consulting me={me} />;
            default:
        }
    };

    const _renderTabsDependingOnRoleUser = () => {
        const { id } = useAuthDetailStore.getState().detail;

        const isRH = roles_access({
            usersPrivileges: [ROLES_CODE.hr],
        });

        if (id === parseInt(id_user) || location.pathname === "/me" || isRH) {
            return (
                <>
                    <TabStyle value={TABS.RESUME}>Résumé</TabStyle>
                    <TabStyle value={TABS.IDENTITE}>Identité</TabStyle>
                    <TabStyle value={TABS.CONTRAT}>Contrat</TabStyle>
                    <TabStyle value={TABS.CONSULTING}>Consulting</TabStyle>
                </>
            );
        }

        return (
            <>
                <TabStyle value={TABS.RESUME}>Résumé</TabStyle>
            </>
        );
    };

    const ifUserHasAccessToModify = ({ location }) => {
        // if the user connected is the user to modify show the button
        // only the RH can modify the user.
        if (location.pathname === "/me") {
            return true;
        }

        const { roles } = useAuthDetailStore.getState().detail;
        const roles_code = roles?.map((el) => el.code);
        const isRh = roles_code.indexOf(ROLES_CODE.hr) !== -1;
        return isRh ? true : false;
    };

    const ifUserHasAccessToModifyManager = ({ location }) => {
        // if the user connected is the user to modify show the button
        // only the RH can modify the user.
        if (location.pathname === "/me") {
            return true;
        }

        const { roles } = useAuthDetailStore.getState().detail;

        const roles_code = roles?.map((el) => el.code);

        const isManager = roles_code.indexOf(ROLES_CODE.manager) !== -1;
        return isManager ? true : false;
    };

    const queryClient = useQueryClient();

    // fetchingData is here
    const {
        data: myInfos,
        isLoading: loadingMyInformations,
        isFetching: fetchingMyInformations,
    } = useQuery(["getMyInformationMe"], () => getMyInformations(), {
        enabled: id_user === undefined,
        refetchOnMount: true,
    });

    const {
        data: infos_employee,
        isLoading: isLoadingInfosEmployee,
        isFetching: isFetchingInfosEmployee,
    } = useQuery(
        ["getMyInformationUser", id_user],
        () => getEmployeeById({ id_user }),
        {
            enabled: id_user !== undefined,
            refetchOnMount: true,
            onSuccess: (res) => {
                if (!res?.success) {
                    return navigate("/404?err=USER_NOT_FOUND");
                }
                queryClient.removeQueries("getMyInformationMe");
            },
        }
    );

    if (id_user !== undefined && isNaN(parseInt(id_user))) {
        return navigate("/");
    }

    if (loadingMyInformations || fetchingMyInformations) {
        return <LoaderPage label="Chargement de mes données ..." />;
    }

    if (isFetchingInfosEmployee || isLoadingInfosEmployee)
        return <LoaderPage label="Chargement des données profil..." />;

    let me;
    if (myInfos) {
        me = myInfos?.user;
    } else {
        me = infos_employee?.user;
    }

    return (
        <ContainerFluid column fill>
            <Toast
                visible={visible}
                content={"Le profil a bien été mis à jour ! !"}
                level={"success"}
            />
            <CustomBreadCrumb
                humberger={BREADCRUMB_HUMBERGER_GLOBAL}
                navList={BREADCRUMB_SHOW_PROFILE}
            />

            {/* header profile */}
            <BoxStyle>
                <BoxMaxWidth>
                    <ProfileInfo vAlign="center">
                        {/* avatar */}
                        <Flex.Item>
                            <Avatar
                                size={"larger"}
                                image={me?.avatar}
                                name={`${me?.first_name} ${me?.last_name}`}
                            />
                        </Flex.Item>
                        {/* Center section  */}
                        <Flex.Item column grow styles={{ gap: "4px" }}>
                            <Flex column vAlign="center">
                                <Flex.Item>
                                    <TextName>{`${me?.first_name} ${me?.last_name}`}</TextName>
                                </Flex.Item>
                                <Flex.Item>
                                    <Text
                                        color="#F5F5F5"
                                        content={me?.job_title}
                                    />
                                </Flex.Item>
                            </Flex>
                        </Flex.Item>
                        {/* three dots */}
                        {ifUserHasAccessToModify({ location }) ? (
                            <Flex.Item>
                                <Menu
                                    items={items}
                                    defaultActiveIndex={0}
                                    iconOnly
                                    accessibility={menuAsToolbarBehavior}
                                    aria-label="Compose Editor"
                                    style={{ marginBottom: "25px" }}
                                />
                            </Flex.Item>
                        ) : null}
                    </ProfileInfo>

                    <FlexButtonStyle vAlign="center" column gap="gap.medium">
                        <Button
                            as="a"
                            href={`MSTeams:/l/chat/0/0?users=${me?.email}`}
                            style={{
                                backgroundColor: "#5B5FC7",
                                color: "white",
                                textDecoration: "none",
                            }}
                            icon={<ContactGroupIcon outline />}
                            content="Contacter sur Teams"
                            iconposition="before"
                        />
                        <Button
                            as="a"
                            href={`mailto:${me?.email}`}
                            style={{
                                backgroundColor: "#FFFFFF",
                                color: "#5B5FC7",
                                textDecoration: "none",
                            }}
                            icon={<EmailIcon outline />}
                            content="Envoyer un e-mail"
                            iconposition="before"
                        />
                    </FlexButtonStyle>
                </BoxMaxWidth>
            </BoxStyle>
            <BoxMaxWidth>
                <TablistStyle
                    selectedValue={selectedTab}
                    onTabSelect={onTabSelect}
                >
                    {_renderTabsDependingOnRoleUser()}
                </TablistStyle>
                {_renderTabs(me)}
            </BoxMaxWidth>
        </ContainerFluid>
    );
}

const BoxStyle = styled(Box)`
    padding: 0 0 16px 0;

    @media ${DEVICE.mobileS} {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        gap: 15px;
        width: 100%;
    }
    @media ${DEVICE.mobileM} {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        gap: 15px;
        width: 100%;
    }
    @media ${DEVICE.mobileL} {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        gap: 15px;
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        gap: 15px;
        padding: 0;
    }
`;

const TabStyle = styled(Tab)`
    @media ${DEVICE.mobileS} {
        justify-content: center;
        margin-inline: -2px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
    }
`;

const ToolbarStyle = styled(Box)`
    background-color: #5b5fc7;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
`;

const BoxMaxWidth = styled(Box)`
    @media ${DEVICE.mobileS} {
        display: flex;
        flex-direction: column;
        max-width: 876px;
        width: 100%;
        margin: 0 auto;
        gap: 15px;
    }
    @media ${DEVICE.laptop} {
        display: flex;
        flex-direction: column;
        max-width: 876px;
        width: 100%;
        margin: 0 auto;
        box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11),
            0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
        padding-bottom: 16px;
    }
`;
const ProfileInfo = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 16px;
        margin-inline: 24px;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }
    @media ${DEVICE.mobileM} {
        margin-top: 16px;
        margin-inline: 24px;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }
    @media ${DEVICE.mobileL} {
        margin-top: 16px;
        margin-inline: 24px;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }
    @media ${DEVICE.tablet} {
        margin-top: 16px;
        margin-inline: 24px;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }
    @media ${DEVICE.laptop} {
        margin-top: 16px;
        margin-inline: 24px;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }
`;
const TextName = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
`;
const FlexButtonStyle = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        justify-content: center;
        padding-inline: 25px;
    }
    @media ${DEVICE.mobileM} {
        padding-inline: 55px;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
        justify-content: center;
        padding-inline: 70px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        justify-content: center;
        padding-inline: 70px;

        gap: 24px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 24px;
        justify-content: flex-start;
        padding-inline: 25px;
    }
`;

const TablistStyle = styled(TabList)`
    @media ${DEVICE.mobileS} {
        margin-inline: 9px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        margin-top: 11px;
    }
`;
