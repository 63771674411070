// This component contains only the header of the expenses page the 3 tab list

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import { Check20Regular, Receipt20Regular } from "@fluentui/react-icons";

import { Container, ContainerFluid } from "../../../../common/styles";
import { ROUTES } from "../../../../constants/routes";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE, QUERY_KEY } from "../../constants";
import { Tab, TabList } from "@fluentui/react-components";
import { CustomBadge } from "../../../../components/Badge";
import { useQuery } from "react-query";
import {
    _api_get_count_expenses_company,
    _api_get_pending_expenses_manager,
} from "../../api";
import { useWhereIamExpenses } from "../../store/useWhereIam.store";
import { useAuthDetailStore } from "../../../../stores/useAuthStore";
import { isDelegatedToRole } from "../../../../utils/permissions";
import { roles_access, ROLES_CODE } from "../../../../constants/roles";

const HeaderManager = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const store_detail = useAuthDetailStore((state) => state.detail);
    const whereIam = useWhereIamExpenses();
    const setWhereIam = useWhereIamExpenses((state) => state.setWhereIam);
    const getIdTabActive = useWhereIamExpenses((state) => state.getIdTabActive);

    const isManager = roles_access({
        usersPrivileges: [ROLES_CODE.manager],
    });

    const { data: my_last_expenses_report, isLoading } = useQuery(
        [QUERY_KEY + "::manager_pending_expenses_badge"],
        () => _api_get_pending_expenses_manager()
    );

    const {
        data: count_expenses_company,
        isLoading: isLoadingCountExpensesCompany,
    } = useQuery([QUERY_KEY + "::accounting_manager_count_expenses"], () =>
        _api_get_count_expenses_company()
    );

    // voir s'il est delegue
    const isDelegatedForExpense = isDelegatedToRole(["expense"]);
    const isDelegatedForAccounting = isDelegatedToRole(["accounting"]);

    return (
        //HERE
        <ContainerFluidStyle>
            <Container>
                <Wrapper fill vAlign="center">
                    <TabList
                        defaultSelectedValue={getIdTabActive()}
                        appearance="transparent"
                    >
                        <Tab
                            content={t("manager.breadcrumb.my_expenses")}
                            icon={<Receipt20Regular />}
                            onClick={() => {
                                setWhereIam({
                                    isEmployeePage: true,
                                    isManagerPage: false,
                                    isAccountingManager: false,
                                });
                                navigate(ROUTES.expenses.home);
                            }}
                            value={0}
                            active={whereIam.isEmployeePage}
                            text
                        />
                        {(isDelegatedForExpense || isManager) && (
                            <Tab
                                content={
                                    <Flex vAlign="center" gap="gap.smaller">
                                        <Text
                                            content={t(
                                                "manager.breadcrumb.my_team_expenses"
                                            )}
                                        />
                                        {isLoading ? (
                                            <Loader size="smallest" />
                                        ) : (
                                            <CustomBadge
                                                size="smaller"
                                                appearence="filled"
                                                bg="orange"
                                                color="white"
                                                label={my_last_expenses_report?.length?.toString()}
                                                rectangular={false}
                                            />
                                        )}
                                    </Flex>
                                }
                                icon={<Check20Regular />}
                                onClick={() => {
                                    setWhereIam({
                                        isEmployeePage: false,
                                        isManagerPage: true,
                                        isAccountingManager: false,
                                    });
                                    navigate(
                                        ROUTES.expenses.manager.home.route
                                    );
                                }}
                                value={1}
                                active={whereIam.isManagerPage}
                                text
                            />
                        )}
                        {(store_detail?.is_accounting_manager ||
                            isDelegatedForAccounting) && (
                            <Tab
                                content={
                                    <Flex vAlign="center" gap="gap.smaller">
                                        <Text
                                            content={t(
                                                "manager.society_expenses"
                                            )}
                                        />
                                        {isLoadingCountExpensesCompany ? (
                                            <Loader size="smallest" />
                                        ) : (
                                            <CustomBadge
                                                size="smaller"
                                                appearence="filled"
                                                bg="orange"
                                                color="white"
                                                label={count_expenses_company?.count?.toString()}
                                                rectangular={false}
                                            />
                                        )}
                                    </Flex>
                                }
                                icon={<Check20Regular />}
                                onClick={() => {
                                    setWhereIam({
                                        isEmployeePage: false,
                                        isManagerPage: false,
                                        isAccountingManager: true,
                                    });
                                    navigate(
                                        ROUTES.expenses.accounting_manager.home
                                            .route
                                    );
                                }}
                                value={2}
                                active={true}
                                text
                            />
                        )}
                    </TabList>
                </Wrapper>
            </Container>
        </ContainerFluidStyle>
    );
};

export default HeaderManager;

const ContainerFluidStyle = styled(ContainerFluid)`
    box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px #00000012,
        0px 1.2000000476837158px 3.5999999046325684px 0px #00000008;
    background-color: #f5f5f5;
`;

const ButtonStyle = styled(Button)`
    background-color: ${({ active }) => (active ? "#EBEbeb" : "transparent")};
    color: ${({ active }) => (active ? "var(--color-primary)" : "inherit")};
`;

const Wrapper = styled(Flex)`
    /* height: 48px; */
    overflow-x: scroll; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    white-space: nowrap; /* Prevent line breaks */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;
