import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import Tile from "../Tile";
import ManualReportIcon from "../../../../assets/icons/employee_folder/planning/manual_reporting.svg";
import N2FIcon from "../../../../assets/icons/employee_folder/planning/N2F.svg";
import { ACTIONS_TIMETABLE } from "../../reducers/timetable.reducer";

const MeansSeizure = ({ state, dispatch }) => {
    return (
        <Flex column gap="gap.small">
            <Flex>
                <TextMeans content="Moyen(s) de saisie" />
            </Flex>
            <Flex gap="gap.medium">
                {/* les cartes */}
                <Tile
                    content={"Saisie manuelle"}
                    desc="Sur base du planning"
                    icon={ManualReportIcon}
                    $default
                />
                <Tile
                    content={"Timbreuse"}
                    desc="Timbreuse physique & digitale"
                    icon={N2FIcon}
                    $selected={state?.badge_activated}
                    onClick={() => {
                        return dispatch({
                            type: ACTIONS_TIMETABLE.toggle_badge_activated,
                        });
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default MeansSeizure;

const TextMeans = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;
