import { Button, Checkbox, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const CheckBoxEnableTimeReporting = styled(Checkbox)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;

export const ButtonChoosePlanning = styled(Button)`
    text-decoration-line: underline;
    font-weight: 400;
    font-size: 12px;
`;

export const TextPlanning = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
`;
