import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../stores/useAuthStore";
import { ROUTES } from "../constants/routes";

const withGuestOnly = (WrappedComponent) => {
    // eslint-disable-next-line react/display-name
    return (props) => {
        const { infos: isAuthenticated } = useAuthStore();

        if (isAuthenticated) {
            // Redirige vers le tableau de bord si l'utilisateur est déjà connecté
            return <Navigate to={ROUTES.home} />;
        }

        // Rendre le composant si l'utilisateur n'est pas connecté
        return <WrappedComponent {...props} />;
    };
};

export default withGuestOnly;
