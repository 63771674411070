import React, { useState } from "react";
import { Flex } from "@fluentui/react-northstar";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
    _api_approve_or_reject_timesheet,
    _api_externe_validation,
} from "./api";

import ValidateLoader from "./components/ValidateLoader";
import ValidateSuccess from "./components/ValidateSuccess";
import ValidateError from "./components/ValidateError";
import Validate from "./components/Validate";
import withGuestOnly from "../../HOC/withGuestOnly";

export const STATUS = {
    approve: "APPROVE",
    reject: "REJECT",
};

const ManagerValidationTimeReporting = () => {
    const { hash } = useParams();
    const [comment, setComment] = useState("");
    const [status, setStatus] = useState(STATUS.approve);

    const { data, isLoading, isError } = useQuery(
        ["MANAGER_VALIDATION_TIME_REPORTING"],
        () => {
            return _api_externe_validation(hash);
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const {
        mutate,
        isLoading: isLoadingMutate,
        data: mutate_data,
    } = useMutation((data) =>
        _api_approve_or_reject_timesheet({ step: data, hash, comment })
    );

    const _validate_or_not_timesheet = (status) => {
        return mutate(status);
    };

    return (
        <Flex
            vAlign="center"
            hAlign="center"
            styles={{ height: "100dvh", backgroundColor: "#F3F2F1" }}
        >
            {mutate_data?.success ? (
                <ValidateSuccess />
            ) : isLoading ? (
                <ValidateLoader />
            ) : isError ? (
                <ValidateError />
            ) : (
                <Validate
                    data={data}
                    setComment={setComment}
                    comment={comment}
                    isLoadingMutate={isLoadingMutate}
                    status={status}
                    setStatus={setStatus}
                    _validate_or_not_timesheet={_validate_or_not_timesheet}
                />
            )}
        </Flex>
    );
};

export default withGuestOnly(ManagerValidationTimeReporting);
