import React from "react";
import {
    ButtonArrow,
    Center,
    HoursPerformed,
    HoursPerformedCount,
    Left,
    MobileTextWeekFromTo,
    Right,
    TextWeekFromTo,
    Wrapper,
} from "./index.style";
import {
    Checkbox,
    ChevronEndIcon,
    ChevronStartIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import BadgeWeek from "../BadgeWeek";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import HourCounter from "../MyTeam/HourBadge/HourCounter";
import { ACTION_TYPES } from "../../reducers/reducer.weeks";
import { useShowWeekend } from "../../stores";
import moment from "moment";

const STATUS = {
    done: "done",
    default: "default",
    saved: "saved",
};

// Fonction pour vérifier si le mois courant a dépassé le mois suivant (en limitant à la dernière semaine du mois suivant) par rapport à la semaine ISO courante
function hasCurrentMonthExceededNextMonth(
    currentMonthIndex,
    currentYear,
    currentIsoWeek
) {
    // Obtenir le début de la semaine ISO courante
    const currentISOWeekStart = moment().startOf("isoWeek");

    // Obtenir le début du mois suivant
    const nextMonthStart = currentISOWeekStart
        .clone()
        .add(1, "months")
        .startOf("month");

    // Obtenir la dernière semaine du mois suivant
    const lastWeekOfNextMonth = nextMonthStart.clone().endOf("month").isoWeek();

    // Comparer les valeurs des années, des mois et des semaines
    return (
        currentYear > nextMonthStart.year() ||
        (currentYear === nextMonthStart.year() &&
            (currentMonthIndex > nextMonthStart.month() ||
                (currentMonthIndex === nextMonthStart.month() &&
                    currentIsoWeek >= lastWeekOfNextMonth)))
    );
}

const WeekActions = ({
    week_status,
    month_status,
    week,
    week_from_to,
    declared_hours_count,
    weekly_difference,
    dispatch,
    is_saved_week,
    mode_editable_activated,
    display_weekend,
    state,
    isMobile,
    Mobile_week_from_to,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const status_value =
        month_status === 3
            ? STATUS.done
            : week_status === 0
            ? STATUS.default
            : [1, 2].includes(week_status)
            ? STATUS.saved
            : STATUS.default;

    const ctx_show_weekend = useShowWeekend((state) => state.show);
    const ctx_setShow = useShowWeekend((state) => state.setShow);

    // const hasExceeded = hasCurrentMonthExceededNextMonth(
    //     state.month_indexed,
    //     state.year,
    //     state.iso_week
    // );

    return (
        <Wrapper space="between" fill>
            {isMobile ? (
                <Flex
                    space="between"
                    vAlign="center"
                    fill
                    // hAlign="center"
                >
                    <ButtonArrow
                        icon={<ChevronStartIcon />}
                        iconOnly
                        flat
                        disabled={mode_editable_activated}
                        onClick={() =>
                            dispatch({
                                type: ACTION_TYPES.decrement_week,
                            })
                        }
                    />
                    <Flex styles={{ paddingInline: "16px" }}>
                        {/* <TextWeekFromTo
                            content={moment(
                                week_from_to,
                                "DD MMMM YYYY"
                            ).format("DD MMMM")}
                        /> */}
                        <MobileTextWeekFromTo content={Mobile_week_from_to} />
                    </Flex>
                    <ButtonArrow
                        icon={<ChevronEndIcon />}
                        iconOnly
                        flat
                        disabled={mode_editable_activated}
                        onClick={() =>
                            dispatch({
                                type: ACTION_TYPES.increment_week,
                            })
                        }
                    />
                </Flex>
            ) : (
                <Left vAlign="center" gap="gap.medium">
                    <Flex styles={{ gap: "4px" }}>
                        <ButtonArrow
                            icon={<ChevronStartIcon />}
                            iconOnly
                            flat
                            disabled={mode_editable_activated}
                            onClick={() =>
                                dispatch({
                                    type: ACTION_TYPES.decrement_week,
                                })
                            }
                        />
                        <ButtonArrow
                            icon={<ChevronEndIcon />}
                            iconOnly
                            flat
                            disabled={mode_editable_activated}
                            onClick={() =>
                                dispatch({
                                    type: ACTION_TYPES.increment_week,
                                })
                            }
                        />
                    </Flex>
                    <Flex>
                        <BadgeWeek status={status_value} week={week} />
                    </Flex>
                    <Flex>
                        <TextWeekFromTo content={week_from_to} />
                    </Flex>
                </Left>
            )}
            <Center vAlign="center" gap="gap.small">
                {is_saved_week && !isMobile && (
                    <>
                        <Flex vAlign="center" gap="gap.small">
                            <HoursPerformed
                                content={t("v2.home.labels.declared_hour")}
                            />
                            <HoursPerformedCount
                                content={`${declared_hours_count} ${t(
                                    "v2.home.labels.hour_unit"
                                )}`}
                            />
                        </Flex>
                        <Flex>
                            <HourCounter
                                addionalHourWorkedBadge={weekly_difference}
                            />
                        </Flex>
                    </>
                )}
            </Center>
            {!isMobile && (
                <Right vAlign="center">
                    <Checkbox
                        label={t("v2.home.toggle.show_weekend")}
                        toggle
                        checked={ctx_show_weekend}
                        onChange={(e, { checked }) => {
                            dispatch({
                                type: ACTION_TYPES.toggle_weekend,
                                payload: {
                                    value: checked,
                                },
                            });
                            ctx_setShow(checked);
                        }}
                    />
                </Right>
            )}
        </Wrapper>
    );
};

export default WeekActions;
