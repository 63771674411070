import { Flex, Text } from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import AnnualRecovery from "../AnnualRecovery";
import MonthlyCard from "../MonthlyCard";
import { useQuery } from "react-query";
import { _api_get_annual_recovery } from "../../api";
import { format_number } from "../../../../constants/maths";
import SliderYears from "../SliderYears";
import moment from "moment";
import { DEVICE } from "../../../../constants/responsive";
import { useMediaQuery } from "react-responsive";

const AnnualTracking = () => {
    const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));

    const { data, isFetching, isLoading } = useQuery(
        ["get_annual_recovery", currentYear],
        () => _api_get_annual_recovery(currentYear),
        {
            refetchOnWindowFocus: false,
        }
    );

    const cardStatus = (status) => {
        switch (status) {
            case -1:
                return "indisponible";
            case 0:
                return "in_progress";
            case 1:
                return "open";
            case 2:
                return "submited";
        }
    };

    const renderEmptyMonth = () => {
        const emptyMonths = [];

        for (let index = 0; index < 12; index++) {
            emptyMonths.push(
                <MonthlyCard
                    key={index}
                    loading={isLoading || isFetching}
                    status={"submited"}
                />
            );
        }

        return emptyMonths;
    };

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    return (
        <Content fill>
            <Flex fill column>
                {!isMobile && <HeaderText content="Suivi mensuel" />}
                {/* <HeaderText content="Suivi mensuel" /> */}
                <Body column>
                    <HeaderBody vAlign="center" space="between">
                        <SliderYears
                            onChangeYear={({ year }) => setCurrentYear(year)}
                        />
                        <AnnualRecovery
                            loading={isLoading || isFetching}
                            hours={
                                data?.total_recap >= 0
                                    ? ` + ${format_number(data?.total_recap)}`
                                    : ` ${format_number(data?.total_recap)}`
                            }
                        />
                    </HeaderBody>

                    <MainBody space="between">
                        {isLoading && renderEmptyMonth()}

                        {data?.months?.map((month) => {
                            return (
                                <MonthlyCard
                                    weeks={month?.weeks}
                                    status={cardStatus(month?.status?.value)}
                                    month={month?.name}
                                    month_index={month?.id}
                                    year={currentYear}
                                    HoursWorked={month?.hours_count}
                                    hourBadge={month?.month_difference}
                                />
                            );
                        })}
                    </MainBody>
                </Body>
            </Flex>
        </Content>
    );
};

export default AnnualTracking;

const Body = styled(Flex)`
    border-top: 4px solid #9299f7;
    margin-inline: 16px;
    box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
        0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);

    gap: 32px;
    @media ${DEVICE.mobileS} {
        padding: 24px 16px;
    }
    @media ${DEVICE.tablet} {
        padding: 22px 49px;
    }
`;
const MainBody = styled(Flex)`
    gap: 16px;

    @media ${DEVICE.mobileS} {
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none; /* Pour Firefox */
        -ms-overflow-style: none; /* Pour IE et Edge */

        /* Masque la barre de défilement pour Chrome, Safari, et Edge */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media ${DEVICE.tablet} {
        flex-wrap: wrap;
    }
`;

const HeaderText = styled(Text)`
    color: #242424;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding: 24px 16px;
`;

const Content = styled(Flex)`
    background: #fff;
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
    /* height: 525px; */
    padding-bottom: 24px;
    margin-top: 24px;
`;

const HeaderBody = styled(Flex)`
    flex-wrap: wrap;
`;
const YearText = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;
