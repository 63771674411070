import {
    Button,
    Checkbox,
    DownloadIcon,
    ExclamationTriangleIcon,
    Flex,
    Loader,
    SendIcon,
    Skeleton,
    Text,
} from "@fluentui/react-northstar";
import {
    ClockDismiss24Regular,
    ClipboardCheckmark24Regular,
} from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import SliderTabs from "../../../../components/shared/SliderTabs";
import TimeReportingStatus from "../TimeReportingStatus";
import SearchBarEmployeeList from "../SearchBarEmployee";
import TimeReportToValidate from "./TimeReportToValidate";
import { Container, ContainerFluid } from "../../../../common/styles";
import { DialogCancelConfirmation } from "../../../../components/Dialogs/DialogCancelConfirmation";
import Illustration from "../../../../components/shared/Illustration";
import Toast from "../../../../components/Alerts";

import { useSearchParams } from "react-router-dom";
import WeekTableHeader from "../WeekTableHeader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    _api_export_time_reporting_selected_user,
    _api_get_all_data_for_my_team,
    _api_get_all_data_for_society,
    _api_get_validate_time_reporting,
    _api_post_validate_time_reporting,
    _api_post_validate_time_reporting_for_hr,
} from "../../api";
import EmptyTable from "./TimeReportToValidate/EmptyTable";
import { BADGE_STATUS } from "../BadgeStatus";
import SliderMonth from "../Sliders/SliderMonth";
import moment from "moment";
import LoadingData from "../../pages/Week/LoadingData";
import ExportButton from "./components/ExportButton";
import { _get_all_employees_and_departments } from "../../../../api/user";

const STATUS_TIME_REPORTING = ["to_approve", "incomplete", "closed"];
const STATUS_COUNTER_LABEL = {
    to_approve: "à valider",
    incomplete: "incomplètes",
    closed: "",
};
const QUERY_KEY_TEAMS = "get_all_data_for_my_team";
const QUERY_KEY_COUNTER_TO_APPROVE = "TIME_REPORTING::counterToApprove";

const TimeReportingSociety = () => {
    const [params, setParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(params.get("tab") * 1 ?? 0);

    const queryClient = useQueryClient();

    const [highlightedColumn, setHighlightedColumn] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);

    const [currentMonth, setCurrentMonth] = useState({
        index: params.get("month")
            ? Number(params.get("month") - 1)
            : moment().get("month"),
        name_month: moment().format("MMMM"),
        year: params.get("year")
            ? Number(params.get("year"))
            : moment().get("year"),
    });

    const [filtredData, setFiltredData] = useState([]);
    const [ConfirmationDialog, setConfirmationDialog] = useState(false);
    const [RefuseDialog, setRefuseDialog] = useState(false);
    const [RelanceDialog, setRelanceDialog] = useState(false);
    const [userIdChecked, setUserIdChecked] = useState([]);

    const [configToast, setConfigToast] = useState({
        visible: false,
        level: "success",
        content: "",
    });

    // fetch les utilisateurs et départements du dropdown
    const {
        data: employees_and_departments,
        isLoading: is_loading_employees_departments,
    } = useQuery(
        ["time_reporting::get_list_employee_and_departments"],
        () => {
            return _get_all_employees_and_departments();
        },
        {
            onSuccess: (data) => {},
        }
    );

    const {
        isFetching,
        isLoading,
        data,
        refetch: refetch_all_data_team,
    } = useQuery(
        ["get_all_data_for_society", currentMonth],
        () => _api_get_all_data_for_society(currentMonth),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            onSuccess: (response) => {
                const id = params.get("id");
                const type = params.get("type");
                const equivalent_data =
                    response[STATUS_TIME_REPORTING.at(params.get("tab") * 1)];

                setFiltredData(equivalent_data);

                setSelectedUserInit(id, type, equivalent_data);
            },
        }
    );

    const {
        mutate: mutate_validation,
        isLoading: isLoadingValidation_or_RefuseTimeReporting,
    } = useMutation((data) => {
        return _api_post_validate_time_reporting_for_hr(data);
    });

    const userIdToSend = (id) => {
        if (userIdChecked?.includes(id)) {
            setUserIdChecked((prevIds) =>
                prevIds?.filter((item) => item !== id)
            );
        } else {
            setUserIdChecked((prevCheckedId) => [...prevCheckedId, id]);
        }
    };
    const handleAllCheckbox = (e, { checked }) => {
        if (checked) {
            // on selectionne tt le monde
            const all_checked = filtredData
                ?.map((el) => el?.users?.flat().map((u) => u?.id))
                ?.flat();
            setUserIdChecked(all_checked);
        } else {
            // on deselectionne tt le monde
            setUserIdChecked([]);
        }
    };

    const findUserById = (data, userId) => {
        let result = [];

        for (let department of data) {
            let filteredUsers = department.users.filter(
                (user) => "u-" + user.id === userId
            );

            if (filteredUsers.length > 0) {
                result.push({
                    ...department,
                    users: filteredUsers,
                });
            }
        }

        return result;
    };

    const findDepartmentById = (data, departmentId) => {
        let result = [];

        for (let department of data) {
            if ("d-" + department.id === departmentId) {
                result.push(department);
                break;
            }
        }

        return result;
    };

    // set initial data when default user is set
    const setSelectedUserInit = (id_selected, type = "user", data) => {
        // sinon on cherche, selon si c'est département ou employé
        if (type === "user" && id_selected) {
            return setFiltredData((state) => {
                const filteredData = findUserById(data, id_selected);
                return filteredData;
            });
        } else if (type === "department" && id_selected) {
            // c'est département
            return setFiltredData((state) => {
                const filtredData = findDepartmentById(data, id_selected);
                return filtredData;
            });
        } else {
            setFiltredData(data);
        }
    };

    // lorqu'on change le User or departement dans le dropdown
    const onChangeSelectedUser = (id_selected, type = null) => {
        // reset tout
        if (!id_selected) {
            return setFiltredData(data[STATUS_TIME_REPORTING.at(activeTab)]);
        }
        // sinon on cherche, selon si c'est département ou employé
        if (type === "user") {
            setFiltredData((state) => {
                const filteredData = findUserById(state, id_selected);
                return filteredData;
            });
        } else {
            // c'est département
            setFiltredData((state) => {
                const filtredData = findDepartmentById(state, id_selected);
                return filtredData;
            });
        }
    };
    // refuser une feuille de temps
    const Refuse_time_reporting = () => {
        mutate_validation(
            { users: userIdChecked, type: "reject", currentMonth },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        setConfigToast({
                            visible: true,
                            level: "warning",
                            content:
                                "Feuille(s) de temps refusée(s) - Réouverture à la saisie",
                        });
                        // setConfirmationDialog(false);
                        setUserIdChecked([]);
                        setRefuseDialog(false);
                        return refetch_all_data_for_my_team();
                    } else {
                        setConfigToast({
                            visible: true,
                            level: "danger",
                            content: "error reject request",
                        });
                    }
                },
            }
        );
    };

    // valider une feuille de temps
    const Validate_time_reporting = () => {
        mutate_validation(
            { users: userIdChecked, type: "approve", currentMonth },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        setConfigToast({
                            visible: true,
                            level: "success",
                            content: "Feuille de temps approuvée",
                        });
                        setUserIdChecked([]);
                        setConfirmationDialog(false);
                        return refetch_all_data_for_my_team();
                    }
                },
            }
        );
    };

    // relancer la feuille de temps
    const Relance_time_reporting = () => {
        mutate_validation(
            { users: userIdChecked, type: "remind", currentMonth },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        setConfigToast({
                            visible: true,
                            level: "success",
                            content: "Feuille de temps relancée",
                        });
                        // setConfirmationDialog(false);
                        setRelanceDialog(false);
                        return refetch_all_data_for_my_team();
                    }
                },
            }
        );
    };

    // Fonction pour calculer le nombre total de fils (utilisateurs)
    const countTotalUsers = (departments) => {
        let totalUsers = 0;
        if (!departments?.length) return 0;
        departments.forEach((department) => {
            if (department.users && Array.isArray(department.users)) {
                totalUsers += department.users.length;
            }
        });

        return totalUsers;
    };

    const path = userIdChecked?.map((checkedId) => `u=${checkedId}`).join("&");
    const isLoadingData = isLoading || isFetching;

    const exportIt = useQuery(["ExportSelectedUser"], () => {
        () => ExportSelectedData();
    });

    const onDownloadSuccess = () => {
        return setIsDownloading(false);
    };

    const ExportSelectedData = async () => {
        setIsDownloading(true);
        await _api_export_time_reporting_selected_user({
            path,
            currentMonth,
            onDownloadSuccess,
        });
        return;
    };

    const refetch_all_data_for_my_team = () => {
        refetch_all_data_team();
        queryClient.refetchQueries([QUERY_KEY_COUNTER_TO_APPROVE]);
        return queryClient.refetchQueries([QUERY_KEY_TEAMS]);
    };

    const onActiveTabChanged = (value) => {
        setUserIdChecked([]);
        setActiveTab(value);
        // params
        addNewParam({
            tab: value,
        });

        setSelectedUserInit(
            params.get("id"),
            params.get("type"),
            data[STATUS_TIME_REPORTING.at(value)]
        );
        // return setFiltredData(data[STATUS_TIME_REPORTING.at(value)]);
        return;
    };

    const changeMonth = (type) => {
        const momentYear = moment()
            .year(currentMonth.year)
            .month(currentMonth.index);
        // supprimer les users selectionnes lors du changement du mois.
        setUserIdChecked([]);
        if (type === "increment") {
            const momentMonthYear = momentYear.add(1, "months");
            setCurrentMonth({
                index: momentMonthYear.month(),
                name_month: momentMonthYear.format("MMMM"),
                year: momentMonthYear.year(),
            });
            return;
        }
        if (type === "decrement") {
            const momentMonthYear = momentYear.add(-1, "months");
            setCurrentMonth({
                index: momentMonthYear.month(),
                name_month: momentMonthYear.format("MMMM"),
                year: momentMonthYear.year(),
            });
            return;
        }
    };

    const addNewParam = (newParams) => {
        // Créer une nouvelle instance de URLSearchParams avec les paramètres actuels
        const newSearchParams = new URLSearchParams(params);

        // Ajouter ou mettre à jour le nouveau paramètre
        Object.entries(newParams).forEach(([key, value]) => {
            newSearchParams.set(key, value);
        });

        // Mettre à jour les paramètres de requête dans l'URL
        setParams(newSearchParams);
    };

    useEffect(() => {
        // Mettez à jour le nom du mois lorsque l'index du mois change
        const updatedMonth = {
            ...currentMonth,
            name_month: moment().month(currentMonth.index).format("MMMM"),
        };
        setCurrentMonth(updatedMonth);
    }, [currentMonth.index]);

    const count_total_users_every_departments = countTotalUsers(filtredData);
    return (
        <ContainerFluid>
            <Container column styles={{ minWidth: "1440px" }}>
                <Toast
                    onStatusChange={() =>
                        setConfigToast({
                            visible: false,
                            content: "",
                            level: "warning",
                        })
                    }
                    level={configToast.level}
                    content={configToast.content}
                    visible={configToast.visible}
                />

                <Wrapper column>
                    <Flex column styles={{ gap: "8px" }}>
                        <HeaderText content="Suivi mensuel" />

                        <ContentHeader fill column>
                            <Flex
                                fill
                                vAlign="center"
                                hAlign="center"
                                space="between"
                            >
                                <Flex
                                    vAlign="center"
                                    styles={{
                                        width: "800px",
                                    }}
                                    space="between"
                                >
                                    <SliderTabs
                                        fill
                                        defaultActiveIndex={activeTab}
                                        tabs={[
                                            "A valider",
                                            "Incomplètes",
                                            "Validées",
                                        ]}
                                        onChange={(e) => {
                                            return onActiveTabChanged(e);
                                        }}
                                    />
                                    {/* slider month */}

                                    <SliderMonth
                                        month_name={currentMonth.name_month}
                                        onDecrement={() =>
                                            changeMonth("decrement")
                                        }
                                        onIncrement={() =>
                                            changeMonth("increment")
                                        }
                                        year={currentMonth.year}
                                        isLoading={isLoading || isFetching}
                                        badge_status={
                                            moment().month() ===
                                            currentMonth?.index
                                                ? "in_progress"
                                                : "default"
                                        }
                                    />
                                </Flex>
                                {/* skeleton */}
                                {activeTab !== 2 && (
                                    <Flex>
                                        {!isLoading || !isFetching ? (
                                            <TimeReportingStatus
                                                counter={data[
                                                    STATUS_TIME_REPORTING.at(
                                                        activeTab
                                                    )
                                                ].reduce((accu, curr) => {
                                                    return (
                                                        accu + curr.users.length
                                                    );
                                                }, 0)}
                                                label={
                                                    STATUS_COUNTER_LABEL[
                                                        STATUS_TIME_REPORTING.at(
                                                            activeTab
                                                        )
                                                    ]
                                                }
                                            />
                                        ) : (
                                            <Flex>
                                                <Skeleton animation="wave">
                                                    <Skeleton.Line width="120px" />
                                                </Skeleton>
                                            </Flex>
                                        )}
                                    </Flex>
                                )}
                            </Flex>
                            <HeaderTable vAlign="center">
                                {/* search bar  */}
                                <Flex
                                    styles={{
                                        minWidth: "276px",
                                    }}
                                >
                                    <SearchBarEmployeeList
                                        users={employees_and_departments}
                                        isLoading={
                                            is_loading_employees_departments
                                        }
                                        onChangeUser={(id_user, type) => {
                                            if (!id_user) {
                                                // mettre les valeurs par defauts des onglets
                                                setFiltredData(
                                                    data[
                                                        STATUS_TIME_REPORTING.at(
                                                            activeTab
                                                        )
                                                    ]
                                                );
                                                return;
                                            }
                                            addNewParam({
                                                id: id_user,
                                                type,
                                            });
                                            onChangeSelectedUser(id_user, type);
                                            return;
                                        }}
                                    />
                                </Flex>

                                <Thead
                                    space="between"
                                    styles={{
                                        minWidth: "909px",
                                    }}
                                    className="thead"
                                    fill
                                >
                                    {isLoadingData ? (
                                        <LoadingData />
                                    ) : (
                                        data?.weeks?.map((el, index) => {
                                            return (
                                                <WeekTableHeader
                                                    currentMonth={currentMonth}
                                                    day={el}
                                                    loading={isLoadingData}
                                                    index={index}
                                                    setHighlightedColumn={
                                                        setHighlightedColumn
                                                    }
                                                    highlightedColumn={
                                                        highlightedColumn
                                                    }
                                                    activeTab={activeTab}
                                                    isHR={true}
                                                />
                                            );
                                        })
                                    )}
                                </Thead>
                                <Flex fill>
                                    <TotalHeaderText content="" />
                                </Flex>
                            </HeaderTable>
                        </ContentHeader>
                    </Flex>

                    {/* body Table  */}

                    <ContentBody column>
                        {/* <Row column> */}
                        <Flex
                            styles={{
                                backgroundColor: "white",
                                width: "328px",
                                padding: "12px 20px",
                            }}
                        >
                            <Checkbox
                                disabled={filtredData?.length ? false : true}
                                onChange={handleAllCheckbox}
                                label="Sélectionner tous les collaborateurs"
                                checked={
                                    count_total_users_every_departments ===
                                    userIdChecked?.length
                                        ? true
                                        : userIdChecked?.length > 0
                                        ? "mixed"
                                        : false
                                }
                            />
                        </Flex>

                        <Flex column>
                            {activeTab === 0 && (
                                <Flex column>
                                    {ConfirmationDialog && (
                                        <DialogCancelConfirmation
                                            open={ConfirmationDialog}
                                            cancelButton={{
                                                content: "Annuler",
                                                flat: true,
                                            }}
                                            confirmButton={{
                                                content: "Valider les feuilles",
                                                flat: true,
                                                loading:
                                                    isLoadingValidation_or_RefuseTimeReporting,
                                            }}
                                            header="Voulez-vous vraiment valider les feuilles de temps ?"
                                            content={
                                                <Flex
                                                    column
                                                    gap="gap.medium"
                                                    styles={{
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <Flex
                                                        gap="gap.small"
                                                        vAlign="center"
                                                    >
                                                        <ExclamationTriangleIcon
                                                            styles={{
                                                                color: "#C4314B",
                                                            }}
                                                        />
                                                        <TextAlertDanger content="Vous allez valider à la place d'un ou plusieurs managers." />
                                                    </Flex>
                                                    <Text content="Les feuilles de temps seront validées et les managers seront notifiés que la demande a été validée à leur place. La saisie ne sera plus disponible pour le collaborateur. " />
                                                    <Flex
                                                        hAlign="center"
                                                        styles={{
                                                            marginTop: "15px",
                                                        }}
                                                    >
                                                        <Illustration
                                                            filename={
                                                                "time_reporting/time_reporting_empty_data.svg"
                                                            }
                                                            width="209px"
                                                        />
                                                    </Flex>
                                                </Flex>
                                            }
                                            onClose={() =>
                                                setConfirmationDialog(false)
                                            }
                                            onConfirm={Validate_time_reporting}
                                            width="566"
                                        />
                                    )}
                                    {RefuseDialog && (
                                        <DialogCancelConfirmation
                                            open={RefuseDialog}
                                            cancelButton={{
                                                content: "Annuler",
                                                flat: true,
                                            }}
                                            confirmButton={{
                                                content: "Refuser",
                                                flat: true,
                                                loading:
                                                    isLoadingValidation_or_RefuseTimeReporting,
                                            }}
                                            header="Voulez-vous vraiment refuser les feuilles de temps ?"
                                            content={
                                                <Flex
                                                    column
                                                    gap="gap.small"
                                                    styles={{
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <Flex
                                                        gap="gap.small"
                                                        vAlign="center"
                                                    >
                                                        <ExclamationTriangleIcon
                                                            styles={{
                                                                color: "#C4314B",
                                                            }}
                                                        />
                                                        <TextAlertDanger content="Vous allez refuser à la place d'un ou plusieurs managers." />
                                                    </Flex>
                                                    <TextDescription content="Les feuilles de temps seront refusées et les managers seront notifiés que la demande à été refusée à leur place. La ou les collaborateurs recevront une notification, la saisie sera de nouveau disponible." />
                                                </Flex>
                                            }
                                            onClose={() =>
                                                setRefuseDialog(false)
                                            }
                                            onConfirm={Refuse_time_reporting}
                                            width="566"
                                        />
                                    )}
                                    <MainTable column className="main_table">
                                        <TimeReportToValidate
                                            tab={activeTab}
                                            tabSociety={true}
                                            departments={filtredData}
                                            loading={isLoadingData}
                                            status={BADGE_STATUS.toValidate}
                                            users={filtredData}
                                            userIdChecked={userIdChecked}
                                            userIdToSend={userIdToSend}
                                            highlightedColumn={
                                                highlightedColumn
                                            }
                                            currentMonth={currentMonth}
                                        />
                                        {filtredData?.length === 0 &&
                                            data !== undefined && (
                                                <EmptyTable
                                                    actionText={"à approuver"}
                                                />
                                            )}
                                    </MainTable>

                                    <FooterButton
                                        vAlign="center"
                                        hAlign="end"
                                        gap="gap.small"
                                    >
                                        <ExportButton
                                            disabled={
                                                userIdChecked?.length
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => ExportSelectedData()}
                                            isLoading={isDownloading}
                                        />
                                        <Button
                                            styles={
                                                userIdChecked?.length
                                                    ? { color: "#C4314B" }
                                                    : ""
                                            }
                                            disabled={
                                                userIdChecked?.length
                                                    ? false
                                                    : true
                                            }
                                            loading={
                                                isLoadingValidation_or_RefuseTimeReporting
                                            }
                                            onClick={() =>
                                                setRefuseDialog(true)
                                            }
                                            iconPosition="after"
                                            icon={<ClockDismiss24Regular />}
                                            text
                                            content="Refuser les feuilles de temps"
                                        />
                                        <Button
                                            disabled={
                                                userIdChecked?.length
                                                    ? false
                                                    : true
                                            }
                                            onClick={() =>
                                                setConfirmationDialog(true)
                                            }
                                            iconPosition="after"
                                            icon={
                                                <ClipboardCheckmark24Regular />
                                            }
                                            flat
                                            primary
                                            content="Valider les feuilles de temps"
                                        />
                                    </FooterButton>
                                </Flex>
                            )}
                            {activeTab === 1 && (
                                <Flex column>
                                    {RelanceDialog && (
                                        <DialogCancelConfirmation
                                            open={RelanceDialog}
                                            cancelButton={{
                                                content: "Annuler",
                                                flat: true,
                                            }}
                                            confirmButton={{
                                                content: "Relancer",
                                                flat: true,
                                                loading:
                                                    isLoadingValidation_or_RefuseTimeReporting,
                                            }}
                                            header="Voulez-vous vraiment Relancer ?"
                                            content={
                                                <Flex hAlign="center" column>
                                                    <Text content="La ou les personnes recevront une notification, la saisie sera de nouveau disponible." />
                                                </Flex>
                                            }
                                            onClose={() =>
                                                setRelanceDialog(false)
                                            }
                                            onConfirm={Relance_time_reporting}
                                            width="566"
                                        />
                                    )}
                                    <MainTable column>
                                        {filtredData?.length ? (
                                            <TimeReportToValidate
                                                tab={activeTab}
                                                tabSociety={true}
                                                departments={filtredData}
                                                userIdChecked={userIdChecked}
                                                userIdToSend={userIdToSend}
                                                loading={isLoadingData}
                                                users={filtredData}
                                                length={1000}
                                                status={BADGE_STATUS.Incomplete}
                                                highlightedColumn={
                                                    highlightedColumn
                                                }
                                                currentMonth={currentMonth}
                                            />
                                        ) : (
                                            <EmptyTable
                                                actionText={"incomplète"}
                                            />
                                        )}
                                    </MainTable>
                                    <FooterButton
                                        vAlign="center"
                                        space="between"
                                        gap="gap.small"
                                    >
                                        <ExportButton
                                            disabled={
                                                userIdChecked?.length
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => ExportSelectedData()}
                                            isLoading={isDownloading}
                                        />
                                        <Button
                                            loading={
                                                isLoadingValidation_or_RefuseTimeReporting
                                            }
                                            onClick={() =>
                                                setRelanceDialog(true)
                                            }
                                            // disabled={
                                            //     filtredData?.length
                                            //         ? false
                                            //         : true
                                            // }
                                            disabled={
                                                userIdChecked?.length
                                                    ? false
                                                    : true
                                            }
                                            iconPosition="after"
                                            icon={<SendIcon />}
                                            flat
                                            primary
                                            content="Relancer les feuilles de temps"
                                        />
                                    </FooterButton>
                                </Flex>
                            )}
                            {activeTab === 2 && (
                                <Flex column>
                                    <MainTable column>
                                        {filtredData?.length ? (
                                            <TimeReportToValidate
                                                tab={activeTab}
                                                tabSociety={true}
                                                departments={filtredData}
                                                loading={isLoadingData}
                                                activeTab={activeTab}
                                                users={filtredData}
                                                length={filtredData?.length}
                                                userIdChecked={userIdChecked}
                                                userIdToSend={userIdToSend}
                                                status={BADGE_STATUS.Validated}
                                                highlightedColumn={
                                                    highlightedColumn
                                                }
                                                currentMonth={currentMonth}
                                            />
                                        ) : (
                                            <EmptyTable
                                                actionText={"validée"}
                                            />
                                        )}
                                    </MainTable>

                                    <FooterButton
                                        vAlign="center"
                                        hAlign="start"
                                        gap="gap.small"
                                    >
                                        <ExportButton
                                            disabled={
                                                userIdChecked?.length
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => ExportSelectedData()}
                                            isLoading={isDownloading}
                                        />
                                    </FooterButton>
                                </Flex>
                            )}
                        </Flex>
                    </ContentBody>
                </Wrapper>
            </Container>
        </ContainerFluid>
    );
};

export default TimeReportingSociety;

const TextDescription = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const TextAlertDanger = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    /* Small/600 */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
`;

const MainTable = styled(Flex)`
    max-height: 390px;
    overflow-x: hidden;
    overflow-y: scroll;
`;
const FooterButton = styled(Flex)`
    min-width: 1430px;
    padding: 16px 16px;
    border-bottom: 1px solid #d1d1d1;
    background: #fff;
    box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
        0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const ContentBody = styled(Flex)`
    /* background-color: #e8ebfa; */

    /* z-index: 2; */
`;

const TotalHeaderText = styled(Text)`
    color: #616161;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const HeaderText = styled(Text)`
    color: #242424;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding: 16px;
`;

const ContentHeader = styled(Flex)`
    z-index: 2;
    background: #fff;
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
    /* height: 525px; */
    padding-bottom: 24px;
    padding: 16px 24px;
    gap: 16px;
`;

const HeaderTable = styled(Flex)`
    gap: 48px;
`;

const Thead = styled(Flex)``;
const Wrapper = styled(Flex)`
    .cell.highlight {
        background-color: #ebebeb;
        transition: all 0.3s;
    }
`;
