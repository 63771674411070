import { Dialog } from "@fluentui/react-northstar";
import styled from "styled-components";

export const DialogStyled = styled(Dialog)`
    /* max-width: 414px; */
    max-width: clamp(304px, 50%, 414px);
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 12.8px 28.8px 0px rgba(0, 0, 0, 0.22),
        0px 2.4px 7.4px 0px rgba(0, 0, 0, 0.18);
`;
