import React, { useState } from "react";
import styled from "styled-components";
import { Avatar, Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import { AppsListDetailFilled } from "@fluentui/react-icons";

import { Container, ContainerFluid } from "../../../../common/styles";
import {
    get_theme_levels_color,
    LEVELS,
    LEVELS_BACKEND,
} from "../../components/Badges/Status";
import StatusFilled from "../../components/Badges/StatusFilled";
import RowTotal from "./RowTotal";
import RowTotalApproved from "./RowTotalApproved";
import RowCodeBudget from "./RowCodeBudget";
import RowsExpenseDetail from "../../components/Tables/RowsExpenseDetail";
import { useQuery } from "react-query";
import { I18N_NS_EXPENSE, QUERY_KEY, TYPE_FORM } from "../../constants";
import { _api_get_detail_report } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import EvolutionCard from "./EvolutionCard";
import EvolutionComment from "./EvolutionComment";
import RowTotalRefused from "./RowTotalRefused";
import { get_symbol_currency } from "../../functions";
import EmbedDocument from "../ExpensesManagerMyTeams/EmbedDocument";
import { ROUTES } from "../../../../constants/routes";
import BadgeIcon from "../../../employee_folder/components/BadgeIcon";
import StatusActionsDependRole, {
    STATUS_ROLE,
} from "../../components/Badges/StatusActionsDependRole";
import EmbedAndCommentsCard from "../../components/EmbedAndCommentsCard/EmbedAndCommentsCard";
import HeaderDetailExpenseReport from "./HeaderDetailExpenseReport/HeaderDetailExpenseReport";
import { Margin, Padding } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
import { DEVICE, SIZES } from "../../../../constants/responsive";
import Reinvoiceable from "../../components/Badges/Reinvoiceable";

const INIT_COMMENT = {
    id_expense: null,
    title_expense: "",
    content: "",
};

const ExpensesDetailReport = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const { id_report } = useParams();

    const navigate = useNavigate();

    // states
    const [toggleComment, setToggleComment] = useState(false);

    const [comments, setComments] = useState([]); // used for toggle between comments card.
    const [embed, setEmbed] = useState(null);
    const [selectedExpense, setSelectedExpense] = useState(null);

    const _goback = () => {
        return navigate(-1);
    };

    const { data: detail, isLoading } = useQuery(
        [QUERY_KEY + "::report_detail"],
        () => _api_get_detail_report({ id_expense_report: id_report })
    );

    const on_click_comment = (expense) => {
        const { documents, histories, id } = expense;

        if (comments?.length) {
            // clicked on the same line
            setEmbed(null);
            setComments([]);
            setSelectedExpense(null);
            return;
        }

        setComments(histories);
        setSelectedExpense(id);
        setEmbed(documents.at(0)?.attached_doc);

        setToggleComment(false);
        return;
    };

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    if (isLoading) {
        return <Loader />;
    }

    const {
        id: report_id,
        name,
        created_date,
        reinvoiceable,
        client,
        expenses,
        history,
        status,
        amount_total,
        currency,
        amount_approved,
        object_code,
        user,
        // eslint-disable-next-line no-unsafe-optional-chaining
    } = detail?.expense_report;
    const { textColor } = get_theme_levels_color(LEVELS_BACKEND[status?.value]); // this is used for showing colors : red, green, yellow

    const currency_symbol = get_symbol_currency(currency);
    const isMobile = isMobileS || isMobileM;

    return (
        <ContainerFluid styles={{ marginTop: "24px" }}>
            <ContainerStyle>
                <Wrapper
                    fill
                    column
                    gap="gap.small"
                    styles={{
                        height: "fit-content",
                        padding: "8px",
                    }}
                >
                    <Flex column fill gap="gap.medium">
                        <Flex fill space="between" vAlign="center">
                            <Flex vAlign="center" gap="gap.small">
                                <Title content={name} />
                                {reinvoiceable && (
                                    <Reinvoiceable client_name={client?.name} />
                                )}
                            </Flex>

                            <Button
                                content={t("common.back")}
                                text
                                primary
                                iconOnly
                                onClick={_goback}
                            />
                        </Flex>
                        <Flex space="between" fill>
                            <Flex column gap="gap.smaller">
                                <CreatedAt
                                    content={
                                        t("report.header.create_at") +
                                        moment(created_date).format(
                                            "DD/MM/YYYY"
                                        )
                                    }
                                />
                            </Flex>
                            <Flex column vAlign="end">
                                <ButtonEvolution
                                    content={history?.length}
                                    icon={<AppsListDetailFilled />}
                                    onClick={() => {
                                        setToggleComment((state) => !state);
                                        setComments([]);
                                        setEmbed(null);
                                        return;
                                    }}
                                    iconPosition={"after"}
                                    active={toggleComment}
                                    text
                                    primary
                                    iconOnly
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                    {/* white section */}
                    <WhiteBoard fill column gap="gap.large">
                        {/* header */}
                        <HeaderDetailExpenseReport
                            status={status}
                            user={user}
                            role={STATUS_ROLE.employee}
                        />
                        <FlexBody>
                            <RowsExpenseDetail
                                selectedExpense={selectedExpense}
                                isMobile={isMobile}
                                expenses={expenses}
                                note_status={status?.value}
                                id_report={report_id}
                                refetch_get_all_expenses={() =>
                                    console.log("refesh")
                                }
                                onClickTitle={({ id_expense, id_report }) => {
                                    // rejected
                                    const status_expense =
                                        detail?.expense_report?.status?.value;

                                    if (status_expense === 4) {
                                        // navigate(
                                        //     ROUTES.expenses.update_form.custom_route(
                                        //         id_report,
                                        //         id_expense,
                                        //         TYPE_FORM.classic
                                        //     )
                                        // );
                                        return;
                                    }
                                }}
                                can_i_delete={false}
                                on_click_comment={on_click_comment}
                                id_expense_of_selected_comment={null}
                                is_employee_page={true}
                            />
                        </FlexBody>
                        <FlexFooter column gap="gap.medium">
                            <RowTotal
                                total={amount_total}
                                currency={currency_symbol}
                            />
                            {/* when the status is refused, show the message "pas de remboursement" */}
                            {LEVELS_BACKEND[status?.value] ===
                                LEVELS.refuse && (
                                <RowTotalRefused
                                    total={"0,00"}
                                    currency={currency_symbol}
                                />
                            )}

                            {LEVELS_BACKEND[status?.value] ===
                                LEVELS.approuve && (
                                <RowTotalApproved
                                    total={amount_approved}
                                    currency={currency_symbol}
                                />
                            )}

                            {object_code?.name && (
                                <RowCodeBudget code={object_code?.name} />
                            )}
                        </FlexFooter>
                    </WhiteBoard>
                </Wrapper>
                {/* right section */}
                <Flex>
                    {toggleComment && (
                        <Flex
                            fill
                            styles={{
                                marginTop: "8px",
                            }}
                        >
                            <EvolutionCard
                                history={history}
                                amount_approved={amount_approved}
                                currency={currency_symbol}
                                setToggleComment={setToggleComment}
                            />
                        </Flex>
                    )}
                </Flex>
                <ExpensesCommentCard fill>
                    {(comments.length || embed) && (
                        <EmbedAndCommentsCard
                            comments={comments}
                            embed={embed}
                            onClose={() => {
                                setEmbed(null);
                                setComments([]);
                                setSelectedExpense(null);
                            }}
                        />
                    )}
                </ExpensesCommentCard>
            </ContainerStyle>
        </ContainerFluid>
    );
};

const ExpensesCommentCard = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 300px;
        margin: 0 auto;
    }
    @media ${DEVICE.mobileM} {
        width: 355px;
        margin: 0 auto;
    }
    @media ${DEVICE.mobileL} {
        width: 405px;
        margin: 0 auto;
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        margin: 0;
    }
    @media ${DEVICE.laptopL} {
        margin: 0;
    }
`;

export default ExpensesDetailReport;

export const Header = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        align-items: center;
    }
`;
export const Agency = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 2px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 16px;
    }
`;

export const AvatarStyle = styled(Avatar)`
    width: clamp(32px, 3.5vw, 40px);
    height: clamp(32px, 3.5vw, 40px);
    @media ${DEVICE.tablet} {
        width: 40px;
        height: 40px;
    }
`;

export const TextUsername = styled(Text)`
    color: black;
    font-size: clamp(14px 2vw 24px);
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: 60px;
    white-space: nowrap;
`;

export const ButtonEvolution = styled(Button)`
    background-color: ${({ active }) => (!active ? "transparent" : "#EBEBEB")};
    padding-inline: 8px;
`;

const ContainerStyle = styled(Flex)`
    max-width: 1440px;
    margin: 0 auto;
    /* border: 2px solid red; */

    /* width: 100%; */
    @media ${DEVICE.mobileS || DEVICE.mobileM || DEVICE.mobileL} {
        width: 100%;
        max-width: auto;
        flex-direction: column;
        padding: 0px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 6px;
        padding: 12px;
    }
    @media ${DEVICE.laptopL} {
        flex-direction: row;
        gap: 32px;
        padding: 12px;
    }
`;

const RefacturableClient = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #616161;
`;

const RefacturableText = styled(Text)`
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    color: #242424;
`;

const WhiteBoard = styled(Flex)`
    background-color: white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    padding: 16px 16px 32px 16px;
    border-radius: 8px;
`;

const FlexFooter = styled(Flex)``;
const FlexBody = styled(Flex)``;

const CreatedAt = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;

    color: #616161;
`;

const Wrapper = styled(Flex)`
    width: 100%;
    @media ${DEVICE.laptop} {
        max-width: 630px;
    }
    @media ${DEVICE.laptopL} {
        max-width: 820px;
    }
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #242424;
`;
