import React from "react";
import { Card, CardContent, Hidden, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AsenditLogo from "../../assets/logos/asendit_logo.svg";
import DashbordImage from "./imgHomePage/dashboard.svg";
import documentsRH from "./imgHomePage/documentsRH.svg";
import onboarding from "./imgHomePage/onboarding.svg";
import profil from "./imgHomePage/profil.svg";
import { Box } from "@mui/system";
import HighLightCard from "../Dashboard/MainContent/HighLightCard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";

import HailIcon from "@mui/icons-material/Hail";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import DateRangeIcon from "@mui/icons-material/DateRange";

export default function HomePage() {
    const navigate = useNavigate();
    return (
        <Body>
            {/* <div
        style={{ width: "100%", height: "30px", backgroundColor: "F5F5F5" }}
      ></div> */}
            <Header style={{ marginTop: "15px" }}>
                <Img src={AsenditLogo} alt={"Asendit Logo"} />
                <TypographyStyle variant="h6">
                    Choisissez le module que vous souhaitez utiliser :
                </TypographyStyle>
            </Header>
            <BoxStyle>
                <HighLightCard
                    icon={
                        <GroupAddIcon
                            style={{
                                backgroundColor: "#673AB7",
                                borderRadius: "50%",
                                color: "white",
                                padding: "10px",
                                width: "48px",
                                height: "48px",
                            }}
                            fontSize="small"
                        />
                    }
                    trending={
                        <TrendingUpIcon
                            style={{ color: "#4caf50", marginRight: "6px" }}
                        />
                    }
                    text={"+12,3 (+3) "}
                    number={"88%"}
                    BottomText={"Compléter votre profil"}
                />
                <HighLightCard
                    icon={
                        <HailIcon
                            style={{
                                backgroundColor: "#FF9800",
                                borderRadius: "50%",
                                color: "white",
                                padding: "10px",
                                width: "48px",
                                height: "48px",
                            }}
                            fontSize="small"
                        />
                    }
                    trending={
                        <ArrowRightAltOutlinedIcon
                            style={{ color: "grey", marginRight: "6px" }}
                        />
                    }
                    text={"+ 0 % (+0)"}
                    number={"13"}
                    BottomText={"Nombre de jour de congé"}
                />
                <HighLightCard
                    icon={
                        <MoodBadIcon
                            style={{
                                backgroundColor: "#03A9F4",
                                borderRadius: "50%",
                                color: "white",
                                padding: "10px",
                                width: "48px",
                                height: "48px",
                            }}
                            fontSize="small"
                        />
                    }
                    trending={
                        <TrendingUpIcon
                            style={{ color: "#4caf50", marginRight: "6px" }}
                        />
                    }
                    text={"+12,3 (+3) "}
                    number={"4"}
                    BottomText={"Nombre des taches a éffectuer"}
                />

                <HighLightCard
                    icon={
                        <DateRangeIcon
                            style={{
                                backgroundColor: "#00BCD4",
                                borderRadius: "50%",
                                color: "white",
                                padding: "10px",
                                width: "48px",
                                height: "48px",
                            }}
                            fontSize="small"
                        />
                    }
                    trending={
                        <TrendingUpIcon
                            style={{ color: "#4caf50", marginRight: "6px" }}
                        />
                    }
                    text={"+28 % (+9)"}
                    number={"6"}
                    BottomText={"Absences"}
                />
            </BoxStyle>
            <Container style={{ margin: "25px" }}>
                <Card style={{ width: "275px" }}>
                    <CardContent
                        onClick={() => navigate("/dashboard")}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            style={{
                                width: "150px",
                                height: "120px",
                            }}
                            src={DashbordImage}
                            alt={"Dashbord Module"}
                        />
                        <Typography variant="h6">Dashboard</Typography>
                    </CardContent>
                </Card>
                <Card style={{ width: "275px", border: "8px" }}>
                    <CardContent
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            style={{
                                width: "150px",
                                height: "120px",
                            }}
                            src={documentsRH}
                            alt={"Dashbord Module"}
                        />
                        <Typography variant="h6">Documents RH</Typography>
                    </CardContent>
                </Card>
                <Card style={{ width: "275px", border: "8px" }}>
                    <CardContent
                        onClick={() => navigate("/onboarding")}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            style={{
                                width: "150px",
                                height: "120px",
                            }}
                            src={onboarding}
                            alt={"Dashbord Module"}
                        />
                        <Typography variant="h6">On boarding</Typography>
                    </CardContent>
                </Card>
                <Card style={{ width: "275px", border: "8px" }}>
                    <CardContent
                        onClick={() => navigate("/profil")}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            style={{
                                width: "150px",
                                height: "120px",
                            }}
                            src={profil}
                            alt={"Dashbord Module"}
                        />
                        <Typography variant="h6">Page profile</Typography>
                    </CardContent>
                </Card>
            </Container>
        </Body>
    );
}

const Header = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        backgroundColor: "#FFFFFF",
        width: "100vw",
        height: "152px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
        backgroundColor: "#FFFFFF",
        width: "100vw",
        height: "152px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
        backgroundColor: "#FFFFFF",
        width: "100vw",
        height: "152px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
        backgroundColor: "#FFFFFF",
        width: "100%",
        height: "152px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}));

const Img = styled("img")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        width: "225px",
        height: "76px",
    },
    [theme.breakpoints.down("sm")]: {
        width: "225px",
        height: "76px",
    },
    [theme.breakpoints.up("md")]: {
        width: "225px",
        height: "76px",
    },
    [theme.breakpoints.up("lg")]: {
        width: "225px",
        height: "76px",
    },
}));

const TypographyStyle = styled("Typography")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        textAlign: "center",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.6)",
        fontWeight: "400",
        FontSize: "16px",
        margin: "15px",
    },
    [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.6)",
        fontWeight: "400",
        FontSize: "16px",
        margin: "15px",
    },
    [theme.breakpoints.up("md")]: {
        textAlign: "center",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.6)",
        fontWeight: "400",
        FontSize: "16px",
        margin: "15px",
    },
    [theme.breakpoints.up("lg")]: {
        // border: "2px solid blue",
        textAlign: "center",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.6)",
        fontWeight: "400",
        FontSize: "16px",
        margin: "15px",
    },
}));
const Body = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        // border: "2px solid blue",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#F5F5F5",
    },
}));
const Container = styled("Box")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        backgroundColor: "#F5F5F5",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "25px",
        padding: "20px",
        flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
        backgroundColor: "#F5F5F5",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "25px",

        padding: "20px",
        flexWrap: "wrap",
    },
    [theme.breakpoints.up("md")]: {
        backgroundColor: "#F5F5F5",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "25px",

        padding: "20px",
        flexWrap: "wrap",
    },
    [theme.breakpoints.up("lg")]: {
        backgroundColor: "#F5F5F5",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "25px",
        padding: "20px",
        flexWrap: "wrap",

        height: "300px",
    },
}));

const BoxStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        justifyContent: "center",
        flexDirection: "row",
        gap: "25px",
        display: "none",
        margin: "25px",
        flexWrap: "wrap",
        backgroundColor: "#F5F5F5",

        padding: "25px",
    },
    [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        flexDirection: "row",
        gap: "25px",

        display: "none",
        margin: "25px",
        flexWrap: "wrap",
        backgroundColor: "#F5F5F5",
    },
    [theme.breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        gap: "25px",
        // border: "1px solid red",
        margin: "25px",
        flexWrap: "wrap",
        backgroundColor: "#F5F5F5",
    },
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        gap: "25px",
        // border: "1px solid red",
        margin: "25px",
    },
}));
