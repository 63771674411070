import moment from "moment";
import { extendMoment } from "moment-range";

const extend_moment = extendMoment(moment);
const REFERENCE_DATE = "2023-01-02T";

export const ACTION_TYPES_SLOTS = {
    add_new_slot: "add_new_slot",
    delete_slot: "delete_slot",
    set_shifts: "set_shifts",
    set_comment: "set_comment",
};

const isNewRangeBetweenSlots = ({ new_range, index, shifts }) => {
    for (let i = 0; i < shifts.length; i++) {
        const shift = shifts[i];
        const start = shift.start_time;
        const end = shift.end_time;

        if ((start !== "00:00:00" || end !== "00:00:00") && i !== index) {
            const new_start = new_range.start;
            const new_end = new_range.end;

            const range1 = extend_moment.range(
                "2023-01-02T" + new_start,
                "2023-01-02T" + new_end
            );
            const range2 = extend_moment.range(
                "2023-01-02T" + start,
                "2023-01-02T" + end
            );

            const isIntersect = range1.overlaps(range2, {
                adjacent: false,
            });

            if (isIntersect) {
                return true;
            }
        }
    }
    return false;
};

export const reducerSlots = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES_SLOTS.delete_slot: {
            const id = action.payload.id;
            const new_state = state.filter((shift) => shift.id !== id);
            // re verifier si y'a eu des chevauchement ou pas 👇
            const new_state_refresh_errors = new_state.map(
                (shift, shift_index) => {
                    const start_time = shift?.start_time;
                    const end_time = shift?.end_time;

                    const moment_start = moment(shift?.start_time, "HH:mm:ss");
                    const moment_end = moment(shift?.end_time, "HH:mm:ss");
                    const isStartAfterEnd =
                        shift?.end_time !== "00:00:00"
                            ? moment_start.isAfter(moment_end) ||
                              moment_start.format("HH:mm:ss") ===
                                  moment_end.format("HH:mm:ss")
                            : false;
                    if (isStartAfterEnd) {
                        // erreur sur les deux champs
                        return {
                            ...shift,
                            errors: {
                                f1: true,
                                f2: true,
                            },
                        };
                    } else {
                        if (shift?.errors?.f1 || shift?.errors?.f2) {
                            // une erreur sur le field 1
                            const isInterfer = isNewRangeBetweenSlots({
                                new_range: {
                                    start: start_time,
                                    end: end_time,
                                },
                                index: shift_index,
                                shifts: new_state,
                            });
                            return {
                                ...shift,
                                errors: {
                                    f1: isInterfer,
                                    f2: isInterfer,
                                },
                            };
                        } else {
                            // aucune erreur
                            return shift;
                        }
                    }
                }
            );
            return new_state_refresh_errors;
        }
        case ACTION_TYPES_SLOTS.add_new_slot: {
            return [
                ...state,
                {
                    id: new Date().getTime(),
                    locked: false,
                    start_time: "00:00:00",
                    end_time: "00:00:00",
                    duration: null,
                    type: {
                        value: 0,
                        value_str: "Heure de travail",
                        leave_name: null,
                    },
                },
            ];
        }
        case ACTION_TYPES_SLOTS.set_shifts: {
            return action.payload.shifts;
        }
        case ACTION_TYPES_SLOTS.set_comment: {
            console.log({ comment: action.payload.comment });
            const new_comment = action.payload.comment;
            const day_id = action.payload.day_id;
            console.log({ new_comment, state, day_id });
            return state;
        }
        default:
            return state;
    }
};
