import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import OnboardingEnCours from "./OnboardingEnCours";

import { useNavigate } from "react-router-dom";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import HighLightCard from "./HighLightCard";
import HailIcon from "@mui/icons-material/Hail";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DetailsTable from "../../OnBoarding/onboardingPage/DetailsTable";

function MainContent(props) {
    const navigate = useNavigate();

    return (
        <Container
            style={{
                width: "100%",
                margin: "30px",
                overflowX: "hidden",
            }}
        >
            <Box
                marginTop={"80px"}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                {/* Rechercher un collaborateur */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-end",
                    }}
                >
                    <TextField
                        id="input-with-sx"
                        label="Rechercher"
                        variant="standard"
                        style={{ width: "300px" }}
                    />
                    <SearchIcon
                        sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                </Box>
                {/* Ajouter un  nouveaux collaborateur */}
                <Button
                    onClick={() => navigate("/annuaire")}
                    variant="contained"
                    endIcon={<AddIcon />}
                >
                    <span>Annuaire</span>
                </Button>
                <Button
                    onClick={() => navigate("/addCollaborator")}
                    variant="contained"
                    endIcon={<AddIcon />}
                >
                    <span>Ajouter un collaborateur</span>
                </Button>
            </Box>

            {/* HighLights */}
            <Typography
                style={{ margin: "20px", paddingTop: "10px" }}
                fontSize={24}
                fontWeight={500}
            >
                Chiffre clés du mois
            </Typography>
            <Divider style={{ margin: "40px" }} />

            <Analytics
                style={{
                    display: "flex",
                    //   flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    paddingInline: "25px",
                }}
                // sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
            >
                <HighLightCard
                    icon={
                        <GroupAddIcon
                            style={{
                                backgroundColor: "#673AB7",
                                borderRadius: "50%",
                                color: "white",
                                padding: "10px",
                                width: "48px",
                                height: "48px",
                            }}
                            fontSize="small"
                        />
                    }
                    trending={
                        <TrendingUpIcon
                            style={{ color: "#4caf50", marginRight: "6px" }}
                        />
                    }
                    text={"+12,3 (+3) "}
                    number={"56"}
                    BottomText={"ONBOARDING"}
                />

                <HighLightCard
                    icon={
                        <HailIcon
                            style={{
                                backgroundColor: "#FF9800",
                                borderRadius: "50%",
                                color: "white",
                                padding: "10px",
                                width: "48px",
                                height: "48px",
                            }}
                            fontSize="small"
                        />
                    }
                    trending={
                        <ArrowRightAltOutlinedIcon
                            style={{ color: "grey", marginRight: "6px" }}
                        />
                    }
                    text={"+ 0 % (+0)"}
                    number={"23"}
                    BottomText={"OFFBOARDING"}
                />
                <HighLightCard
                    icon={
                        <MoodBadIcon
                            style={{
                                backgroundColor: "#03A9F4",
                                borderRadius: "50%",
                                color: "white",
                                padding: "10px",
                                width: "48px",
                                height: "48px",
                            }}
                            fontSize="small"
                        />
                    }
                    trending={
                        <TrendingUpIcon
                            style={{ color: "#4caf50", marginRight: "6px" }}
                        />
                    }
                    text={"+12,3 (+3) "}
                    number={"40"}
                    BottomText={"TURNOVER"}
                />

                <HighLightCard
                    icon={
                        <DateRangeIcon
                            style={{
                                backgroundColor: "#00BCD4",
                                borderRadius: "50%",
                                color: "white",
                                padding: "10px",
                                width: "48px",
                                height: "48px",
                            }}
                            fontSize="small"
                        />
                    }
                    trending={
                        <TrendingUpIcon
                            style={{ color: "#4caf50", marginRight: "6px" }}
                        />
                    }
                    text={"+28 % (+9)"}
                    number={"34"}
                    BottomText={"JOURS DE CONGÉS DU MOIS"}
                />
            </Analytics>
            <Typography
                style={{
                    margin: "20px",
                    paddingTop: "50px",
                    fontWeight: "bold",
                }}
                variant="body1"
            >
                Nouveaux Arrivant
            </Typography>
            {/* Nouveau Arrivant  */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 25,
                    alignItems: "center",
                    overflowX: "scroll",
                    minWidth: "100%",
                    padding: "15px",
                }}
            >
                {[1, 2, 3, 4, 5, 6, 7].map((el, i) => {
                    return (
                        <Card
                            key={i}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "0px",
                                minWidth: 250,
                            }}
                            elevation={2}
                        >
                            <CardHeader
                                avatar={
                                    <Avatar
                                        alt="Bruno Bruno"
                                        src={"/imgTest/image" + el + ".jpg"}
                                        sx={{
                                            width: 80,
                                            height: 80,
                                            justifyContent: "center",
                                        }}
                                    />
                                }
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            />
                            <CardContent
                                style={{
                                    justifyContent: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    paddingTop: "0px",
                                }}
                            >
                                <Typography
                                    fontWeight={900}
                                    variant="body1"
                                    component="a"
                                    style={{ cursor: "pointer" }}
                                >
                                    Bruno Foued
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "15px",
                                        color: "gray",
                                        fontStyle: "italic",
                                    }}
                                    pb="5px"
                                    variant="body1"
                                    component="div"
                                >
                                    Developpeur Full stack.
                                </Typography>
                                <Typography
                                    style={{
                                        color: "gray",
                                        opacity: "0.7",
                                    }}
                                    variant="caption"
                                    gutterBottom
                                >
                                    Arrivé le 01/04
                                </Typography>
                                <br />
                                <Button variant="outlined" size="small">
                                    Voir détail
                                </Button>
                            </CardContent>
                        </Card>
                    );
                })}
            </div>
            <Typography
                style={{
                    margin: "20px",
                    paddingTop: "10px",
                    fontWeight: "bold",
                }}
                variant="body1"
            >
                On boarding en cours
            </Typography>

            {/* <OnboardingEnCours /> */}
            {/* <DetailsTable /> */}
        </Container>
    );
}

export default MainContent;

const Analytics = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        gap: "10px",
        width: "100%",
        flexWrap: "wrap",
    },
    [theme.breakpoints.up("sm")]: {
        gap: "20px",

        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
    },
    [theme.breakpoints.up("md")]: {
        maxWidth: "100vw",
        gap: "10px",
    },
    [theme.breakpoints.up("lg")]: {
        width: "100%",
    },
}));

const Container = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        width: "70vw",
    },
    [theme.breakpoints.up("md")]: {
        width: "65vw",
    },
    [theme.breakpoints.up("lg")]: {
        width: "80vw",
    },
}));
