import React, { useState } from "react";
import { ContainerFluid } from "../../../../../../common/styles";
import { Button, Checkbox, Flex } from "@fluentui/react-northstar";
import DayCard from "../../../DayCard/DayCard";
import { CheckBoxEnableTimeReporting } from "./index.style";
import Planning from "../../../Planning/Planning";
import MeansSeizure from "../../../MeansSeizure/MeansSeizure";
import AdditionalOptions from "../../../AdditionalOptions/AdditionalOptions";
import useTimetable from "../../../../hooks/useTimetable";
import { ACTIONS_TIMETABLE } from "../../../../reducers/timetable.reducer";
import Toast from "../../../../../../components/Alerts";

const TabTimetable = () => {
    const { reducer, forms, mutations, alert } = useTimetable();

    return (
        <ContainerFluid column gap="gap.large">
            {/* alerts */}
            <Flex>
                <Toast
                    visible={alert.toast.visible}
                    content={alert.toast.content}
                    level={alert.toast.level}
                    onStatusChange={() => {
                        return alert.setToast((toast) => ({
                            ...toast,
                            visible: false,
                        }));
                    }}
                />
            </Flex>
            {/* toggle activation */}
            <Flex>
                <CheckBoxEnableTimeReporting
                    label="Saisie du temps"
                    onChange={(e, i) => {
                        reducer.dispatch({
                            type: ACTIONS_TIMETABLE.toggle_time_reporting_activated,
                            payload: i.checked,
                        });
                        if (!i.checked) {
                            forms.on_submit_save_modification(e, false);
                        }
                        return;
                    }}
                    checked={reducer.state?.time_reporting_activated}
                    toggle
                />
            </Flex>
            {/* body */}
            <Flex>
                {reducer.state?.time_reporting_activated && (
                    <Flex column gap="gap.large">
                        {/* planning */}
                        <Planning
                            timetable={reducer.state?.timetable}
                            timetable_templates={
                                reducer.state?.timetable_templates
                            }
                            onSuccessSavePlanning={() => {
                                alert.setToast((toast) => ({
                                    ...toast,
                                    visible: true,
                                    content: "Planning appliqué",
                                    level: "success",
                                }));
                                return;
                            }}
                        />
                        {/* moyens de saisie */}
                        <MeansSeizure
                            state={reducer.state}
                            dispatch={reducer.dispatch}
                        />
                        {/* options suplémentaire */}
                        <AdditionalOptions
                            state={reducer.state}
                            dispatch={reducer.dispatch}
                        />
                        {/* buttons actions */}
                        <Flex gap="gap.medium">
                            <Button
                                content="Réinitialiser les changements"
                                flat
                                onClick={forms.resetForm}
                            />
                            <Button
                                content="Enregistrer les modifications"
                                onClick={(e) =>
                                    forms.on_submit_save_modification(e)
                                }
                                loading={mutations.update_timetable.isLoading}
                                disabled={!forms.isDirty}
                                primary
                                flat
                            />
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </ContainerFluid>
    );
};

export default TabTimetable;
