import React from "react";
import {
    BadgeDefault,
    BadgeSubscription,
    Content,
    HeaderContent,
    HeaderDescription,
    Wrapper,
} from "./index.style";
import { Flex, Image } from "@fluentui/react-northstar";

const Tile = ({
    icon,
    content,
    desc,
    $selected = false,
    $disabled = false,
    $showBadge = false,
    $default = false,
    ...rest
}) => {
    return (
        <Wrapper
            gap="gap.medium"
            $selected={$selected}
            $disabled={$disabled}
            $default={$default}
            {...rest}
        >
            <Content gap="gap.medium">
                <Flex>
                    <Image src={icon} alt={`img_${content}`} />
                </Flex>
                <Flex column vAlign="center" fill>
                    <Flex vAlign="center" space="between">
                        <HeaderContent content={content} />
                        {$showBadge && (
                            <BadgeSubscription content={"Sur abonnement"} />
                        )}
                        {$default && <BadgeDefault content={"Par défaut"} />}
                    </Flex>
                    <HeaderDescription content={desc} />
                </Flex>
            </Content>
        </Wrapper>
    );
};

export default Tile;
