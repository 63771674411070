import { AcceptIcon, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

export default function StatusBadge({ status, status_text }) {
    const status2 = [
        {
            id: 1,
            name: "Compte créé",
            status_name: "waiting_preonboarding",
        },
        {
            id: 2,
            name: "Pré-onboarding",
            status_name: "preonboarding",
        },
        {
            id: 3,
            name: "Onboarding",
            status_name: "end_onboarding",
            status_text: "onboarding",
        },
        {
            id: 4,
            name: "Suivi",
        },
    ];

    // console.log({ status6565: status });

    const stepper = status2.find(
        (item) => item?.status_name === status || item?.status_text === status
    );

    return (
        <Flex gap="gap.large">
            {status2?.map((statusItem) => {
                const highlight = statusItem?.id == stepper?.id;
                console.log({ status1: statusItem?.id });
                console.log({ status2: stepper?.id });
                return (
                    <BadgeStatus key={statusItem?.id} column>
                        {stepper?.id > statusItem?.id && (
                            <StatusNumber
                                color={"#616161"}
                                content={
                                    <AcceptIcon styles={{ color: "#616161" }} />
                                }
                            />
                        )}
                        {stepper?.id < statusItem?.id && (
                            <StatusNumber
                                color={"#C7C7C7"}
                                content={statusItem?.id}
                            />
                        )}
                        {stepper?.id === statusItem?.id && (
                            <StatusNumber
                                bgColor={"#5B5FC7"}
                                color={"#fff"}
                                content={statusItem?.id}
                            />
                        )}

                        {stepper?.id > statusItem?.id && (
                            <StepDone content={statusItem?.name} />
                        )}
                        {stepper?.id == statusItem?.id && (
                            <CurrentStep content={statusItem?.name} />
                        )}
                        {stepper?.id < statusItem?.id && (
                            <NextStep content={statusItem?.name} />
                        )}
                    </BadgeStatus>
                );
            })}
        </Flex>
    );
}

const NextStep = styled(Text)`
    color: #c7c7c7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
const CurrentStep = styled(Text)`
    color: #5b5fc7;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;
const StepDone = styled(Text)`
    color: #616161;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const StatusNumber = styled(Text)`
    /* color: ${({ highlight }) => (!highlight ? "#C7C7C7" : "#fff")}; */
    color: ${({ color }) => color};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    border-radius: 40px;
    /* background-color: ${({ highlight }) =>
        highlight ? "#5b5fc7" : "#ffffff"}; */
    background-color: ${({ bgColor }) => bgColor};
    width: 28px;
    height: 28px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    outline: 2px solid ${({ color }) => color};
`;

const BadgeStatus = styled(Flex)`
    width: 120px;
    height: 54px;
    justify-content: center;
    align-items: center;
    gap: 4px;
`;

const StatusName = styled(Text)`
    color: ${({ disabled }) => (!disabled ? "#5b5fc7" : "#C7C7C7")};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;
