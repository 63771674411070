import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../stores/useAuthStore";
import { ROUTES } from "../constants/routes";

const withAuth = (WrappedComponent) => {
    // eslint-disable-next-line react/display-name
    return (props) => {
        const { infos: user } = useAuthStore();

        return user ? (
            <WrappedComponent {...props} />
        ) : (
            <Navigate
                to={ROUTES.login}
                replace
                state={{ path: location.pathname }}
            />
        );
    };
};

export default withAuth;
