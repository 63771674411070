import { Flex, InfoIcon, Text, Tooltip } from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";

const Reinvoiceable = ({ client_name = "" }) => {
    const [open, setOpen] = useState(false);

    return (
        <Wrapper
            hAlign="center"
            vAlign="center"
            gap="gap.smaller"
            onMouseLeave={() => {
                return setOpen(false);
            }}
        >
            <Content content={"Refacturable"} />
            <div
                style={{
                    height: "fit-content",
                    display: "flex",
                }}
            >
                <Tooltip
                    key="tootltip_refacturable"
                    open={open}
                    trigger={
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <InfoIconStyle
                                outline={!open}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpen(true);
                                }}
                                size="small"
                            />
                        </div>
                    }
                    content={client_name}
                    subtle={false}
                    pointing
                    align="start"
                    position="after"
                />
            </div>
        </Wrapper>
    );
};

export default Reinvoiceable;

const InfoIconStyle = styled(InfoIcon)`
    cursor: pointer;
    color: #616161;
`;

const Content = styled(Text)`
    color: #616161;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const Wrapper = styled(Flex)`
    height: 24px;
    padding: 4px 8px;
    gap: 4px;

    border-radius: 4px;
    background: #ebebeb;
    position: relative;
`;
