import React from "react";
import {
    Button,
    CloseIcon,
    Dialog,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Loader,
    RadioGroup,
    Text,
} from "@fluentui/react-northstar";
import Card from "..";
import Img from "../../../../../assets/illustrations/employee_folder/time_job_salary.svg";
import TimeJobAndSalaryContent, {
    Title,
} from "./Components/TimeJobAndSalaryContent";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEmployeeFolderIsMe } from "../../../store";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
    _api_get_leave_policy,
    _api_get_package_type_standard_ids,
    _api_update_contract,
    _api_update_my_personal_information,
} from "../../../api";
import { QUERY_KEY } from "../../../constants";
import {
    ACTIVITY_RATE,
    RTT_POLICY,
    SALARY_PERIOD,
    SALARY_TYPE,
} from "../../../../../constants/lists";
import styled from "styled-components";
import { get_symbol_currency } from "../../../../expenses/functions";
import { ROLES_CODE, roles_access } from "../../../../../constants/roles";
import { ErrorMessage } from "../../../../../common/styles";
import { DialogConfirmationUpdateHoursWorked } from "../../DialogConfirmationUpdateHoursWorked/DialogConfirmationUpdateHoursWorked";
import Planning from "./Components/Planning";
import { comparerJSON } from "../../../utils";

const TimeJobAndSalary = ({ contract_data }) => {
    const clientQuery = useQueryClient();

    const [modeUpdate, setModeUpdate] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState({
        visible: false,
        message_hours_worked: "",
        message_leave_policy: "",
        message_timetable: "",
    });

    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });

    const method = useForm({
        mode: "onChange",
        defaultValues: {
            package_type: contract_data?.package_type?.id ?? 1,
            salary_currency: contract_data?.salary?.currency,
            timetable: contract_data?.timetable,
        },
    });

    // GET leave policy
    const { data: leave_policy_data, isLoading: leave_policy_data_loading } =
        useQuery(
            [QUERY_KEY + "::get_leave_policy"],
            () => _api_get_leave_policy(),
            {
                refetchOnWindowFocus: false,
            }
        );

    const { mutate: mutate_update_contract, isLoading: is_loading_mutate } =
        useMutation((data) => _api_update_contract(data));

    if (leave_policy_data_loading) {
        return <Loader content="chargement des données" />;
    }

    const package_type = method.watch(
        "package_type",
        contract_data?.package_type?.id
    );

    const _setModeUpdate = (bool) => {
        if (!bool) {
            method.reset();
        }
        return setModeUpdate(bool);
    };

    const onSubmit = (data) => {
        mutate_update_contract(
            {
                ...data,
                leave_policy_id: data?.leave_policy?.id,
                rtt_policy: data?.rtt_policy?.id,
                part_time_percentage: parseFloat(data?.part_time_percentage),
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.resetQueries([QUERY_KEY + "::contract"]);
                        _setModeUpdate(false);
                    }
                    return;
                },
            }
        );
    };

    const _handleSubmit = (e) => {
        e.preventDefault();
        const old_hour_count_per_week = contract_data?.hour_count_per_week;
        const new_hour_count_per_week = Number(
            method.getValues("hour_count_per_week")
        );

        const old_leave_policy_id = contract_data?.leave_policy?.id;
        const new_leave_policy_id = method.getValues("leave_policy")?.id;

        const old_timetable = contract_data?.timetable;
        const new_timetable = method.getValues("timetable");

        const is_alter_timetable = !comparerJSON(old_timetable, new_timetable);

        const content_message = {
            message_hours_worked:
                package_type === 1 &&
                old_hour_count_per_week !== new_hour_count_per_week ? (
                    <div>
                        La <u>modification des heures travaillées</u> par
                        semaine <b>impactera la déclaration du temps</b> future
                        de la personne concernée."
                    </div>
                ) : (
                    ""
                ),
            message_leave_policy:
                old_leave_policy_id !== new_leave_policy_id ? (
                    <div>
                        <u>
                            Les soldes de congés seront automatiquement
                            transférés
                        </u>
                        <b> dans les types de congés correspondants</b> de la
                        nouvelle politique d’absence.
                    </div>
                ) : (
                    ""
                ),
            message_timetable: is_alter_timetable ? (
                <div>
                    <u>L'emploi du temps</u> défini par le manager{" "}
                    <b>sera remis à zéro</b>
                </div>
            ) : (
                ""
            ),
        };

        if (
            (package_type === 1 &&
                old_hour_count_per_week !== new_hour_count_per_week) ||
            old_leave_policy_id !== new_leave_policy_id ||
            is_alter_timetable
        ) {
            return setOpenConfirmationDialog((state) => ({
                ...state,
                visible: true,
                ...content_message,
            }));
        } else {
            method.handleSubmit(onSubmit)();
        }
    };

    // * MODE UPDATE
    // * MODE UPDATE
    // * MODE UPDATE
    if (modeUpdate) {
        // * Format activity_rate
        const PACKAGE_TYPE = [
            {
                key: 1,
                label: "Heures",
                value: 1,
            },
            {
                key: 2,
                label: "Jours",
                value: 2,
            },
        ];

        const default_package_type = PACKAGE_TYPE?.filter(
            (el) => el?.value === package_type
        );

        const format_activity_rates = ACTIVITY_RATE.map((ar) => {
            return {
                key: ar?.id,
                label: ar?.name,
                value: ar.id,
                title: ar?.name,
                styles: {
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            };
        });

        let default_activity_rate_find = format_activity_rates?.filter(
            (ar) => ar?.id === contract_data?.activity_rate?.id
        );
        default_activity_rate_find = default_activity_rate_find?.length
            ? default_activity_rate_find[0]?.value
            : format_activity_rates[0]?.value;

        const format_leave_policy_data = leave_policy_data?.map((lp) => {
            return {
                id: lp?.id,
                header: lp?.name,
            };
        });

        const default_leave_policy_data = format_leave_policy_data?.find(
            (el) => el?.id === contract_data?.leave_policy?.id
        );

        const format_rtt_policy = RTT_POLICY?.map((rtt) => {
            return {
                id: rtt?.id,
                header: rtt?.name,
            };
        });

        const default_rtt_policy = format_rtt_policy?.find(
            (el) => el?.id === contract_data?.rtt_policy?.id
        );

        const salary_type = SALARY_TYPE.map((el) => {
            return {
                key: el?.id,
                label: el?.name,
                value: el?.id,
                title: el?.name,
                styles: {
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            };
        });

        const salary_period = SALARY_PERIOD.map((el) => {
            return {
                key: el?.id,
                label: el?.name,
                value: el?.id,
                title: el?.name,
                styles: {
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            };
        });

        const [_, ...rest] = salary_period;

        // * Verify the country => if FR or CHF
        const is_fr = contract_data?.contract_country?.toLowerCase() === "fr";
        const is_ch = contract_data?.contract_country?.toLowerCase() === "ch";

        return (
            <Flex fill styles={{ maxWidth: "440px" }} column>
                <Card column>
                    <DialogConfirmationUpdateHoursWorked
                        openConfirmationDialog={openConfirmationDialog.visible}
                        onCancel={() =>
                            setOpenConfirmationDialog((state) => ({
                                ...state,
                                visible: false,
                            }))
                        }
                        content={
                            <Flex column gap="gap.small">
                                <Text
                                    content={
                                        openConfirmationDialog.message_hours_worked
                                    }
                                />
                                <Text
                                    content={
                                        openConfirmationDialog.message_leave_policy
                                    }
                                />
                                <Text
                                    content={
                                        openConfirmationDialog.message_timetable
                                    }
                                />
                            </Flex>
                        }
                        onConfirm={() => {
                            method.handleSubmit(onSubmit)();
                            setOpenConfirmationDialog((state) => ({
                                ...state,
                                visible: false,
                            }));
                            return;
                        }}
                    />
                    <Card.EditTitle
                        content={"Temps de travail"}
                        onClose={() => _setModeUpdate(false)}
                    />
                    <Card.Body
                        content={
                            <FormProvider {...method}>
                                <form onSubmit={method.handleSubmit(onSubmit)}>
                                    <Flex column gap="gap.medium">
                                        <Flex>
                                            <Title content="Temps de travail" />
                                        </Flex>

                                        <div>
                                            <Planning
                                                mode="writing"
                                                timetable={method.watch(
                                                    "timetable"
                                                )}
                                            />
                                        </div>

                                        <Flex column>
                                            <InputLabel content="Activité contractuelle" />
                                            <Controller
                                                control={method.control}
                                                defaultValue={
                                                    default_activity_rate_find
                                                }
                                                render={({
                                                    field: { onChange },
                                                }) => {
                                                    return (
                                                        <RadioGroupStyle
                                                            onCheckedValueChange={(
                                                                _,
                                                                i
                                                            ) => {
                                                                onChange(
                                                                    i?.value
                                                                );
                                                                switch (
                                                                    i?.value
                                                                ) {
                                                                    case 1:
                                                                        // value : 100% - disabled
                                                                        method.setValue(
                                                                            "part_time_percentage",
                                                                            100
                                                                        );
                                                                        method.clearErrors(
                                                                            "part_time_percentage"
                                                                        );
                                                                        break;
                                                                    case 2:
                                                                        // value : 90%
                                                                        method.setValue(
                                                                            "part_time_percentage",
                                                                            90
                                                                        );
                                                                        method.clearErrors(
                                                                            "part_time_percentage"
                                                                        );
                                                                        break;
                                                                    case 3:
                                                                        // value : 30%
                                                                        method.setValue(
                                                                            "part_time_percentage",
                                                                            30
                                                                        );
                                                                        method.clearErrors(
                                                                            "part_time_percentage"
                                                                        );
                                                                        break;
                                                                }
                                                            }}
                                                            items={
                                                                format_activity_rates
                                                            }
                                                            defaultValue={
                                                                default_activity_rate_find
                                                            }
                                                            defaultCheckedValue={
                                                                default_activity_rate_find
                                                            }
                                                        />
                                                    );
                                                }}
                                                name="activity_rate"
                                            />
                                        </Flex>
                                        <Flex>
                                            <Controller
                                                control={method.control}
                                                name="part_time_percentage"
                                                defaultValue={
                                                    contract_data?.part_time_percentage
                                                }
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "Ce champ est obligatoire",
                                                    },
                                                    validate: {
                                                        inInterval: (
                                                            v,
                                                            { activity_rate }
                                                        ) => {
                                                            const value =
                                                                parseFloat(v);
                                                            switch (
                                                                activity_rate
                                                            ) {
                                                                case 1:
                                                                    console.log(
                                                                        {
                                                                            v:
                                                                                value ===
                                                                                100,
                                                                        }
                                                                    );
                                                                    return (
                                                                        value ===
                                                                        100
                                                                    );
                                                                case 2:
                                                                    console.log(
                                                                        {
                                                                            v2:
                                                                                value >=
                                                                                    51 &&
                                                                                value <=
                                                                                    99,
                                                                        }
                                                                    );
                                                                    return (
                                                                        value >=
                                                                            51 &&
                                                                        value <=
                                                                            99
                                                                    );
                                                                case 3:
                                                                    console.log(
                                                                        {
                                                                            v3:
                                                                                value >=
                                                                                    1 &&
                                                                                value <=
                                                                                    50,
                                                                        }
                                                                    );
                                                                    return (
                                                                        value >=
                                                                            1 &&
                                                                        value <=
                                                                            50
                                                                    );
                                                            }
                                                        },
                                                    },
                                                }}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                        ref,
                                                    },
                                                }) => (
                                                    <InputSuffix
                                                        type="number"
                                                        icon={{
                                                            content: "%",
                                                        }}
                                                        onChange={(e, i) => {
                                                            return onChange(
                                                                i?.value
                                                            );
                                                        }}
                                                        value={value}
                                                        label={
                                                            "Taux d'activité"
                                                        }
                                                        defaultValue={
                                                            contract_data?.part_time_percentage
                                                        }
                                                        disabled={
                                                            value == 100 &&
                                                            method.getValues(
                                                                "activity_rate"
                                                            ) === 1
                                                        }
                                                        error={
                                                            method.errors
                                                                ?.part_time_percentage
                                                                ?.message ||
                                                            method.errors
                                                                ?.part_time_percentage
                                                                ?.type ===
                                                                "inInterval"
                                                        }
                                                    />
                                                )}
                                            />
                                        </Flex>
                                        {is_fr && (
                                            <>
                                                <Flex column>
                                                    <InputLabel content="Forfait" />
                                                    <Controller
                                                        control={method.control}
                                                        name="package_type"
                                                        defaultValue={
                                                            default_package_type
                                                        }
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                                onBlur,
                                                                ref,
                                                            },
                                                        }) => (
                                                            <RadioGroupStyle
                                                                onCheckedValueChange={(
                                                                    _,
                                                                    i
                                                                ) =>
                                                                    onChange(
                                                                        i?.value
                                                                    )
                                                                }
                                                                value={value}
                                                                items={
                                                                    PACKAGE_TYPE
                                                                }
                                                                defaultCheckedValue={
                                                                    contract_data
                                                                        ?.package_type
                                                                        ?.id ??
                                                                    1
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Flex>
                                                {package_type === 1 ? ( // heures
                                                    <>
                                                        <Flex column>
                                                            <Controller
                                                                control={
                                                                    method.control
                                                                }
                                                                name="hour_count_per_week"
                                                                rules={{
                                                                    required: {
                                                                        value: true,
                                                                        message:
                                                                            "Ce champ est obligatoire",
                                                                    },
                                                                }}
                                                                defaultValue={
                                                                    contract_data?.hour_count_per_week
                                                                }
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                    },
                                                                }) => {
                                                                    return (
                                                                        <Input
                                                                            defaultValue={
                                                                                contract_data?.hour_count_per_week
                                                                            }
                                                                            onChange={(
                                                                                _,
                                                                                i
                                                                            ) =>
                                                                                onChange(
                                                                                    i?.value
                                                                                )
                                                                            }
                                                                            type="number"
                                                                            label="Heures travaillées par semaine"
                                                                            error={
                                                                                method
                                                                                    .errors
                                                                                    ?.hour_count_per_week
                                                                                    ?.message
                                                                            }
                                                                            fluid
                                                                            onWheel={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                e.target.blur();
                                                                                setTimeout(
                                                                                    () => {
                                                                                        e.target.focus();
                                                                                    },
                                                                                    0
                                                                                );
                                                                                return false;
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                content={
                                                                    method
                                                                        .errors
                                                                        ?.hour_count_per_week
                                                                        ?.message
                                                                }
                                                            />
                                                        </Flex>
                                                        {/* <Flex column>
                                                            <InputLabel
                                                                content={
                                                                    "Politique RTT"
                                                                }
                                                            />
                                                            <Controller
                                                                name="rtt_policy"
                                                                control={control}
                                                                defaultValue={
                                                                    default_rtt_policy
                                                                }
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                    },
                                                                }) => {
                                                                    return (
                                                                        <Dropdown
                                                                            items={
                                                                                format_rtt_policy
                                                                            }
                                                                            defaultValue={
                                                                                default_rtt_policy
                                                                            }
                                                                            onChange={(
                                                                                e,
                                                                                i
                                                                            ) =>
                                                                                onChange(
                                                                                    i?.value
                                                                                )
                                                                            }
                                                                            placeholder="Choisir la politique RTT"
                                                                            fluid
                                                                            checkable
                                                                            getA11ySelectionMessage={{
                                                                                onAdd: (
                                                                                    item
                                                                                ) =>
                                                                                    `${item} has been selected.`,
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </Flex> */}
                                                    </>
                                                ) : (
                                                    // jours
                                                    <>
                                                        <Flex>
                                                            <Input
                                                                {...method.register(
                                                                    "day_count_per_year",
                                                                    {
                                                                        required:
                                                                            {
                                                                                value: true,
                                                                                message:
                                                                                    "Ce champ est obligatoire",
                                                                            },
                                                                        setValueAs:
                                                                            (
                                                                                v
                                                                            ) =>
                                                                                parseFloat(
                                                                                    v
                                                                                ),
                                                                    }
                                                                )}
                                                                error={
                                                                    method
                                                                        .errors
                                                                        ?.day_count_per_year
                                                                        ?.message
                                                                }
                                                                defaultValue={
                                                                    contract_data?.day_count_per_year
                                                                }
                                                                type="number"
                                                                label="Nombre de jours travaillés par an"
                                                                fluid
                                                            />
                                                        </Flex>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {is_ch && (
                                            <>
                                                <Flex>
                                                    <Controller
                                                        control={method.control}
                                                        name="hour_count_per_week"
                                                        defaultValue={
                                                            contract_data?.hour_count_per_week
                                                        }
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message:
                                                                    "Ce champ est obligatoire",
                                                            },
                                                            setValueAs: (v) =>
                                                                parseFloat(v),
                                                        }}
                                                        render={({
                                                            field: { onChange },
                                                        }) => {
                                                            return (
                                                                <Input
                                                                    defaultValue={
                                                                        contract_data?.hour_count_per_week
                                                                    }
                                                                    onChange={(
                                                                        _,
                                                                        i
                                                                    ) =>
                                                                        onChange(
                                                                            i?.value
                                                                        )
                                                                    }
                                                                    error={
                                                                        method
                                                                            .errors
                                                                            ?.hour_count_per_week
                                                                            ?.message
                                                                    }
                                                                    type="number"
                                                                    label="Heures travaillées par semaine"
                                                                    fluid
                                                                    onWheel={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        e.target.blur();
                                                                        setTimeout(
                                                                            () => {
                                                                                e.target.focus();
                                                                            },
                                                                            0
                                                                        );
                                                                        return false;
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Flex>
                                            </>
                                        )}

                                        <Flex column>
                                            <InputLabel
                                                content={"Politique d'absence"}
                                            />
                                            <Controller
                                                name="leave_policy"
                                                control={method.control}
                                                defaultValue={
                                                    default_leave_policy_data
                                                }
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "ce champs est boligatoire",
                                                    },
                                                }}
                                                render={({
                                                    field: { onChange },
                                                }) => {
                                                    return (
                                                        <Dropdown
                                                            items={
                                                                format_leave_policy_data
                                                            }
                                                            defaultValue={
                                                                default_leave_policy_data
                                                            }
                                                            onChange={(_, i) =>
                                                                onChange(
                                                                    i?.value
                                                                )
                                                            }
                                                            error={
                                                                method.errors
                                                                    ?.leave_policy
                                                                    ?.message
                                                            }
                                                            fluid
                                                            placeholder="Choisir la politique d'absence"
                                                            checkable
                                                            getA11ySelectionMessage={{
                                                                onAdd: (item) =>
                                                                    `${item} has been selected.`,
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Flex>
                                        {/* salaire */}
                                        {/* <Title content="Salaire" />
                                        <Flex column>
                                            <InputLabel content="Valeur du salaire fixe" />
                                            <Controller
                                                name="salary_type"
                                                control={control}
                                                defaultValue={
                                                    contract_data?.salary
                                                        ?.salary_type
                                                }
                                                render={({
                                                    field: { onChange },
                                                }) => {
                                                    return (
                                                        <RadioGroup
                                                            defaultCheckedValue={
                                                                contract_data
                                                                    ?.salary
                                                                    ?.salary_type
                                                            }
                                                            onCheckedValueChange={(
                                                                e,
                                                                i
                                                            ) => onChange(i?.value)}
                                                            items={salary_type}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Flex>
                                        <Flex>
                                            <InputSuffix
                                                {...register("salary", {
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "Ce champ est obligatoire",
                                                    },
                                                    setValueAs: (v) =>
                                                        parseFloat(v),
                                                })}
                                                error={errors?.salary?.message}
                                                defaultValue={
                                                    contract_data?.salary?.amount
                                                }
                                                type="number"
                                                icon={{
                                                    content: get_symbol_currency(
                                                        contract_data?.salary
                                                            ?.currency
                                                    ),
                                                }}
                                                label="Salaire fixe"
                                            />
                                        </Flex>
                                        <Flex column>
                                            <InputLabel content="Temporalité du salaire" />
                                            <Controller
                                                control={control}
                                                name="salary_period"
                                                defaultValue={
                                                    contract_data?.salary
                                                        ?.salary_period
                                                }
                                                render={({
                                                    field: { onChange },
                                                }) => {
                                                    return (
                                                        <RadioGroupStyle
                                                            defaultCheckedValue={
                                                                contract_data
                                                                    ?.salary
                                                                    ?.salary_period
                                                            }
                                                            onCheckedValueChange={(
                                                                e,
                                                                i
                                                            ) => onChange(i?.value)}
                                                            items={
                                                                package_type === 1
                                                                    ? salary_period
                                                                    : rest
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Flex> */}
                                    </Flex>
                                    <Flex
                                        hAlign="center"
                                        gap="gap.medium"
                                        styles={{ marginTop: "24px" }}
                                    >
                                        <Button
                                            content={"Annuler"}
                                            onClick={() =>
                                                _setModeUpdate(false)
                                            }
                                            flat
                                        />
                                        <Button
                                            content={
                                                "Enregistrer les modifications"
                                            }
                                            onClick={_handleSubmit}
                                            loading={is_loading_mutate}
                                            primary
                                            flat
                                        />
                                    </Flex>
                                </form>
                            </FormProvider>
                        }
                    />
                </Card>
            </Flex>
        );
    }

    // * MODE READ
    // * MODE READ
    // * MODE READ
    return (
        <Flex fill styles={{ maxWidth: "440px" }} column>
            <Card column>
                {/* <Card.Illustration image={Img} canEdit={isHR} /> */}
                <Card.Title
                    canEdit={isHR}
                    content={"Temps de travail"}
                    onEdit={() => {
                        if (!isHR) {
                            return;
                        }
                        _setModeUpdate(true);
                        return;
                    }}
                />
                {/* temps de travail & salaire */}
                <Card.Body
                    content={
                        <TimeJobAndSalaryContent
                            activity_rate={contract_data?.activity_rate}
                            part_time_percentage={
                                contract_data?.part_time_percentage
                            }
                            package_type={contract_data?.package_type}
                            hour_count_per_week={
                                contract_data?.hour_count_per_week
                            }
                            day_count_per_year={
                                contract_data?.day_count_per_year
                            }
                            rtt_policy={contract_data?.rtt_policy}
                            leave_policy={contract_data?.leave_policy}
                            salary={contract_data?.salary}
                            contract_country={contract_data?.contract_country}
                            timetable={contract_data?.timetable}
                        />
                    }
                />
            </Card>
        </Flex>
    );
};

export default TimeJobAndSalary;

const InputSuffix = styled(Input)`
    .ui-input__icon {
        right: 0;
        padding-inline: 8px;
        background-color: #c7c7c7;
        border-radius: 0px 4px 4px 0px;
    }
`;

const RadioGroupStyle = styled(RadioGroup)`
    .ui-radiogroup__item__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
