import React, { useEffect, useState } from "react";
import CustomBreadCrumb from "../../components/breadcrumb";
// import Step from "./components/Step";

import { Button, Flex } from "@fluentui/react-northstar";
import { ADD_EMPLOYEE_STEPS, I18N_NS_ADD_EMPLOYEE } from "./constants";
import { useTranslation } from "react-i18next";

import {
    Outlet,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { f_resetStepperContext } from "./stores";
import { createUser, modifyCollaborator } from "../../api/user";
import { useMutation, useQueryClient } from "react-query";
import {
    _api_assign_ressource,
    _api_save_settings,
} from "../../api/onBoarding/user/stepsAddCollaborator";
import { _api_patch_assign_task, _api_save_steps } from "./api";

import { BREADCRUMB_HUMBERGER_GLOBAL } from "../../constants/routes/drawer";
import { Container, ContainerFluid } from "../../common/styles";
import Toast from "../../components/Alerts";
import Step from "./components/Step";
import { ROUTES } from "../../constants/routes";
import withAuth from "../../HOC/withAuth";

const AddEmployees = () => {
    const [configToast, setConfigToast] = useState({
        visible: false,
        content: "",
        level: "danger",
    });

    const [params] = useSearchParams();
    const IS_UPDATING_USER_ONBOARDING =
        params.get("t") === "update_onboarding" && params.get("id") !== null;

    const refer_onboarding = params.get("refer") === "onboarding";

    const navigate = useNavigate();
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const { pathname } = useLocation();

    const current_step =
        ADD_EMPLOYEE_STEPS.filter((el) => el.pathname === pathname)[0] ||
        ADD_EMPLOYEE_STEPS[0];

    // sert a supprimer le localStorage
    useEffect(() => {
        return () => {
            return f_resetStepperContext();
        };
    }, []);

    /**
     * QUERIES
     */
    const { mutate: update_new_employee, isLoading: isLoadingPatch } =
        useMutation(
            "validate_step_one",
            (data) => {
                return modifyCollaborator(data);
            },
            {
                onSuccess: (data) => {},
            }
        );
    /**
     * query add new employee
     */
    const { mutate: add_new_employee, isLoading } = useMutation(
        "validate_step_one",
        (data) => {
            return createUser(data);
        },
        {
            onSuccess: (data) => {},
        }
    );

    /**
     * query save the Role
     */
    const { mutate: save_role, isLoading: isLoadingRole } = useMutation(
        "validate_step_role",
        (data) => {
            return _api_save_settings(data);
        },
        {
            onSuccess: (data) => {
                if (data?.success) {
                    return go_to_step(current_step);
                }
            },
        }
    );
    /**
     * query save Resources.
     */
    const { mutate: save_resources, isLoading: isLoadingResources } =
        useMutation(
            "validate_step_resources",
            (data) => {
                return _api_assign_ressource(data);
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        return go_to_step(current_step);
                    }
                },
            }
        );
    /**
     * query save the tasks
     */
    const { mutate: save_tasks, isLoading: isLoadingTasks } = useMutation(
        "validate_step_tasks",
        (data) => {
            return _api_patch_assign_task(data);
        },
        {
            onSuccess: (data) => {
                if (data?.success) {
                    create_employee({
                        id_user: data?.user?.id,
                        email: data?.user?.email,
                    });
                } else {
                    setConfigToast((state) => {
                        return {
                            ...state,
                            visible: true,
                            level: "danger",
                            content:
                                "une erreur est survenue lors de la creation !",
                        };
                    });
                }
            },
        }
    );
    /**
     * query save the tasks
     */
    const { mutate: create_employee, isLoading: isLoadingAddEmployee } =
        useMutation(
            "validate_creation_employee",
            (data) => {
                return _api_save_steps(data);
            },
            {
                onSuccess: (data) => {},
            }
        );

    // use this function for just navigation between steps
    const go_to_step = (current_step) => {
        // incremeneter d'une etape
        const next_step_id = current_step.id + 1;
        // search a new step
        const next_step = ADD_EMPLOYEE_STEPS.filter(
            (el) => el.id === next_step_id
        )[0];
        return navigate(next_step.pathname);
    };

    /** handle change steps */
    const _goNext = async (current_step) => {};

    const _goPrevious = (current_step) => {
        // therefore the user want to Cancel the creation of the employee
        if (current_step?.id === 1) {
            // todo 1. navigate to the dashboard.
            // todo 2. remove the data Stepper
            f_resetStepperContext();
            return navigate("/dashboard");
        }

        // decrementer d'une etape
        const previous_step_id = current_step.id - 1;
        // search a new step
        const previous_step = ADD_EMPLOYEE_STEPS.filter(
            (el) => el.id === previous_step_id
        )[0];
        return navigate(previous_step.pathname);
    };

    // change the value of the button, breadcrumb actions.
    const showTextDependingCurrentStep = (current_step) => {
        if (current_step?.id === 4) {
            return t("pages.add_employee.last_step");
        }
        return t("pages.add_employee.next_step");
    };

    const showTextBackDependingCurrentStep = (current_step) => {
        const text = [
            t("common.cancel"),
            t("common.back"),
            t("common.back"),
            t("common.back"),
        ];
        return text[current_step?.id - 1];
    };

    let datas = ADD_EMPLOYEE_STEPS;
    if (IS_UPDATING_USER_ONBOARDING) {
        datas = ADD_EMPLOYEE_STEPS.slice(0, ADD_EMPLOYEE_STEPS.length - 1);
    }

    return (
        <Flex fill column styles={{ height: "100vh" }}>
            <Toast
                onStatusChange={() =>
                    setConfigToast({
                        visible: false,
                        content: "",
                        level: "danger",
                    })
                }
                level={configToast.level}
                content={configToast.content}
                visible={configToast.visible}
            />
            {/* breadCrumb */}
            <CustomBreadCrumb
                humberger={BREADCRUMB_HUMBERGER_GLOBAL}
                navList={[
                    {
                        label: refer_onboarding
                            ? "Onboarding"
                            : t("navigation.home"),
                        href: refer_onboarding ? ROUTES.onboarding.home : "/",
                    },
                    {
                        label: t("navigation.add_employee"),
                        href: "/employee/add/first",
                    },
                ]}
            />
            {/* left and right side */}
            <Flex
                fill
                styles={{
                    background:
                        "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 81%)",
                }}
            >
                <ContainerFluid>
                    <Container>
                        {/* left side */}
                        <Flex.Item
                            styles={{
                                backgroundColor: "white",
                                maxWidth: "450px",
                                width: "100%",
                            }}
                        >
                            <Flex
                                column
                                styles={{ justifyContent: "space-evenly" }}
                            >
                                <Flex column gap="gap.large">
                                    {datas.map((el, index) => {
                                        return (
                                            <Step
                                                label={el.label}
                                                key={index}
                                                id={index + 1}
                                                active={
                                                    el.id === current_step?.id
                                                        ? "true"
                                                        : "false"
                                                }
                                                already={
                                                    el.id < current_step?.id
                                                        ? "true"
                                                        : "false"
                                                }
                                                required={el.required}
                                                onClick={(e) => {
                                                    if (
                                                        current_step?.id > el.id
                                                    ) {
                                                        const paramsString =
                                                            Array.from(
                                                                params.entries()
                                                            )
                                                                .map(
                                                                    ([
                                                                        key,
                                                                        value,
                                                                    ]) =>
                                                                        `${key}=${value}`
                                                                )
                                                                .join("&");
                                                        navigate(
                                                            IS_UPDATING_USER_ONBOARDING
                                                                ? el?.pathname +
                                                                      "?" +
                                                                      paramsString
                                                                : el?.pathname
                                                        );
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </Flex>
                                <Flex hAlign="center">
                                    {current_step.image}
                                </Flex>
                            </Flex>
                        </Flex.Item>
                        {/* right side */}
                        <Flex
                            styles={{
                                backgroundColor: "#F0F0F0",
                                flex: 3,
                                padding: "40px 80px",
                                height: "fit-content",
                                minHeight: "100%",
                            }}
                            gap="gap.small"
                            column
                        >
                            <Outlet />
                        </Flex>
                    </Container>
                </ContainerFluid>
            </Flex>
        </Flex>
    );
};

export default withAuth(AddEmployees);
