import { ArrowRightIcon, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { ReactComponent as FoodIcon } from "../../../../assets/icons/time_reporting/food.svg";
import moment from "moment";

const Slot = ({ slot }) => {
    switch (slot.status.value) {
        // * c'est un jour de travail.
        case 1: {
            const start_hour_format = moment(
                slot.start_hour,
                "HH:mm:ss"
            ).format("HH:mm");
            const end_hour_format = moment(slot.end_hour, "HH:mm:ss").format(
                "HH:mm"
            );
            return (
                <Container hAlign="center" vAlign="center">
                    <Time content={start_hour_format} />
                    <ArrowRightIcon size="smallest" />
                    <Time content={end_hour_format} />
                </Container>
            );
        }
        // * c'est une pause
        case -1: {
            const break_duration_str = slot.duration_str;
            return (
                <ContainerPause hAlign="center" vAlign="center">
                    <FoodIcon />
                    <Time content={break_duration_str} />
                </ContainerPause>
            );
        }
        default: {
            return (
                <ContainerNotWorking hAlign="center" vAlign="center">
                    <TextNotWroking content={"Non-travaillé"} />
                </ContainerNotWorking>
            );
        }
    }

    // non-defini
    // return (
    //     <ContainerUndefined>
    //         <TimeUndefined content="Non défini" />
    //     </ContainerUndefined>
    // );
};

export default Slot;

const ContainerUndefined = styled(Flex)`
    display: flex;
    padding: var(--spacing-ixxs-4, 2px) var(--spacing-ixxs-4, 11.5px)
        var(--spacing-ixxs-4, 2px) var(--spacing-ixxs-4, 12.5px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xxxs-2, 0px);
    align-self: stretch;

    border-radius: var(--radius-m-4, 4px);
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);
`;
// pause
const ContainerPause = styled(Flex)`
    display: flex;
    width: 88px;
    padding: var(--spacing-ixxs-4, 4px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs-8, 8px);

    border-radius: var(--radius-m-4, 4px);
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);
`;

// non travaillé
const ContainerNotWorking = styled(Flex)`
    display: flex;
    width: 88px;
    padding: var(--spacing-ixxs-4, 4px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs-8, 8px);

    border-radius: var(--radius-m-4, 4px);
    background: repeating-linear-gradient(
        45deg,
        #d1d1d1,
        #d1d1d1 2px,
        transparent 3px,
        transparent 8px
    );
`;

const TextNotWroking = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */

    border-radius: var(--radius-xs-2, 2px);
    background: var(
        --Light-theme-Disabled-Background-Default-Background-Disabled,
        #f0f0f0
    );
    padding: var(--spacing-xxxs-2, 2px) var(--spacing-ixxs-4, 4px);
`;

// slots time
const Time = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const TimeUndefined = styled(Time)`
    border-radius: var(--radius-xs-2, 2px);
    background: var(
        --Light-theme-Disabled-Background-Default-Background-Disabled,
        #f0f0f0
    );
    display: flex;
    padding: var(--spacing-xxxs-2, 2px) var(--spacing-ixxs-4, 4px);
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

const Container = styled(Flex)`
    width: 88px;
    height: var(--spacing-l-24, 24px);
    padding: var(--spacing-ixxs-4, 4px);
    gap: var(--spacing-xxxs-2, 2px);
    flex-shrink: 0;

    border-radius: var(--radius-m-4, 4px);
    background: var(--Light-theme-Rest-Background-Yellow-Background-1, #fbf6d9);
`;
