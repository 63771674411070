import React from "react";
import Slot from "../Slot";
import Break from "../Break";

const ShowSlotsTimeClock = ({ useTimerClockSlots = [] }) => {
    const data = useTimerClockSlots.at(0).at(1);
    const shifts = data?.shifts;

    if (!shifts) return null;

    // show slots
    return shifts.map((shift, index) => {
        const isLastItem = index === shifts.length - 1;
        const isLastItemBreak = shift.type?.value === -1 && isLastItem;
        // c'est une pause
        if (shift.type?.value === -1 && !isLastItemBreak) {
            return (
                <Break duration={shift.duration_str} isSlotsTimeClock={true} />
            );
        }
        // slots
        return (
            <Slot
                status={
                    data.is_paused || isLastItemBreak
                        ? "clocktime_paused"
                        : "clocktime_start"
                }
                start_time={shift.start_time}
                end_time={shift.end_time}
                duration={shift.duration_str ?? "--h--"}
                isSlotsTimeClock={true}
                isPaused={data.is_paused}
                isOnsite={shift.is_on_site}
                isLastItemBreak={isLastItemBreak}
            />
        );
    });
};

export default ShowSlotsTimeClock;
