import { AcceptIcon, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const TimetableTemplates = ({ timetable_templates = [], managePlan }) => {
    // Event choose planning.
    const handleClickEventPlanning = (e, plan_id) => {
        e.preventDefault();
        e.stopPropagation();
        managePlan.setSelectedPlan(plan_id);
        return;
    };

    return (
        <Flex column>
            {timetable_templates.map((plan) => {
                const plan_id = plan.id;
                const is_selected = managePlan.selectedPlan === plan_id;
                return (
                    <Container
                        space="between"
                        vAlign="center"
                        key={plan_id}
                        onClick={(e) => handleClickEventPlanning(e, plan_id)}
                        $selected={is_selected}
                    >
                        <Flex column>
                            <Title
                                content={plan.name === "" ? "-" : plan.name}
                            />
                            <Description
                                content={
                                    plan.description === ""
                                        ? "-"
                                        : plan.description
                                }
                            />
                        </Flex>
                        {is_selected && (
                            <AcceptIcon
                                styles={{ color: "var(--color-primary)" }}
                            />
                        )}
                    </Container>
                );
            })}
        </Flex>
    );
};

export default TimetableTemplates;

const Description = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const Title = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const Container = styled(Flex)`
    --hover-bg-color: #f5f5f5;
    display: flex;
    padding: 8px 10px;

    background: ${({ $selected }) =>
        $selected ? "var(--hover-bg-color)" : "white"};
    :hover {
        background: var(
            --Light-theme-Hover-Background-Default-Background-Hover,
            var(--hover-bg-color)
        );
        cursor: pointer;
    }
`;
