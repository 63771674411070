import React from "react";
import { ArrowRightIcon, Flex, Text } from "@fluentui/react-northstar";
import {
    WeekText,
    WeekFlex,
    DayText,
    WrapperWeeksValues,
    TextWeek,
} from "./index.style";
import styled from "styled-components";
import moment from "moment";
import { ROUTES } from "../../../../constants/routes";
import { CustomBadge } from "../../../../components/Badge";
import { CommentRegular } from "@fluentui/react-icons";
import { useSearchParams } from "react-router-dom";

const GetNbrCommentDependTabs = ({ tab, day }) => {
    switch (tab) {
        case 0:
            return day?.comments_count?.to_approve;
        case 1:
            return day?.comments_count?.incomplete;
        case 2:
            return day?.comments_count?.closed;

        default:
            return day?.comments_count?.to_approve;
    }
};

const WeekTableHeader = ({
    day,
    currentMonth,
    index,
    highlightedColumn,
    isHR = false,
    $noStyle = false,
    setHighlightedColumn = () => null,
}) => {
    const week_start = moment(day?.monday).format("DD/MM");
    const week_end = moment(day?.sunday).format("DD/MM");

    const [searchParams] = useSearchParams();

    const redirectWithParams = () => {
        // Récupérer les paramètres actuels sous forme de chaîne de requête
        const newSearchParams = new URLSearchParams(searchParams);
        const paramsString = newSearchParams.toString();
        // Rediriger vers la nouvelle route en ajoutant les paramètres existants
        return paramsString ? "&" + paramsString : "";
    };

    const paramsString = redirectWithParams();

    return (
        <WeekFlex
            hAlign="center"
            vAlign="center"
            onMouseOver={() => setHighlightedColumn(index)}
            onMouseLeave={() => setHighlightedColumn(null)}
            $highlight={highlightedColumn === index}
            $noStyle={$noStyle}
        >
            {/* week view */}
            {day?.date && (
                <DayText
                    content={`${moment(day?.date).format("ddd DD MMMM")}`}
                />
            )}
            {/* month view */}
            {day?.monday && (
                <WeekText
                    to={`${
                        isHR
                            ? ROUTES.time_reporting.week_rh
                            : ROUTES.time_reporting.week
                    }?week=${day?.week_number}&year=${day?.week_object?.at(
                        0
                    )}&month=${currentMonth?.index + 1}${paramsString}`}
                >
                    <Flex
                        space="between"
                        vAlign="center"
                        styles={{
                            height: "fit-content",
                        }}
                    >
                        <CustomBadge
                            color="white"
                            size="medium"
                            bg="var(--color-primary)"
                            label={`S. ${day?.week_number}`}
                            rectangular
                            styles={{ margin: 0 }}
                        />
                    </Flex>
                    <WrapperWeeksValues vAlign="center" space="between">
                        <TextWeek content={week_start} />
                        <ArrowRightIcon
                            size="small"
                            styles={{ color: "#616161" }}
                        />
                        <TextWeek content={week_end} />
                    </WrapperWeeksValues>
                </WeekText>
            )}
        </WeekFlex>
    );
};

export default WeekTableHeader;
