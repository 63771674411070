import { create } from "zustand";

export const ACTIONS_TIMETABLE = {
    init_reducer: "init_reducer",
    set_timetables: "set_timetables",
    update_slot_day: "update_slot_day",
    toggle_time_reporting_activated: "toggle_time_reporting_activated",
    toggle_geolocation_activated: "toggle_geolocation_activated",
    toggle_auto_correct_hours_activated: "toggle_auto_correct_hours_activated",
    toggle_break_time_alert_activated: "toggle_break_time_alert_activated",
    toggle_badge_activated: "toggle_badge_activated",
    update_break_time_duration_minutes: "update_break_time_duration_minutes",
    toggle_auto_correct_hours_before_minutes:
        "toggle_auto_correct_hours_before_minutes",
    toggle_auto_correct_hours_after_minutes:
        "toggle_auto_correct_hours_after_minutes",
};

export const INIT_REDUCER_ARGS = {};

export const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS_TIMETABLE.init_reducer: {
            const payload = action.payload;
            return payload;
        }
        case ACTIONS_TIMETABLE.set_timetables: {
            const payload = action.payload;
            return {
                ...state,
                timetable: payload,
            };
        }
        case ACTIONS_TIMETABLE.toggle_geolocation_activated: {
            const checked = action.payload;
            return {
                ...state,
                geolocation_activated: checked,
            };
        }
        case ACTIONS_TIMETABLE.toggle_time_reporting_activated: {
            const checked = action.payload;
            return {
                ...state,
                time_reporting_activated: checked,
            };
        }
        case ACTIONS_TIMETABLE.toggle_auto_correct_hours_activated: {
            const checked = action.payload;
            return {
                ...state,
                auto_correct_hours_activated: checked,
            };
        }
        case ACTIONS_TIMETABLE.toggle_break_time_alert_activated: {
            const checked = action.payload;
            return {
                ...state,
                break_time_alert_activated: checked,
            };
        }
        case ACTIONS_TIMETABLE.toggle_badge_activated: {
            return {
                ...state,
                badge_activated: !state.badge_activated,
            };
        }
        case ACTIONS_TIMETABLE.update_break_time_duration_minutes: {
            return {
                ...state,
                break_time_duration_minutes: action.payload,
            };
        }
        case ACTIONS_TIMETABLE.toggle_auto_correct_hours_before_minutes: {
            return {
                ...state,
                auto_correct_hours_before_minutes: action.payload,
            };
        }
        case ACTIONS_TIMETABLE.toggle_auto_correct_hours_after_minutes: {
            return {
                ...state,
                auto_correct_hours_after_minutes: action.payload,
            };
        }
        case ACTIONS_TIMETABLE.update_slot_day: {
            const day_index = action.payload.day_index;
            const new_slots = action.payload.slots;
            return {
                ...state,
                timetable: state.timetable.map((tt) => {
                    if (tt.day_idx === day_index) {
                        return {
                            ...tt,
                            shifts: new_slots,
                        };
                    }
                    return tt;
                }),
            };
        }

        default: {
            return state;
        }
    }
};

// Créer le store Zustand avec un état initial et un dispatch
export const useStoreTimetable = create((set) => ({
    state: INIT_REDUCER_ARGS,
    dispatch: (action) =>
        set((currentState) => ({
            state: reducer(currentState.state, action),
        })),
}));
