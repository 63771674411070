import {
    Alert,
    ChevronDownIcon,
    Divider,
    ExclamationTriangleIcon,
    Flex,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import React, { useReducer, useState } from "react";
import {
    CardDetailWeek,
    ContractualHours,
    Dates,
    DeclaredMonth,
    DetailMonthFlex,
    Graph,
    GraphLabelFlex,
    Hours,
    Label,
    Left,
    Right,
    SpecialDayFlex,
    Title,
    TotalHours,
    WrapperCards,
} from "../DialogSubmitMonth/index.style";
import { useTranslation } from "react-i18next";
import {
    BAR_GRAPH_MONTH_SUBMIT,
    I18N_NS_TIME_REPORTING,
} from "../../constants";
import moment from "moment";
import BadgeWeek from "../BadgeWeek";
import HourCounter from "../MyTeam/HourBadge/HourCounter";
import { CustomBadge } from "../../../../components/Badge";
import { useMediaQuery } from "react-responsive";
import { initialState } from "../../reducers/reducer.collapse_month_detail";

const ContentDetailMonth = ({
    special_days = [],
    weeks = [],
    declared_hours_count = 0,
    expected_hours_count = 0,
    difference = 0,
    isExistsPendingLeaveRequest = false,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const total_special_days = special_days?.reduce((prev, curr) => {
        return prev + curr.value;
    }, 0);
    const percent = (declared_hours_count * 100) / expected_hours_count;
    const height = (percent * BAR_GRAPH_MONTH_SUBMIT) / 100;

    const reducer = (state, action) => {
        switch (action.type) {
            case "TOGGLE_COLLAPSE":
                return {
                    collapsByWeek:
                        action.button === "collapsByWeek"
                            ? !state.collapsByWeek
                            : false,
                    collapsGraph:
                        action.button === "collapsGraph"
                            ? !state.collapsGraph
                            : false,
                    collapsSpecialDays:
                        action.button === "collapsSpecialDays"
                            ? !state.collapsSpecialDays
                            : false,
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleToggleCollapse = (button) => {
        dispatch({ type: "TOGGLE_COLLAPSE", button });
    };

    return (
        <Flex column gap="gap.large">
            <Flex gap="gap.large" fill>
                {isExistsPendingLeaveRequest && (
                    <Alert
                        content="Des absences non-validées sont présentes sur votre feuille de temps"
                        icon={<ExclamationTriangleIcon />}
                        visible
                        warning
                        fluid
                        styles={{
                            width: "100%",
                        }}
                    />
                )}
            </Flex>
            <DetailMonthFlex

            // gap="gap.large"
            >
                {/* par semaine  */}
                <Left column gap="gap.medium">
                    <Flex vAlign="center" gap="gap.small">
                        <Title
                            content={t(
                                "v2.home.dialogs.contents.section_titles.by_week"
                            )}
                        />
                        {isMobile && (
                            <ChevronDownIcon
                                rotate={state.collapsByWeek ? 180 : 0}
                                onClick={() =>
                                    handleToggleCollapse("collapsByWeek")
                                }
                            />
                        )}
                    </Flex>
                    {(state.collapsByWeek || !isMobile) && (
                        <WrapperCards fill>
                            {weeks?.map((week) => {
                                const week_number = week.week_number;
                                const difference = week.difference;
                                const declared_hours_count =
                                    week.declared_hours_count;
                                const monday = moment(week.monday).format(
                                    "DD/MM"
                                );
                                const sunday = moment(week.sunday).format(
                                    "DD/MM"
                                );
                                return (
                                    <CardDetailWeek column>
                                        <Flex space="between" fill>
                                            <BadgeWeek
                                                status={"default"}
                                                week={week_number}
                                            />
                                            <HourCounter
                                                addionalHourWorkedBadge={
                                                    difference
                                                }
                                                prefix=" h"
                                            />
                                        </Flex>
                                        <Flex space="between" fill>
                                            <Dates
                                                content={`${monday} - ${sunday}`}
                                            />
                                            <TotalHours
                                                content={`${declared_hours_count} h`}
                                            />
                                        </Flex>
                                    </CardDetailWeek>
                                );
                            })}
                        </WrapperCards>
                    )}
                </Left>
                <Divider vertical />
                {/* sur le mois  */}
                <Right column gap="gap.large">
                    <Flex vAlign="center" gap="gap.small">
                        <Title
                            content={t(
                                "v2.home.dialogs.contents.section_titles.per_month"
                            )}
                        />
                        {isMobile && (
                            <ChevronDownIcon
                                rotate={state.collapsGraph ? 180 : 0}
                                onClick={() =>
                                    handleToggleCollapse("collapsGraph")
                                }
                            />
                        )}
                    </Flex>
                    {(state.collapsGraph || !isMobile) && (
                        <Flex column gap="gap.medium">
                            {/* graphs */}
                            <Flex gap="gap.medium" vAlign="end">
                                <Graph primary progress={height} />
                                <Graph progress={BAR_GRAPH_MONTH_SUBMIT} />
                            </Flex>
                            <GraphLabelFlex vAlign="center">
                                <DeclaredMonth column>
                                    <Hours
                                        content={`${declared_hours_count} h`}
                                    />
                                    <Label
                                        content={t(
                                            "v2.home.labels.declared_hours"
                                        )}
                                    />
                                </DeclaredMonth>
                                <ContractualHours column>
                                    <Hours
                                        content={`${expected_hours_count} h`}
                                    />
                                    <Label
                                        content={t(
                                            "v2.home.labels.hours_contractuals"
                                        )}
                                    />
                                </ContractualHours>
                            </GraphLabelFlex>
                            <Flex gap="gap.smaller">
                                <Text content="Récupération" />
                                <HourCounter
                                    addionalHourWorkedBadge={difference}
                                    prefix=" h"
                                />
                            </Flex>
                        </Flex>
                    )}
                </Right>
            </DetailMonthFlex>
            {/* Jour spéciaux  */}
            <Flex column gap="gap.small">
                <Flex vAlign="center" gap="gap.small">
                    <Text content="Jours spéciaux" />
                    {isMobile && (
                        <ChevronDownIcon
                            rotate={state.collapsSpecialDays ? 180 : 0}
                            onClick={() =>
                                handleToggleCollapse("collapsSpecialDays")
                            }
                        />
                    )}
                </Flex>
                {(state.collapsSpecialDays || !isMobile) && (
                    <SpecialDayFlex>
                        {special_days?.map((day) => {
                            if (day.is_homeoffice_travel) {
                                // teletravail ou deplacement
                                const sentence = `Pour plus de détail, voir l’onglet de télétravail`;
                                return (
                                    <Tooltip
                                        pointing
                                        subtle={false}
                                        content={sentence}
                                        position="after"
                                        align="center"
                                        trigger={
                                            <CustomBadge
                                                bg={day.color}
                                                color="black"
                                                size="medium"
                                                label={
                                                    <Flex
                                                        vAlign="center"
                                                        gap="gap.small"
                                                    >
                                                        <Text
                                                            content={day.name}
                                                        />
                                                        <CustomBadge
                                                            bg="black"
                                                            color="white"
                                                            size="smaller"
                                                            label={`${day.value} j`}
                                                            rectangular={true}
                                                            style={{
                                                                borderRadius:
                                                                    "4px",
                                                            }}
                                                        />
                                                    </Flex>
                                                }
                                                rectangular={true}
                                                style={{
                                                    borderRadius: "4px",
                                                }}
                                            />
                                        }
                                    />
                                );
                            } else {
                                return (
                                    <CustomBadge
                                        bg={day.color}
                                        color="black"
                                        size="medium"
                                        label={
                                            <Flex
                                                vAlign="center"
                                                gap="gap.small"
                                            >
                                                <Text content={day.name} />
                                                <CustomBadge
                                                    bg="black"
                                                    color="white"
                                                    size="smaller"
                                                    label={`${day.value} j`}
                                                    rectangular={true}
                                                    style={{
                                                        borderRadius: "4px",
                                                    }}
                                                />
                                            </Flex>
                                        }
                                        rectangular={true}
                                        style={{
                                            borderRadius: "4px",
                                        }}
                                    />
                                );
                            }
                        })}
                    </SpecialDayFlex>
                )}
            </Flex>
        </Flex>
    );
};

export default ContentDetailMonth;
