import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Flex, Text } from "@fluentui/react-northstar";

import { Container, ContainerFluid } from "../../../common/styles";
import { Tab, TabList } from "@fluentui/react-components";
import { DEVICE } from "../../../constants/responsive";
import { CustomBadge } from "../../Badge";

/**
 * @typedef {{ content : string, icon : JSX.Element, value : string | number, action_count : number }} Menu
 *
 * @typedef {Object} Props
 * @property {Menu[]} menu
 * @property {number} defaultSelectedValue
 * @property {function} setTabView
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
const SubHeader = ({
    menu = [],
    setTabView,
    defaultSelectedValue,
    ...rest
}) => {
    useEffect(() => {
        console.log({ defaultSelectedValue });
    }, []);

    return (
        <ContainerFluidStyle {...rest}>
            <Container>
                <Wrapper fill vAlign="center" gap="gap.small">
                    <TabList
                        appearance="transparent"
                        // defaultSelectedValue={defaultSelectedValue}
                        selectedValue={defaultSelectedValue}
                    >
                        {menu?.map((el, index) => {
                            return (
                                <Flex vAlign="center">
                                    <Tab
                                        key={`subheader_${index}`}
                                        content={
                                            <Flex
                                                styles={{
                                                    height: "36px",
                                                }}
                                                vAlign="center"
                                                gap="gap.smaller"
                                            >
                                                <span>{el?.content}</span>
                                                {el?.action_count ? (
                                                    <CustomBadge
                                                        color="white"
                                                        label={el?.action_count}
                                                        size="smaller"
                                                    />
                                                ) : null}
                                            </Flex>
                                        }
                                        icon={el?.icon}
                                        value={el.value}
                                        onClick={() => setTabView(el?.value)}
                                    />
                                </Flex>
                            );
                        })}
                    </TabList>
                </Wrapper>
            </Container>
        </ContainerFluidStyle>
    );
};

export default SubHeader;

const ContainerFluidStyle = styled(ContainerFluid)`
    box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px #00000012,
        0px 1.2000000476837158px 3.5999999046325684px 0px #00000008;
    background-color: ${({ fromOnboarding }) =>
        fromOnboarding ? "#FFF" : "#F5F5F5"};
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
`;

const ButtonStyle = styled(Button)`
    background-color: ${({ active }) => (active ? "#EBEbeb" : "transparent")};
    color: ${({ active }) => (active ? "var(--color-primary)" : "inherit")};
`;

const Wrapper = styled(Flex)`
    /* height: 48px; */
    /* outline: 2px dashed green; */
`;
