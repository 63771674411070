import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled, { css } from "styled-components";
import Slot from "../Slots/Slot";
import DialogModifyDay from "../Dialogs/DialogModifyDay/DialogModifyDay";

const DayCard = ({
    day_index,
    day_name = "",
    slots = [],
    duration_str = "--h",
}) => {
    const is_weekend = [5, 6].includes(day_index);
    return (
        <Container column space="between">
            {/* header */}
            <Header hAlign="center" vAlign="center" $isWeekend={is_weekend}>
                <HeaderText content={day_name} />
            </Header>
            {/* body */}
            <Body hAlign="center" column gap="gap.small" space="between">
                {slots.map((slot) => {
                    return <Slot key={slot.id} slot={slot} />;
                })}
                {/* dialog */}
                <OverlayModification>
                    <DialogModifyDay shifts={slots} day_index={day_index}>
                        <ButtonUpdate>Modifier</ButtonUpdate>
                    </DialogModifyDay>
                </OverlayModification>
            </Body>
            {/* footer */}
            <Footer hAlign="center" vAlign="center">
                <FooterTextHour content={duration_str} />
            </Footer>
        </Container>
    );
};

export default DayCard;

const ButtonUpdate = styled.button`
    display: inline-flex;
    height: 29.387px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding-inline: 8px;
    background-color: transparent;
    cursor: pointer;

    border-radius: 4px;
    border: 1px solid var(--Light-theme-Rest-Border-Default-Border, #d1d1d1);

    color: var(--Light-theme-Rest-Foreground-Default-Foreground-3, #fff);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 350;
    line-height: 17px; /* 141.667% */
`;

const OverlayModification = styled(Flex)`
    background-color: rgba(37, 36, 35, 0.65);
    position: absolute;
    inset: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
`;

const Body = styled(Flex)`
    flex: 1;
    position: relative;
    :hover {
        ${OverlayModification} {
            display: flex;
        }
    }
`;

const FooterTextHour = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Brand-Foreground-2, #4f52b2);

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
`;

const Container = styled(Flex)`
    border: 1px solid red;
    display: flex;
    width: 104px;
    gap: 12px;
    position: relative;

    border-radius: var(--spacing-xs-8, 8px) var(--spacing-xs-8, 8px)
        var(--spacing-ixxs-4, 8px) var(--spacing-ixxs-4, 8px);
    border: 1px solid var(--Light-theme-Rest-Border-Default-Border-3, #f0f0f0);
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);
`;

const Header = styled(Flex)`
    display: flex;
    padding: 12px;

    border-radius: var(--spacing-xs-8, 8px) var(--spacing-xs-8, 8px) 0px 0px;
    ${({ $isWeekend }) =>
        $isWeekend
            ? css`
                  background-color: #2f2f4a;
              `
            : css`
                  background-color: #444791;
              `};
`;

const HeaderText = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Default-Foreground-3, #fff);
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

const Footer = styled(Flex)`
    padding: var(--spacing-xs-8, 8px) 0px var(--spacing-s-12, 12px) 0px;
    gap: 8px;

    border-radius: 0px 0px 8px 8px;
    background: var(
        --Light-theme-Rest-Background-Default-Background-2,
        #f5f5f5
    );
`;
