import { useEffect, useReducer, useRef, useState } from "react";
import { ACTIONS_TIMECLOCK, reducer } from "../reducers/reducer";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { _api_get_timeclock, _api_post_timeclock } from "../api";
import { queryClient } from "../../..";
import { useAuthStore } from "../../../stores/useAuthStore";

const useLogicTimeClock = () => {
    const intervalRef = useRef(null);
    const infos = useAuthStore((state) => state.infos);

    const [timeclock, dispatch] = useReducer(reducer, {
        open_menu: false, // juste pour ouvrir/fermer la modale
        timer_count: 0, // format timestamp
        is_running: false,
        format_timer_count: null, // format "xx : xx : xx"
        localisation: {
            latitude: null,
            longitude: null,
            error: null,
            error_message: "",
        },
    });

    // lorsqu'on clique sur icone timeclock
    const onClickTimeClockIcon = () => {
        // on ouvre/ferme le menu
        return dispatch({ type: ACTIONS_TIMECLOCK.open_menu });
    };

    // pour la localisation, et permission.
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    dispatch({
                        type: ACTIONS_TIMECLOCK.set_coordinates,
                        payload: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            error: false,
                            error_message: "",
                        },
                    });
                },
                (error) => {
                    dispatch({
                        type: ACTIONS_TIMECLOCK.set_coordinates,
                        payload: {
                            latitude: null,
                            longitude: null,
                            error: error,
                            error_message:
                                "La localisation est désactivée ou non disponible",
                        },
                    });
                }
            );
        } else {
            dispatch({
                type: ACTIONS_TIMECLOCK.set_coordinates,
                payload: {
                    latitude: null,
                    longitude: null,
                    error: true,
                    error_message:
                        "La géolocalisation n'est pas supportée par ce navigateur.",
                },
            });
        }
    }, []);

    // pour le chrono
    useEffect(() => {
        if (timeclock.is_running) {
            // Démarre l'intervalle
            intervalRef.current = setInterval(() => {
                dispatch({
                    type: ACTIONS_TIMECLOCK.increment_timer,
                });
            }, 1000);
        } else if (!timeclock.is_running && intervalRef.current) {
            // Arrête l'intervalle si le chronomètre est en pause
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        // Nettoyage à la fin
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [timeclock.is_running]);

    // * FUNCTIONS
    // * FUNCTIONS
    // * FUNCTIONS
    const refetchClockAndTimeReporting = () => {
        refetch();
        queryClient.refetchQueries("time_reporting::get_time_reporting");
        return;
    };

    // * QUERIES & MUTATIONS
    // * QUERIES & MUTATIONS
    // * QUERIES & MUTATIONS
    const { data, isLoading, refetch } = useQuery(
        ["timeclock"],
        () => {
            return _api_get_timeclock();
        },
        {
            enabled: infos !== undefined && infos !== null,
            onSuccess: (data) => {
                if (data?.start_time) {
                    // Utiliser Moment.js pour calculer le temps écoulé
                    const startMoment = moment(data.start_time, "HH:mm:ss"); // Convertir l'heure reçue en un moment
                    const currentMoment = moment(); // Moment actuel

                    let elapsed = currentMoment.diff(startMoment);

                    if (data.is_paused) {
                        const pauseStartMoment = moment(
                            data.pause_start_time,
                            "HH:mm:ss"
                        );
                        const diff =
                            pauseStartMoment.diff(startMoment) -
                            data.pause_duration_seconds * 1000;
                        elapsed = diff;
                    }

                    if (
                        data.pause_duration_seconds &&
                        !data.is_paused &&
                        !data.is_stopped
                    ) {
                        elapsed = elapsed - data.pause_duration_seconds * 1000;
                    }

                    return dispatch({
                        type: ACTIONS_TIMECLOCK.set_timer,
                        payload: {
                            timer_count: elapsed,
                            is_paused: data.is_paused,
                            is_stopped: data.is_stopped,
                        },
                    });
                }
            },
        }
    );
    // START TIMECLOCK
    const { isLoading: isLoadingAddEntry, mutate: mutate_add_entry } =
        useMutation(
            () => {
                return _api_post_timeclock(0, timeclock.localisation);
            },
            {
                onSuccess: (data) => {
                    if (data.success) {
                        return refetchClockAndTimeReporting();
                    }
                },
            }
        );
    // STOP TIMECLOCK
    const { isLoading: isLoadingStopEntry, mutate: mutate_stop_entry } =
        useMutation(
            () => {
                return _api_post_timeclock(2, timeclock.localisation);
            },
            {
                onSuccess: (data) => {
                    if (data.success) {
                        refetchClockAndTimeReporting();
                        return;
                    }
                },
            }
        );
    // PAUSE & RESUME TIMECLOCK
    const { isLoading: isLoadingPauseEntry, mutate: mutate_pause_entry } =
        useMutation(
            () => {
                return _api_post_timeclock(1, timeclock.localisation);
            },
            {
                onSuccess: (data) => {
                    if (data.success) {
                        refetchClockAndTimeReporting();
                        return;
                    }
                },
            }
        );

    return {
        timeclock: {
            open_menu: timeclock.open_menu,
            state: timeclock.state_timerclock,
            timer_count: timeclock.timer_count,
            format_timer_count: timeclock.format_timer_count,
        },
        positions: {
            button: {
                bottom: "23px",
                right: "68px",
            },
            menu: {},
        },
        queryTimeclock: {
            data,
            isLoading,
        },
        mutations: {
            start: {
                is_loading: isLoadingAddEntry,
                mutate: mutate_add_entry,
                isLoading: isLoadingAddEntry,
            },
            pause: {
                is_loading: isLoadingPauseEntry,
                mutate: mutate_pause_entry,
                isLoading: isLoadingPauseEntry,
            },
            stop: {
                is_loading: isLoadingStopEntry,
                mutate: mutate_stop_entry,
                isLoading: isLoadingStopEntry,
            },
        },
        localisation: timeclock.localisation,
        onClickTimeClockIcon: onClickTimeClockIcon,
    };
};

export default useLogicTimeClock;
