import { Alert, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";
import { LAYOUT_MAX_WIDTH } from "../../constants";
import { DEVICE } from "../../../../constants/responsive";

export const Header = styled(Flex)`
    padding-block: 12px;
`;
export const HeaderLeft = styled(Flex)``;
export const HeaderRight = styled(Flex)``;

export const Title = styled(Text)`
    color: #242424;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
`;

export const Body = styled(Flex)``;
export const WrapperCard = styled(Flex)`
    width: 100%;
    max-width: 100%;
    background: #fff;
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
    border-top: 4px solid #9299f7;
`;
export const WrapperCardHeader = styled(Flex)`
    /* border: 3px dashed red; */
`;
export const WrapperCardTitle = styled(Text)`
    color: #242424;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;
export const WrapperWeek = styled(Flex)`
    /* padding: 16px 24px 30px 24px; */
    /* overflow-x: scroll; */
    border-top: none;
    background: #fff;
    /* padding-top: 24px; */
    max-width: 100%;
    width: 100%;
    box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
        0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
    @media (max-width: 768px) {
        .card-container {
            border-top: 4px solid #9299f7;
            padding: 0px;
        }
    }
    @media (min-width: 769px) {
        padding: 24px 16px 24px 16px;
    }
`;
export const WrapperChevronSlider = styled(Flex)`
    button.left {
        position: absolute;
        top: 50%;
        left: -50px;
    }
    button.right {
        position: absolute;
        top: 50%;
        right: -50px;
    }
`;
export const AlertFixed = styled(Alert)`
    position: fixed;
    top: 90px;
    left: 50%;
    transform: translate(-50%);
    z-index: 10;
    padding-block: 12px;
    /* min-width: 55%; */

    @media ${DEVICE.mobileS} {
        width: 80%;
        top: 100px;
    }
    @media ${DEVICE.laptop} {
        width: 50%;
    }
`;
