import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button, FilesUploadIcon, Flex, Text } from "@fluentui/react-northstar";
import Illustration from "../../../../components/shared/Illustration";
import styled from "styled-components";

const DropzoneDocument = ({
    isLoading = false,
    mutate_upload_file,
    update_authorized,
    folder_name,
}) => {
    const onDrop = useCallback(
        async (acceptedFiles) => {
            mutate_upload_file({
                uploaded_files: acceptedFiles,
                folder_name,
            });
        },
        [folder_name, mutate_upload_file]
    );

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            "application/*": [".docx", ".doc", ".pptx", ".ppt"],
            "application/pdf": [".pdf"],
            "image/*": [".jpeg", ".jpg", ".png"],
        },

        onDrop: onDrop,
        maxFiles: 5,
        disabled: !update_authorized,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <Wrapper
            {...getRootProps({ style })}
            column
            gap="gap.small"
            fill
            update_authorized={update_authorized}
        >
            <Flex
                column
                gap="gap.medium"
                hAlign="center"
                update_authorized={update_authorized}
            >
                <input {...getInputProps()} />
                <Title>Importer un document</Title>
                <Illustration
                    filename={"Import-doc.svg"}
                    width={"160px"}
                    height={"auto"}
                />
                <Button
                    icon={<FilesUploadIcon />}
                    content="Importer un document"
                    iconPosition="before"
                    primary
                    loading={isLoading}
                    disabled={!update_authorized}
                />
            </Flex>
            <Flex
                column
                gap="gap.small"
                styles={{ width: "100%" }}
                update_authorized={update_authorized}
            >
                <TextStyle>
                    <b>Poids maximal</b> du fichier : <b>2,5 Mo</b>
                </TextStyle>
                <TextStyle>
                    <b>Nombre</b> de fichiers <b>maximum : 5</b>
                </TextStyle>
            </Flex>
        </Wrapper>
    );
};

export default DropzoneDocument;

const Wrapper = styled(Flex)`
    opacity: ${({ update_authorized }) => (!update_authorized ? "0.7" : "1")};
    cursor: ${({ update_authorized }) =>
        !update_authorized ? "not-allowed!important" : "pointer!important"};
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #242424;
`;

const TextStyle = styled(Text)`
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
`;

const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    outline: "none",
    height: "336px",
    width: "400px",
    gap: 15,
    cursor: "pointer",
    borderWidth: "2px",
    borderStyle: "dashed",
    borderColor: "#C5CBFA",
    boxShadow:
        "0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};
