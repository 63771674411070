import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { useAuthDetailStore } from "../stores/useAuthStore";

const withRole = (requiredRoles) => (WrappedComponent) => {
    // eslint-disable-next-line react/display-name
    return (props) => {
        const detail = useAuthDetailStore((state) => state.detail);
        // on vérifie si authenrtificated user est connecté ou pas, sinon logout.
        // peut etre il a supprime le storage.
        if (!detail) {
            return <Navigate to={ROUTES.login} />;
        }

        // récupérer le role depuis le details
        const userRoles = detail?.roles?.map((el) => el?.code);
        // voir si il a les roles
        const isAuthorized = requiredRoles?.some((el) =>
            userRoles?.includes(el)
        );

        if (!isAuthorized) {
            // redirige vers la page DENIED.
            return <Navigate to={ROUTES.denied} />;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withRole;
