import { PresenceBadge } from "@fluentui/react-components";
import {
    Button,
    ChevronEndIcon,
    Flex,
    MenuButton,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { getDaynameByIndex } from "../../../../../addEmployees/utils";
import { useFormContext } from "react-hook-form";

const PRESENCE_BADGE = [
    {
        badge: <PresenceBadge status="unknown" />,
        content: (
            <Flex vAlign="center" gap="gap.smaller">
                <PresenceBadge status="unknown" />
                <Text content="Repos temps partiel" />
            </Flex>
        ),
    },
    {
        badge: <PresenceBadge />,
        content: (
            <Flex vAlign="center" gap="gap.smaller">
                <PresenceBadge />
                <Text content="Travaillé" />
            </Flex>
        ),
    },
    {
        badge: <PresenceBadge status="out-of-office" />,
        content: (
            <Flex vAlign="center" gap="gap.smaller">
                <PresenceBadge status="out-of-office" />
                <Text content="Repos hebdomadaire" />
            </Flex>
        ),
    },
];

/**
 * Composant AnotherComponent
 * Utilise également les mêmes propriétés que Planning.
 *
 * @param {import("./Planning").PlanningProps} props - Les propriétés du composant.
 * @returns {JSX.Element} Le rendu du composant AnotherComponent.
 */
const PlanningCard = ({ mode = "reading", slots, indexPosition }) => {
    const method = useFormContext();

    const day_name = getDaynameByIndex(indexPosition, "ddd");
    const menu = PRESENCE_BADGE.map((item) => item.content);
    const style = {
        minWidth: "56px",
        width: "56px",
        padding: "0px",
    };

    const handleChangeStatusDay = ({ time, index_day, changed_value }) => {
        const old_planning = method.getValues("timetable");
        const new_planning = old_planning.map((plan, index) => {
            if (index_day === index) {
                return {
                    am: time === "am" ? changed_value : plan.am,
                    pm: time === "pm" ? changed_value : plan.pm,
                };
            }
            return plan;
        });
        // set new value
        method.setValue("timetable", new_planning);
        return;
    };

    return (
        <Card column>
            {/* header */}
            <CardHeader hAlign="center">
                <CardHeaderDayname content={day_name} />
            </CardHeader>
            {/* body */}
            <CardBody column hAlign="center" gap="gap.medium">
                {mode === "reading" ? (
                    <>
                        <WrapperPresence hAlign="center" vAlign="center">
                            {PRESENCE_BADGE[slots.am].badge}
                        </WrapperPresence>
                        <WrapperPresence hAlign="center" vAlign="center">
                            {PRESENCE_BADGE[slots.pm].badge}
                        </WrapperPresence>
                    </>
                ) : (
                    <>
                        <MenuButton
                            trigger={
                                <Button
                                    icon={<ChevronEndIcon rotate="90" />}
                                    iconPosition="after"
                                    content={PRESENCE_BADGE[slots?.am]?.badge}
                                    aria-label="Click button"
                                    onClick={(e, i) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    styles={style}
                                />
                            }
                            menu={menu}
                            on="click"
                            onMenuItemClick={(e, i) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleChangeStatusDay({
                                    time: "am",
                                    index_day: indexPosition,
                                    changed_value: i?.index,
                                });
                                return;
                            }}
                        />
                        <MenuButton
                            trigger={
                                <Button
                                    icon={<ChevronEndIcon rotate="90" />}
                                    iconPosition="after"
                                    content={PRESENCE_BADGE[slots?.pm]?.badge}
                                    aria-label="Click button"
                                    onClick={(e, i) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    styles={style}
                                />
                            }
                            menu={menu}
                            on="click"
                            onMenuItemClick={(e, i) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleChangeStatusDay({
                                    time: "pm",
                                    index_day: indexPosition,
                                    changed_value: i?.index,
                                });
                                return;
                            }}
                        />
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default PlanningCard;

const WrapperPresence = styled(Flex)`
    width: 56px;
    padding: 8px;

    gap: 8px;

    border-radius: 4px;
    border: 1px solid var(--Light-theme-Rest-Border-Default-Border-2, #e0e0e0);
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);

    /* shadow-2 */
    box-shadow: 0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13);
`;

const CardBody = styled(Flex)``;

const CardHeaderDayname = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Default-Foreground-3, #fff);
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const CardHeader = styled(Flex)`
    padding: var(--spacing-s-12, 12px);

    border-radius: var(--spacing-xs-8, 8px) var(--spacing-xs-8, 8px) 0px 0px;
    background: var(--Light-theme-Rest-Background-Brand-Background-4, #444791);
`;

const Card = styled(Flex)`
    min-width: 80px;
    padding-bottom: var(--spacing-s-12, 12px);

    gap: var(--spacing-s-12, 12px);

    border-radius: var(--spacing-xs-8, 8px) var(--spacing-xs-8, 8px)
        var(--spacing-ixxs-4, 4px) var(--spacing-ixxs-4, 4px);
    border: 1px solid var(--Light-theme-Rest-Border-Default-Border-3, #f0f0f0);
`;
