import {
    ArrowRightIcon,
    Button,
    CloseIcon,
    Dialog,
    Dropdown,
    Flex,
    Input,
    Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import {
    CalendarEdit20Regular,
    Edit20Regular,
    FolderLink20Regular,
    Handshake20Regular,
} from "@fluentui/react-icons";
import {
    _changeStartDate,
    _changeStatusOnboarding,
    _renewTrialPeriod,
    _startPreonboarding,
} from "../api";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "../../../common/styles";
import ContentDialogInterruptionAction from "./ContentDialogInterruptionAction";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

export default function FooterActions({
    startDate,
    badgeText = "Pré-onboarding",
    setToastConfig,
    refetchDataUserDetail,
    activeTab,
    activeTabFromLocation,
}) {
    const navigate = useNavigate();

    console.log({ activeTab125: activeTab });

    const [
        openDialogInterruptionOnboading,
        SetOpenDialogInterruptionOnboarding,
    ] = useState(false);
    const [openDialogChandePeriodeEssai, SetOpenDialogChandePeriodeEssai] =
        useState(false);
    const [openDialogChangeStartDate, SetOpenDialogChangeStartDate] =
        useState(false);
    const user_id = useParams();
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // Mois commence à 0
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    const [params] = useSearchParams();
    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            status: "",
            datefrom: getCurrentDate(),
            // datefrom: ,
        },
    });

    const tabParams = params.get("tab");
    const subtabParams = params.get("subtab");
    console.log({ tabParams, subtabParams });

    const {
        mutate: mutate_change_status_onboarding,
        isLoading: loading_change_status_onboarding,
    } = useMutation((data) => _changeStatusOnboarding(data), {
        onSuccess: (reponse) => {
            console.log({ reponse: reponse });
            if (reponse.success) {
                setToastConfig({
                    content: "Statut modifié avec succès",
                    visible: true,
                    level: "success",
                });
                SetOpenDialogInterruptionOnboarding(false);
                reset();
                setTimeout(() => {
                    navigate(
                        "/onboarding?tab=" +
                            tabParams +
                            "&subtab=" +
                            subtabParams
                    );
                }, 2000);

                return;
            } else {
                setToastConfig({
                    content: "Erreur lors de la modification du statut",
                    visible: true,
                    level: "danger",
                });
                SetOpenDialogInterruptionOnboarding(true);
            }
        },
    });

    const onSubmitStopOnboarding = (data) => {
        mutate_change_status_onboarding({ ...data, user_id });
    };

    const { mutate: mutate_start_preonboarding, isLoading: is_loading_mutate } =
        useMutation((user_id) => _startPreonboarding(user_id), {
            onSuccess: (response) => {
                if (response.success) {
                    setToastConfig({
                        content: "Pré-onboarding lancé avec succès",
                        visible: true,
                        level: "success",
                    });
                    setTimeout(() => {
                        navigate(
                            "/onboarding?tab=" +
                                tabParams +
                                "&subtab=" +
                                subtabParams
                        );
                    }, 2000);
                } else {
                    setToastConfig({
                        content: response.message,
                        visible: true,
                        level: "danger",
                    });
                }
            },
        });

    const startPreOnboarding = (user_id) => {
        return mutate_start_preonboarding(user_id);
    };

    // chhanger la periode d'essai logic

    const { isLoading: loadingRenewTrialPeriod, mutate: mutateRenewPeriod } =
        useMutation((data) => _renewTrialPeriod(data), {
            onSuccess: (reponse) => {
                console.log({ reponse: reponse });
                if (reponse.success) {
                    setToastConfig({
                        content: "Période d'essai renouvelée avec succès",
                        visible: true,
                        level: "success",
                    });
                    SetOpenDialogChandePeriodeEssai(false);
                    reset();
                    refetchDataUserDetail();
                } else {
                    setToastConfig({
                        content: "Erreur lors du renouvellement de la période",
                        visible: true,
                        level: "danger",
                    });
                    SetOpenDialogChandePeriodeEssai(true);
                }
            },
        });
    const { isLoading: loadingChangeStartDate, mutate: mutateChangeStartDate } =
        useMutation((data) => _changeStartDate(data), {
            onSuccess: (reponse) => {
                console.log({ reponse: reponse });
                if (reponse.success) {
                    setToastConfig({
                        content: "La date de début est bien changée",
                        visible: true,
                        level: "success",
                    });
                    SetOpenDialogChangeStartDate(false);
                    reset();
                    refetchDataUserDetail();
                } else {
                    setToastConfig({
                        content:
                            "Erreur lors du changement de la date de début",
                        visible: true,
                        level: "danger",
                    });
                    SetOpenDialogChangeStartDate(true);
                }
            },
        });

    const renewTrialPeriod = (user_id) => {
        const new_date = watch("datefrom");
        return mutateRenewPeriod({ user_id, new_date });
    };
    const changeStartDate = (user_id) => {
        const new_date = watch("datefrom");
        return mutateChangeStartDate({ user_id, new_date });
    };
    // end of perdiode d essai logic
    console.log({
        activeTab159: activeTab,
        activeTabFromLocation,
        subtabParams,
    });
    const inputItems = [
        // nouveau
        {
            id: "-2",
            // nameShowedInDropdown: "Nouveau",
            header: "Nouveau",
            description: {
                titleDescription:
                    "Ce statut a pour conséquences sur le collaborateur :",
                contentDescription: [
                    "Plus de connexion possible à Yeetch",
                    "Archivage de la liste de tâches",
                    "Réinitialisation de tous les soldes (vacances, récupération, congés payés...)",
                    "Il ne sera plus visible dans l’outil",
                ],
            },
        },
        // PréonBoarding:
        // {
        //     id: "-2",
        //     // nameShowedInDropdown: "A démarrer",
        //     header: "A démarrer",
        //     description: {
        //         titleDescription:
        //             "Ce statut a pour conséquences sur le collaborateur :",
        //         contentDescription: [
        //             "Plus de connexion possible à Yeetch",
        //             "Archivage de la liste de tâches",
        //             "Réinitialisation de tous les soldes (vacances, récupération, congés payés...)",
        //             "Il ne sera plus visible dans l’outil",
        //         ],
        //     },
        // },
        // nouveau
        {
            id: "0",
            // nameShowedInDropdown: "Pré-onboarding",
            header: "Préonboarding",
            description: {
                titleDescription:
                    "Ce statut a pour conséquences sur le collaborateur :",
                contentDescription: [
                    "Accès restreint à un espace d’échange documentaire",
                    "Il est possible d’attribuer des tâches à ce collaborateur",
                    "Ce collaborateur n’apparaît pas dans l’organigramme et ne peut pas voir l’organigramme non-plus",
                    "Il ne cumule pas de soldes de vacances/congés",
                ],
            },
        },
        {
            id: "-3",
            header: "Onboarding",
            // nameShowedInDropdown: "Onboarding",
            description: {
                titleDescription:
                    "Ce statut a pour conséquences sur le collaborateur :",
                contentDescription: [
                    "Il aura accès à l’ensemble de l’outil et sera inclus dans l’organigramme",
                ],
            },
        },
        {
            id: "1",
            header: "Suivi",
            // nameShowedInDropdown: "Suivi",
            description: {
                titleDescription: "Statut : Suivi",
                contentDescription: [
                    "Il aura accès à l’ensemble de l’outil et sera inclus dans l’organigramme",
                ],
            },
        },
        {
            id: "6",
            // nameShowedInDropdown: "Offboarding",
            header: "Offboarding",
            description: {
                titleDescription:
                    "Ce statut a pour conséquences sur le collaborateur :",
                contentDescription: [
                    "Il aura accès à l’ensemble de l’outil et sera inclus dans l’organigramme",
                ],
            },
            // il manque le statut archivé heyyyyyyyyyyyy
        },
    ];

    // tab a démarrer

    if (
        activeTab === 0 ||
        activeTabFromLocation === 0 ||
        subtabParams === "0"
    ) {
        const _editProfile = (user) => {
            // console.log("updateInformation", user?.id_task);
            return navigate(
                `${ROUTES.employee_add}?t=update_onboarding&id=${user?.id_task}`
            );
        };

        return (
            <>
                <Button
                    onClick={() => _editProfile(user_id)}
                    iconPosition="after"
                    secondary
                    content={"Modifier le profil"}
                    icon={<Edit20Regular />}
                    flat
                />
                <Button
                    loading={is_loading_mutate}
                    onClick={() => startPreOnboarding(user_id)}
                    iconPosition="after"
                    primary
                    content={"Lancer le pré-onboarding"}
                    icon={<Handshake20Regular />}
                    flat
                />
            </>
        );
    }
    // Tab en cours + a cloturer
    // if (activeTab === 2 || activeTab === 3) {
    //     const selectedStatus = watch("status");
    //     return (
    //         <>
    //             {/* renouveler la periode d'essai UI */}
    //             <Dialog
    //                 open={openDialogChandePeriodeEssai}
    //                 positionFixed
    //                 header="Renouveler la période d'essai"
    //                 headerAction={{
    //                     icon: <CloseIcon />,
    //                     title: "Fermer",
    //                     onClick: () => {
    //                         reset();
    //                         SetOpenDialogChandePeriodeEssai(false);
    //                     },
    //                 }}
    //                 content={
    //                     <form onSubmit={handleSubmit(renewTrialPeriod)}>
    //                         <Flex fill column gap="gap.medium">
    //                             <Label
    //                                 content={
    //                                     "A quelle date voulez vous mettre la date de début ?"
    //                                 }
    //                             />
    //                             <Flex fill>
    //                                 <Controller
    //                                     name="datefrom"
    //                                     control={control}
    //                                     rules={{
    //                                         required:
    //                                             "Veuillez sélectionner une date",
    //                                         validate: (value) => {
    //                                             const today = new Date()
    //                                                 .toISOString()
    //                                                 .split("T")[0];
    //                                             return (
    //                                                 value >= today ||
    //                                                 "La date ne peut pas être antérieure à aujourd'hui"
    //                                             );
    //                                         },
    //                                     }} // Validation du champ date
    //                                     render={({
    //                                         field: { onChange, value },
    //                                     }) => (
    //                                         <Input
    //                                             error={
    //                                                 errors?.datefrom
    //                                                     ? true
    //                                                     : false
    //                                             }
    //                                             type="date"
    //                                             placeholder="Aujourd'hui"
    //                                             fluid
    //                                             label="Date de début *"
    //                                             value={value}
    //                                             // defaultValue={startDate} // Liée à la valeur actuelle du champ
    //                                             min={
    //                                                 new Date()
    //                                                     .toISOString()
    //                                                     .split("T")[0]
    //                                             }
    //                                             onChange={(e) =>
    //                                                 onChange(e.target.value)
    //                                             }
    //                                         />
    //                                     )}
    //                                 />
    //                                 {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
    //                                 {errors.datefrom && (
    //                                     <ErrorMessage
    //                                         content={errors.datefrom.message}
    //                                     />
    //                                 )}
    //                             </Flex>
    //                         </Flex>
    //                     </form>
    //                 }
    //                 trigger={
    //                     <Button
    //                         onClick={() =>
    //                             SetOpenDialogChandePeriodeEssai(true)
    //                         }
    //                         iconPosition="after"
    //                         secondary
    //                         content={"Renouveler la période d'essai"}
    //                         icon={<Edit20Regular />}
    //                     />
    //                 }
    //                 footer={
    //                     <Flex gap="gap.small" hAlign="end">
    //                         <Button
    //                             onClick={() => {
    //                                 reset();
    //                                 SetOpenDialogChandePeriodeEssai(false);
    //                             }}
    //                             content="Annuler"
    //                         />
    //                         <Button
    //                             loading={loadingRenewTrialPeriod}
    //                             disabled={!isValid}
    //                             primary
    //                             content="Renouveler la période d'essai"
    //                             onClick={() => renewTrialPeriod(user_id)}
    //                             // onClick={() => changeStartDate(user_id)}
    //                         />
    //                     </Flex>
    //                 }
    //             />

    //             <DialogStyle
    //                 positionFixed
    //                 open={openDialogInterruptionOnboading}
    //                 content={
    //                     <form onSubmit={handleSubmit(onSubmitStopOnboarding)}>
    //                         <Flex fill column gap="gap.medium">
    //                             <Flex>
    //                                 <Label
    //                                     content={
    //                                         "Quel autre statut souhaitez-vous attribuer au collaborateur ?"
    //                                     }
    //                                 />
    //                             </Flex>
    //                             <Flex fill space="between" vAlign="center">
    //                                 <BadgeFlex>
    //                                     <BadgeText content={"Onboarding"} />
    //                                 </BadgeFlex>
    //                                 <ArrowRightIcon color="gray" />
    //                                 {/* <Flex fill> */}
    //                                 <Controller
    //                                     name="status"
    //                                     control={control}
    //                                     rules={{
    //                                         required:
    //                                             "Veuillez sélectionner un statut",
    //                                     }}
    //                                     render={({
    //                                         field: { onChange, value },
    //                                     }) => (
    //                                         <DropdownStyled
    //                                             fluid
    //                                             error={
    //                                                 errors?.status
    //                                                     ? true
    //                                                     : false
    //                                             }
    //                                             positionFixed
    //                                             position="below"
    //                                             items={inputItems}
    //                                             placeholder="Choisir un statut dans la liste"
    //                                             checkable
    //                                             onChange={(_, e) =>
    //                                                 onChange(e?.value)
    //                                             }
    //                                             getA11ySelectionMessage={{
    //                                                 onAdd: (item) =>
    //                                                     `${item} has been selected.`,
    //                                             }}
    //                                         />
    //                                     )}
    //                                 />
    //                                 {errors.status && (
    //                                     <ErrorMessage
    //                                         content={errors.status.message}
    //                                     />
    //                                 )}
    //                                 {/* </Flex> */}
    //                             </Flex>

    //                             {/* date input  */}
    //                             {selectedStatus && (
    //                                 <Flex fill column>
    //                                     <Controller
    //                                         name="datefrom"
    //                                         control={control}
    //                                         rules={{
    //                                             required:
    //                                                 "Veuillez sélectionner une date",
    //                                         }} // Validation du champ date
    //                                         render={({
    //                                             field: { onChange, value },
    //                                         }) => (
    //                                             <InputStyle
    //                                                 error={
    //                                                     errors?.datefrom
    //                                                         ? true
    //                                                         : false
    //                                                 }
    //                                                 type="date"
    //                                                 placeholder="Aujourd'hui"
    //                                                 fluid
    //                                                 label={
    //                                                     <LabelDate>
    //                                                         A partir de
    //                                                         <span
    //                                                             style={{
    //                                                                 color: "red",
    //                                                             }}
    //                                                         >
    //                                                             {"  "}*
    //                                                         </span>
    //                                                     </LabelDate>
    //                                                 }
    //                                                 onChange={(e) =>
    //                                                     onChange(e.target.value)
    //                                                 }
    //                                                 min={getCurrentDate()}
    //                                                 value={
    //                                                     value ||
    //                                                     getCurrentDate()
    //                                                 }
    //                                             />
    //                                         )}
    //                                     />
    //                                     {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
    //                                     {errors.datefrom && (
    //                                         <ErrorMessage
    //                                             content={
    //                                                 errors.datefrom.message
    //                                             }
    //                                         />
    //                                     )}
    //                                     <Flex>
    //                                         <ContentDialogInterruptionAction
    //                                             description={selectedStatus}
    //                                         />
    //                                     </Flex>
    //                                 </Flex>
    //                             )}
    //                             {!selectedStatus && (
    //                                 <Flex
    //                                     styles={{
    //                                         height: "219px",
    //                                     }}
    //                                 />
    //                             )}
    //                         </Flex>
    //                     </form>
    //                 }
    //                 header={"Interrompre l'onboarding"}
    //                 headerAction={{
    //                     icon: <CloseIcon />,
    //                     title: "Close",
    //                     onClick: () => {
    //                         reset();
    //                         SetOpenDialogInterruptionOnboarding(false);
    //                     },
    //                 }}
    //                 trigger={
    //                     <Button
    //                         loading={false}
    //                         onClick={() =>
    //                             SetOpenDialogInterruptionOnboarding(true)
    //                         }
    //                         iconPosition="after"
    //                         primary
    //                         content={"Interrompre l'onboarding"}
    //                         icon={<FolderLink20Regular />}
    //                     />
    //                 }
    //                 footer={
    //                     <Flex gap="gap.small" hAlign="end">
    //                         <Button
    //                             flat
    //                             onClick={() => {
    //                                 reset();
    //                                 SetOpenDialogInterruptionOnboarding(false);
    //                             }}
    //                             content="Annuler"
    //                         />
    //                         <Button
    //                             flat
    //                             loading={loading_change_status_onboarding}
    //                             // disabled={!isValid}
    //                             // Désactive le bouton si une erreur est présente
    //                             primary
    //                             onClick={handleSubmit(onSubmitStopOnboarding)}
    //                             content="Valider ce statut"
    //                         />
    //                     </Flex>
    //                 }
    //             />
    //         </>
    //     );
    // }
    // tab ; préonboarding

    // if (activeTab === 1 || activeTab === 4) {
    //     const selectedStatus = watch("status");
    //     const current_date = getCurrentDate();

    //     return (
    //         <>
    //             {activeTab !== 4 && (
    //                 <Dialog
    //                     open={openDialogChangeStartDate}
    //                     positionFixed
    //                     header="Changer la date de début  "
    //                     headerAction={{
    //                         icon: <CloseIcon />,
    //                         title: "Fermer",
    //                         onClick: () => {
    //                             reset();
    //                             SetOpenDialogChangeStartDate(false);
    //                         },
    //                     }}
    //                     content={
    //                         <form onSubmit={handleSubmit(renewTrialPeriod)}>
    //                             <Flex fill column gap="gap.medium">
    //                                 <Label
    //                                     content={
    //                                         "A quelle date voulez vous mettre la date de début ?"
    //                                     }
    //                                 />
    //                                 <Flex fill>
    //                                     <Controller
    //                                         name="datefrom"
    //                                         control={control}
    //                                         rules={{
    //                                             required:
    //                                                 "Veuillez sélectionner une date",
    //                                             validate: (value) => {
    //                                                 const today = new Date()
    //                                                     .toISOString()
    //                                                     .split("T")[0];
    //                                                 return (
    //                                                     value >= today ||
    //                                                     "La date ne peut pas être antérieure à aujourd'hui"
    //                                                 );
    //                                             },
    //                                         }} // Validation du champ date
    //                                         render={({
    //                                             field: { onChange, value },
    //                                         }) => (
    //                                             <Input
    //                                                 error={
    //                                                     errors?.datefrom
    //                                                         ? true
    //                                                         : false
    //                                                 }
    //                                                 type="date"
    //                                                 placeholder="Aujourd'hui"
    //                                                 fluid
    //                                                 label={
    //                                                     <LabelDate>
    //                                                         A partir de
    //                                                         <span
    //                                                             style={{
    //                                                                 color: "red",
    //                                                             }}
    //                                                         >
    //                                                             {"  "}*
    //                                                         </span>
    //                                                     </LabelDate>
    //                                                 }
    //                                                 value={value}
    //                                                 defaultValue={startDate} // Liée à la valeur actuelle du champ
    //                                                 min={
    //                                                     new Date()
    //                                                         .toISOString()
    //                                                         .split("T")[0]
    //                                                 }
    //                                                 onChange={(e) =>
    //                                                     onChange(e.target.value)
    //                                                 }
    //                                             />
    //                                         )}
    //                                     />
    //                                     {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
    //                                     {errors.datefrom && (
    //                                         <ErrorMessage
    //                                             content={
    //                                                 errors.datefrom.message
    //                                             }
    //                                         />
    //                                     )}
    //                                 </Flex>
    //                             </Flex>
    //                         </form>
    //                     }
    //                     trigger={
    //                         <Button
    //                             onClick={() =>
    //                                 SetOpenDialogChangeStartDate(true)
    //                             }
    //                             iconPosition="after"
    //                             secondary
    //                             content={"Changer la date de début"}
    //                             icon={<CalendarEdit20Regular />}
    //                         />
    //                     }
    //                     footer={
    //                         <Flex gap="gap.small" hAlign="end">
    //                             <Button
    //                                 onClick={() => {
    //                                     reset();
    //                                     SetOpenDialogChangeStartDate(false);
    //                                 }}
    //                                 content="Annuler"
    //                             />
    //                             <Button
    //                                 loading={loadingChangeStartDate}
    //                                 // disabled={!isValid}
    //                                 primary
    //                                 content="Changer la date de début"
    //                                 // onClick={() => renewTrialPeriod(user_id)}
    //                                 onClick={() => changeStartDate(user_id)}
    //                             />
    //                         </Flex>
    //                     }
    //                 />
    //             )}
    //             {/* Interrumption modale dialog logic  */}
    //             <DialogStyle
    //                 // positionFixed
    //                 open={openDialogInterruptionOnboading}
    //                 content={
    //                     <form onSubmit={handleSubmit(onSubmitStopOnboarding)}>
    //                         <Flex column gap="gap.medium">
    //                             <Label
    //                                 content={
    //                                     "Quel autre statut souhaitez-vous attribuer au collaborateur ?"
    //                                 }
    //                             />
    //                             <Flex space="between" fill vAlign="center">
    //                                 <BadgeFlex>
    //                                     <BadgeText content={badgeText} />
    //                                 </BadgeFlex>
    //                                 <ArrowRightIcon color="gray" />
    //                                 <Flex column>
    //                                     <Controller
    //                                         name="status"
    //                                         control={control}
    //                                         rules={{
    //                                             required:
    //                                                 "Veuillez sélectionner un statut",
    //                                         }} // Ajout de validation
    //                                         render={({
    //                                             field: { onChange, value },
    //                                         }) => (
    //                                             <DropdownStyled
    //                                                 error={
    //                                                     errors?.status
    //                                                         ? true
    //                                                         : false
    //                                                 }
    //                                                 positionFixed
    //                                                 items={inputItems}
    //                                                 placeholder="Choisir un statut dans la listesss"
    //                                                 checkable
    //                                                 onChange={(_, e) => {
    //                                                     return onChange(
    //                                                         e?.value
    //                                                     );
    //                                                 }}
    //                                                 getA11ySelectionMessage={{
    //                                                     onAdd: (item) =>
    //                                                         `${item} has been selected.`,
    //                                                 }}
    //                                             />
    //                                         )}
    //                                     />
    //                                     {errors.status && (
    //                                         <ErrorMessage
    //                                             content={errors.status.message}
    //                                         />
    //                                     )}
    //                                 </Flex>
    //                             </Flex>
    //                             {/* date input  */}
    //                             {selectedStatus && (
    //                                 <Flex fill column>
    //                                     <Controller
    //                                         name="datefrom"
    //                                         control={control}
    //                                         rules={{
    //                                             required:
    //                                                 "Veuillez sélectionner une date",
    //                                         }}
    //                                         defaultValue={current_date}
    //                                         render={({
    //                                             field: { onChange, value },
    //                                         }) => (
    //                                             <InputStyle
    //                                                 error={
    //                                                     errors?.datefrom
    //                                                         ? true
    //                                                         : false
    //                                                 }
    //                                                 type="date"
    //                                                 placeholder="Aujourd'hui"
    //                                                 fluid
    //                                                 label={
    //                                                     <LabelDate>
    //                                                         A partir de
    //                                                         <span
    //                                                             style={{
    //                                                                 color: "red",
    //                                                             }}
    //                                                         >
    //                                                             {"  "}*
    //                                                         </span>
    //                                                     </LabelDate>
    //                                                 }
    //                                                 onChange={(e) =>
    //                                                     onChange(e.target.value)
    //                                                 }
    //                                                 value={
    //                                                     value || current_date
    //                                                 }
    //                                             />
    //                                         )}
    //                                     />
    //                                     {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
    //                                     {errors.datefrom && (
    //                                         <ErrorMessage
    //                                             content={
    //                                                 errors.datefrom.message
    //                                             }
    //                                         />
    //                                     )}
    //                                     <Flex>
    //                                         <ContentDialogInterruptionAction
    //                                             description={selectedStatus}
    //                                         />
    //                                     </Flex>
    //                                 </Flex>
    //                             )}
    //                             {!selectedStatus && (
    //                                 <Flex
    //                                     styles={{
    //                                         height: "219px",
    //                                     }}
    //                                 />
    //                             )}
    //                         </Flex>
    //                     </form>
    //                 }
    //                 header={
    //                     activeTab === 4
    //                         ? "Interrompre le suivi "
    //                         : "Interrompre le Pré-onboarding ?"
    //                 }
    //                 headerAction={{
    //                     icon: <CloseIcon />,
    //                     title: "Close",
    //                     onClick: () => {
    //                         reset();
    //                         SetOpenDialogInterruptionOnboarding(false);
    //                     },
    //                 }}
    //                 trigger={
    //                     <Button
    //                         loading={false}
    //                         onClick={() =>
    //                             SetOpenDialogInterruptionOnboarding(true)
    //                         }
    //                         iconPosition="after"
    //                         primary
    //                         content={
    //                             activeTab === 4
    //                                 ? "Interrompre le suivi"
    //                                 : "Interrompre le pré-onboarding"
    //                         }
    //                         icon={<FolderLink20Regular />}
    //                     />
    //                 }
    //                 footer={
    //                     <Flex gap="gap.small" hAlign="end">
    //                         <Button
    //                             onClick={() => {
    //                                 reset();
    //                                 SetOpenDialogInterruptionOnboarding(false);
    //                             }}
    //                             content="Annuler"
    //                         />
    //                         <Button
    //                             loading={loading_change_status_onboarding}
    //                             // disabled={!isValid}
    //                             // Désactive le bouton si une erreur est présente
    //                             primary
    //                             onClick={handleSubmit(onSubmitStopOnboarding)}
    //                             content="Valider ce statut"
    //                         />
    //                     </Flex>
    //                 }
    //             />
    //         </>
    //     );
    // }

    // tab en suivie

    // changement start from here
    // tab préonboarding with subtabParams
    if (subtabParams === "1") {
        const selectedStatus = watch("status");
        const current_date = getCurrentDate();

        return (
            <>
                <Dialog
                    open={openDialogChangeStartDate}
                    positionFixed
                    header="Changer la date de début  "
                    headerAction={{
                        icon: <CloseIcon />,
                        title: "Fermer",
                        onClick: () => {
                            reset();
                            SetOpenDialogChangeStartDate(false);
                        },
                    }}
                    content={
                        <form onSubmit={handleSubmit(renewTrialPeriod)}>
                            <Flex fill column gap="gap.medium">
                                <Label
                                    content={
                                        "A quelle date voulez vous mettre la date de début ?"
                                    }
                                />
                                <Flex fill>
                                    <Controller
                                        name="datefrom"
                                        control={control}
                                        rules={{
                                            required:
                                                "Veuillez sélectionner une date",
                                            validate: (value) => {
                                                const today = new Date()
                                                    .toISOString()
                                                    .split("T")[0];
                                                return (
                                                    value >= today ||
                                                    "La date ne peut pas être antérieure à aujourd'hui"
                                                );
                                            },
                                        }} // Validation du champ date
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Input
                                                error={
                                                    errors?.datefrom
                                                        ? true
                                                        : false
                                                }
                                                type="date"
                                                placeholder="Aujourd'hui"
                                                fluid
                                                label={
                                                    <LabelDate>
                                                        A partir de
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {"  "}*
                                                        </span>
                                                    </LabelDate>
                                                }
                                                value={value}
                                                defaultValue={startDate} // Liée à la valeur actuelle du champ
                                                min={
                                                    new Date()
                                                        .toISOString()
                                                        .split("T")[0]
                                                }
                                                onChange={(e) =>
                                                    onChange(e.target.value)
                                                }
                                            />
                                        )}
                                    />
                                    {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
                                    {errors.datefrom && (
                                        <ErrorMessage
                                            content={errors.datefrom.message}
                                        />
                                    )}
                                </Flex>
                            </Flex>
                        </form>
                    }
                    trigger={
                        <Button
                            onClick={() => SetOpenDialogChangeStartDate(true)}
                            iconPosition="after"
                            secondary
                            content={"Changer la date de début"}
                            icon={<CalendarEdit20Regular />}
                        />
                    }
                    footer={
                        <Flex gap="gap.small" hAlign="end">
                            <Button
                                onClick={() => {
                                    reset();
                                    SetOpenDialogChangeStartDate(false);
                                }}
                                content="Annuler"
                            />
                            <Button
                                loading={loadingChangeStartDate}
                                // disabled={!isValid}
                                primary
                                content="Changer la date de début"
                                // onClick={() => renewTrialPeriod(user_id)}
                                onClick={() => changeStartDate(user_id)}
                            />
                        </Flex>
                    }
                />

                {/* Interrumption modale dialog logic  */}
                <DialogStyle
                    // positionFixed
                    open={openDialogInterruptionOnboading}
                    content={
                        <form onSubmit={handleSubmit(onSubmitStopOnboarding)}>
                            <Flex column gap="gap.medium">
                                <Label
                                    content={
                                        "Quel autre statut souhaitez-vous attribuer au collaborateur ?"
                                    }
                                />
                                <Flex space="between" fill vAlign="center">
                                    <BadgeFlex>
                                        <BadgeText content={badgeText} />
                                    </BadgeFlex>
                                    <ArrowRightIcon color="gray" />
                                    <Flex column>
                                        <Controller
                                            name="status"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Veuillez sélectionner un statut",
                                            }} // Ajout de validation
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <DropdownStyled
                                                    error={
                                                        errors?.status
                                                            ? true
                                                            : false
                                                    }
                                                    positionFixed
                                                    items={inputItems}
                                                    placeholder="Choisir un statut dans la listesss"
                                                    checkable
                                                    onChange={(_, e) => {
                                                        return onChange(
                                                            e?.value
                                                        );
                                                    }}
                                                    getA11ySelectionMessage={{
                                                        onAdd: (item) =>
                                                            `${item} has been selected.`,
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.status && (
                                            <ErrorMessage
                                                content={errors.status.message}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                                {/* date input  */}
                                {selectedStatus && (
                                    <Flex fill column>
                                        <Controller
                                            name="datefrom"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Veuillez sélectionner une date",
                                            }}
                                            defaultValue={current_date}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <InputStyle
                                                    error={
                                                        errors?.datefrom
                                                            ? true
                                                            : false
                                                    }
                                                    type="date"
                                                    placeholder="Aujourd'hui"
                                                    fluid
                                                    label={
                                                        <LabelDate>
                                                            A partir de
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                {"  "}*
                                                            </span>
                                                        </LabelDate>
                                                    }
                                                    onChange={(e) =>
                                                        onChange(e.target.value)
                                                    }
                                                    value={
                                                        value || current_date
                                                    }
                                                />
                                            )}
                                        />
                                        {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
                                        {errors.datefrom && (
                                            <ErrorMessage
                                                content={
                                                    errors.datefrom.message
                                                }
                                            />
                                        )}
                                        <Flex>
                                            <ContentDialogInterruptionAction
                                                description={selectedStatus}
                                            />
                                        </Flex>
                                    </Flex>
                                )}
                                {!selectedStatus && (
                                    <Flex
                                        styles={{
                                            height: "219px",
                                        }}
                                    />
                                )}
                            </Flex>
                        </form>
                    }
                    header={"Interrompre le Pré-onboarding ?"}
                    headerAction={{
                        icon: <CloseIcon />,
                        title: "Close",
                        onClick: () => {
                            reset();
                            SetOpenDialogInterruptionOnboarding(false);
                        },
                    }}
                    trigger={
                        <Button
                            loading={false}
                            onClick={() =>
                                SetOpenDialogInterruptionOnboarding(true)
                            }
                            iconPosition="after"
                            primary
                            content={"Interrompre le pré-onboarding"}
                            icon={<FolderLink20Regular />}
                        />
                    }
                    footer={
                        <Flex gap="gap.small" hAlign="end">
                            <Button
                                onClick={() => {
                                    reset();
                                    SetOpenDialogInterruptionOnboarding(false);
                                }}
                                content="Annuler"
                            />
                            <Button
                                loading={loading_change_status_onboarding}
                                // disabled={!isValid}
                                // Désactive le bouton si une erreur est présente
                                primary
                                onClick={handleSubmit(onSubmitStopOnboarding)}
                                content="Valider ce statut"
                            />
                        </Flex>
                    }
                />
            </>
        );
    }

    // tab en cours when subtabParams is 2 ( en cours )
    if (subtabParams === "2" || subtabParams === "3") {
        const selectedStatus = watch("status");
        return (
            <>
                {/* renouveler la periode d'essai UI */}
                <Dialog
                    open={openDialogChandePeriodeEssai}
                    positionFixed
                    header="Renouveler la période d'essai"
                    headerAction={{
                        icon: <CloseIcon />,
                        title: "Fermer",
                        onClick: () => {
                            reset();
                            SetOpenDialogChandePeriodeEssai(false);
                        },
                    }}
                    content={
                        <form onSubmit={handleSubmit(renewTrialPeriod)}>
                            <Flex fill column gap="gap.medium">
                                <Label
                                    content={
                                        "A quelle date voulez vous mettre la date de début ?"
                                    }
                                />
                                <Flex fill>
                                    <Controller
                                        name="datefrom"
                                        control={control}
                                        rules={{
                                            required:
                                                "Veuillez sélectionner une date",
                                            validate: (value) => {
                                                const today = new Date()
                                                    .toISOString()
                                                    .split("T")[0];
                                                return (
                                                    value >= today ||
                                                    "La date ne peut pas être antérieure à aujourd'hui"
                                                );
                                            },
                                        }} // Validation du champ date
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Input
                                                error={
                                                    errors?.datefrom
                                                        ? true
                                                        : false
                                                }
                                                type="date"
                                                placeholder="Aujourd'hui"
                                                fluid
                                                label="Date de début *"
                                                value={value}
                                                // defaultValue={startDate} // Liée à la valeur actuelle du champ
                                                min={
                                                    new Date()
                                                        .toISOString()
                                                        .split("T")[0]
                                                }
                                                onChange={(e) =>
                                                    onChange(e.target.value)
                                                }
                                            />
                                        )}
                                    />
                                    {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
                                    {errors.datefrom && (
                                        <ErrorMessage
                                            content={errors.datefrom.message}
                                        />
                                    )}
                                </Flex>
                            </Flex>
                        </form>
                    }
                    trigger={
                        <Button
                            onClick={() =>
                                SetOpenDialogChandePeriodeEssai(true)
                            }
                            iconPosition="after"
                            secondary
                            content={"Renouveler la période d'essai"}
                            icon={<Edit20Regular />}
                        />
                    }
                    footer={
                        <Flex gap="gap.small" hAlign="end">
                            <Button
                                onClick={() => {
                                    reset();
                                    SetOpenDialogChandePeriodeEssai(false);
                                }}
                                content="Annuler"
                            />
                            <Button
                                loading={loadingRenewTrialPeriod}
                                disabled={!isValid}
                                primary
                                content="Renouveler la période d'essai"
                                onClick={() => renewTrialPeriod(user_id)}
                                // onClick={() => changeStartDate(user_id)}
                            />
                        </Flex>
                    }
                />

                <DialogStyle
                    positionFixed
                    open={openDialogInterruptionOnboading}
                    content={
                        <form onSubmit={handleSubmit(onSubmitStopOnboarding)}>
                            <Flex fill column gap="gap.medium">
                                <Flex>
                                    <Label
                                        content={
                                            "Quel autre statut souhaitez-vous attribuer au collaborateur ?"
                                        }
                                    />
                                </Flex>
                                <Flex fill space="between" vAlign="center">
                                    <BadgeFlex>
                                        <BadgeText content={"Onboarding"} />
                                    </BadgeFlex>
                                    <ArrowRightIcon color="gray" />
                                    {/* <Flex fill> */}
                                    <Controller
                                        name="status"
                                        control={control}
                                        rules={{
                                            required:
                                                "Veuillez sélectionner un statut",
                                        }}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <DropdownStyled
                                                fluid
                                                error={
                                                    errors?.status
                                                        ? true
                                                        : false
                                                }
                                                positionFixed
                                                position="below"
                                                items={inputItems}
                                                placeholder="Choisir un statut dans la liste"
                                                checkable
                                                onChange={(_, e) =>
                                                    onChange(e?.value)
                                                }
                                                getA11ySelectionMessage={{
                                                    onAdd: (item) =>
                                                        `${item} has been selected.`,
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.status && (
                                        <ErrorMessage
                                            content={errors.status.message}
                                        />
                                    )}
                                    {/* </Flex> */}
                                </Flex>

                                {/* date input  */}
                                {selectedStatus && (
                                    <Flex fill column>
                                        <Controller
                                            name="datefrom"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Veuillez sélectionner une date",
                                            }} // Validation du champ date
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <InputStyle
                                                    error={
                                                        errors?.datefrom
                                                            ? true
                                                            : false
                                                    }
                                                    type="date"
                                                    placeholder="Aujourd'hui"
                                                    fluid
                                                    label={
                                                        <LabelDate>
                                                            A partir de
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                {"  "}*
                                                            </span>
                                                        </LabelDate>
                                                    }
                                                    onChange={(e) =>
                                                        onChange(e.target.value)
                                                    }
                                                    min={getCurrentDate()}
                                                    value={
                                                        value ||
                                                        getCurrentDate()
                                                    }
                                                />
                                            )}
                                        />
                                        {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
                                        {errors.datefrom && (
                                            <ErrorMessage
                                                content={
                                                    errors.datefrom.message
                                                }
                                            />
                                        )}
                                        <Flex>
                                            <ContentDialogInterruptionAction
                                                description={selectedStatus}
                                            />
                                        </Flex>
                                    </Flex>
                                )}
                                {!selectedStatus && (
                                    <Flex
                                        styles={{
                                            height: "219px",
                                        }}
                                    />
                                )}
                            </Flex>
                        </form>
                    }
                    header={
                        subtabParams === "2"
                            ? "Interrompre l'onboarding"
                            : subtabParams === "3"
                            ? "Cloturer l'onboarding"
                            : null
                    }
                    headerAction={{
                        icon: <CloseIcon />,
                        title: "Close",
                        onClick: () => {
                            reset();
                            SetOpenDialogInterruptionOnboarding(false);
                        },
                    }}
                    trigger={
                        <Button
                            loading={false}
                            onClick={() =>
                                SetOpenDialogInterruptionOnboarding(true)
                            }
                            iconPosition="after"
                            primary
                            content={
                                subtabParams === "2"
                                    ? "Interrompre l'onboarding"
                                    : "Cloturer l'onboarding"
                            }
                            icon={<FolderLink20Regular />}
                        />
                    }
                    footer={
                        <Flex gap="gap.small" hAlign="end">
                            <Button
                                flat
                                onClick={() => {
                                    reset();
                                    SetOpenDialogInterruptionOnboarding(false);
                                }}
                                content="Annuler"
                            />
                            <Button
                                flat
                                loading={loading_change_status_onboarding}
                                // disabled={!isValid}
                                // Désactive le bouton si une erreur est présente
                                primary
                                onClick={handleSubmit(onSubmitStopOnboarding)}
                                content="Valider ce statut"
                            />
                        </Flex>
                    }
                />
            </>
        );
    }

    if (subtabParams === "4") {
        const selectedStatus = watch("status");
        const current_date = getCurrentDate();

        return (
            <>
                {/* Interrumption modale dialog logic  */}
                <DialogStyle
                    // positionFixed
                    open={openDialogInterruptionOnboading}
                    content={
                        <form onSubmit={handleSubmit(onSubmitStopOnboarding)}>
                            <Flex column gap="gap.medium">
                                <Label
                                    content={
                                        "Quel autre statut souhaitez-vous attribuer au collaborateur ?"
                                    }
                                />
                                <Flex space="between" fill vAlign="center">
                                    <BadgeFlex>
                                        <BadgeText content={badgeText} />
                                    </BadgeFlex>
                                    <ArrowRightIcon color="gray" />
                                    <Flex column>
                                        <Controller
                                            name="status"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Veuillez sélectionner un statut",
                                            }} // Ajout de validation
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <DropdownStyled
                                                    error={
                                                        errors?.status
                                                            ? true
                                                            : false
                                                    }
                                                    positionFixed
                                                    items={inputItems}
                                                    placeholder="Choisir un statut dans la listesss"
                                                    checkable
                                                    onChange={(_, e) => {
                                                        return onChange(
                                                            e?.value
                                                        );
                                                    }}
                                                    getA11ySelectionMessage={{
                                                        onAdd: (item) =>
                                                            `${item} has been selected.`,
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.status && (
                                            <ErrorMessage
                                                content={errors.status.message}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                                {/* date input  */}
                                {selectedStatus && (
                                    <Flex fill column>
                                        <Controller
                                            name="datefrom"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Veuillez sélectionner une date",
                                            }}
                                            defaultValue={current_date}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <InputStyle
                                                    error={
                                                        errors?.datefrom
                                                            ? true
                                                            : false
                                                    }
                                                    type="date"
                                                    placeholder="Aujourd'hui"
                                                    fluid
                                                    label={
                                                        <LabelDate>
                                                            A partir de
                                                            <span
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                {"  "}*
                                                            </span>
                                                        </LabelDate>
                                                    }
                                                    onChange={(e) =>
                                                        onChange(e.target.value)
                                                    }
                                                    value={
                                                        value || current_date
                                                    }
                                                />
                                            )}
                                        />
                                        {/* Affichage de l'erreur si aucune date n'est sélectionnée */}
                                        {errors.datefrom && (
                                            <ErrorMessage
                                                content={
                                                    errors.datefrom.message
                                                }
                                            />
                                        )}
                                        <Flex>
                                            <ContentDialogInterruptionAction
                                                description={selectedStatus}
                                            />
                                        </Flex>
                                    </Flex>
                                )}
                                {!selectedStatus && (
                                    <Flex
                                        styles={{
                                            height: "219px",
                                        }}
                                    />
                                )}
                            </Flex>
                        </form>
                    }
                    header={"Interrompre le suivi "}
                    headerAction={{
                        icon: <CloseIcon />,
                        title: "Close",
                        onClick: () => {
                            reset();
                            SetOpenDialogInterruptionOnboarding(false);
                        },
                    }}
                    trigger={
                        <Button
                            loading={false}
                            onClick={() =>
                                SetOpenDialogInterruptionOnboarding(true)
                            }
                            iconPosition="after"
                            primary
                            content={"Interrompre le suivi"}
                            icon={<FolderLink20Regular />}
                        />
                    }
                    footer={
                        <Flex gap="gap.small" hAlign="end">
                            <Button
                                onClick={() => {
                                    reset();
                                    SetOpenDialogInterruptionOnboarding(false);
                                }}
                                content="Annuler"
                            />
                            <Button
                                loading={loading_change_status_onboarding}
                                // disabled={!isValid}
                                // Désactive le bouton si une erreur est présente
                                primary
                                onClick={handleSubmit(onSubmitStopOnboarding)}
                                content="Valider ce statut"
                            />
                        </Flex>
                    }
                />
            </>
        );
    }
}

const InputStyle = styled(Input)`
    width: 196px;
    appearance: none;

    /* Masque l'icône du calendrier pour les navigateurs WebKit (Chrome, Safari) */
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
`;

const LabelDate = styled(Text)`
    color: #616161;
`;
const Label = styled(Text)`
    color: #616161;
`;
const DialogStyle = styled(Dialog)`
    width: 507px;
    height: 445px;
    overflow-y: hidden;
`;
export const BadgeFlex = styled(Flex)`
    border-radius: 4px;
    background: #e8ebfa;
    padding: 4px 8px;
`;
export const BadgeText = styled(Text)`
    color: #4f52b2;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
`;

const DropdownStyled = styled(Dropdown)`
    .ui-dropdown__container {
        width: 250px;
    }
    .ui-dropdown__items-list {
        width: 250px;
    }
    .ui-dropdown__item {
        padding-block: 8px;
    }
`;
