import React from "react";
import { Flex } from "@fluentui/react-northstar";
import PlanningCard from "./PlanningCard";
import styled from "styled-components";

/**
 * @typedef {Object} TimetableEntry
 * @property {number} am - Nombre d'activités prévues le matin.
 * @property {number} pm - Nombre d'activités prévues l'après-midi.
 */

/**
 * @typedef {Object} PlanningProps
 * @property {"reading" | "writing"} mode - Le mode d'affichage.
 */

/**
 * Composant Planning
 * Affiche un contenu basé sur le mode fourni.
 *
 * @param {PlanningProps} props - Les propriétés du composant.
 * @param {TimetableEntry[]} timetable - Le calendrier d'activités pour la semaine.
 * @returns {JSX.Element} Le rendu du composant Planning.
 */
const Planning = ({ mode = "reading", timetable = [] }) => {
    return (
        <ContainerPlanning gap="gap.large">
            {timetable.map((slots, index) => {
                return (
                    <PlanningCard
                        mode={mode}
                        slots={slots}
                        indexPosition={index}
                        key={index}
                    />
                );
            })}
        </ContainerPlanning>
    );
};

export default Planning;

const ContainerPlanning = styled(Flex)`
    overflow-x: scroll;
`;
