import { useMutation, useQuery } from "react-query";
import { QUERY_KEY } from "../constants";
import { _api_get_timetable, _api_post_timetable } from "../api";
import { useParams } from "react-router-dom";
import {
    ACTIONS_TIMETABLE,
    useStoreTimetable,
} from "../reducers/timetable.reducer";
import useToast from "../../../hooks/useToast";
import moment from "moment";
import { _util_remove_seconds_from_json, comparerJSON } from "../utils";
import { useCallback, useState } from "react";
import { queryClient } from "../../..";

const useTimetable = () => {
    // * PARAMS
    // * PARAMS
    // * PARAMS
    const { id_employee } = useParams();

    // * REDUCERS & STATS & context
    // * REDUCERS & STATS & context
    // * REDUCERS & STATS & context
    const { state, dispatch } = useStoreTimetable();

    // pour sauvegarder la premiere réponse du back afin de disable/enable le button enregistrer
    // pour aussi le bouton reintialiser le formulaire
    const [initialData, setInitialData] = useState(null);
    const [toast, setToast] = useToast();

    // * QUERIES & MUTATIONS
    // * QUERIES & MUTATIONS
    // * QUERIES & MUTATIONS
    const { data, isLoading, isFetching } = useQuery(
        [QUERY_KEY + "::timetable_rh"],
        () => {
            return _api_get_timetable({
                id_user: id_employee,
            });
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: (response) => {
                // remove seconds
                const formated_data = {
                    ...response,
                    timetable: _util_remove_seconds_from_json(
                        response?.timetable
                    ),
                };
                // save initial data here
                setInitialData(formated_data);

                return dispatch({
                    type: ACTIONS_TIMETABLE.init_reducer,
                    payload: formated_data,
                });
            },
        }
    );

    const { mutate: mutate_updating, isLoading: is_updating } = useMutation(
        (data) => _api_post_timetable(data)
    );

    // * FONCTION
    // * FONCTION
    // * FONCTION
    const onSubmitForm = (e, toggle_activate_time_reporting = null) => {
        e.preventDefault();

        mutate_updating(
            {
                data: {
                    ...state,
                    time_reporting_activated:
                        toggle_activate_time_reporting !== null
                            ? false
                            : state.time_reporting_activated,
                },
                id_user: id_employee,
            },
            {
                onSuccess: (data) => {
                    if (!data?.success) {
                        return setToast((toast) => ({
                            ...toast,
                            visible: true,
                            content: data.message,
                            level: "danger",
                        }));
                    } else {
                        setToast((toast) => ({
                            ...toast,
                            visible: true,
                            content: "Modifications enregistrées",
                            level: "success",
                        }));
                        // refetch data
                        queryClient.refetchQueries(
                            QUERY_KEY + "::timetable_rh"
                        );
                        return;
                    }
                },
            }
        );
    };

    // * re-init form
    const resetForm = () => {
        dispatch({
            type: ACTIONS_TIMETABLE.init_reducer,
            payload: initialData,
        });
        return;
    };

    // * RETURN
    // * RETURN
    // * RETURN
    return {
        query_timetable: {
            data,
            isLoading,
            isFetching,
        },
        reducer: {
            state,
            dispatch,
        },
        mutations: {
            update_timetable: {
                isLoading: is_updating,
            },
        },
        forms: {
            isDirty: !comparerJSON(state, initialData),
            resetForm,
            on_submit_save_modification: onSubmitForm,
        },
        alert: {
            toast,
            setToast,
        },
    };
};

export default useTimetable;
