import React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { getMyInformations } from "../../api/user";
import { useAuthDetailStore } from "../../stores/useAuthStore";
import withGuestOnly from "../../HOC/withGuestOnly";

const CallbackMs = () => {
    const setDetailUser = useAuthDetailStore((state) => state.setDetailUser);
    const [searchParams] = useSearchParams();
    const success = searchParams.get("success");
    const navigate = useNavigate();
    if (success === "true") {
        // user connected successfully, then get /me
        getMyInformations()
            .then((data) => {
                if (data?.success) {
                    setDetailUser(data.user);
                    return navigate("/dashboard?refer=msLogin");
                }
            })
            .catch((error) => {
                console.warn({ error });
                localStorage.clear();
                return navigate("/login?error=slash_me_error");
            });
    } else {
        const error = searchParams.get("msg");
        return <Navigate to={"/login?error=" + error} />;
    }
};

export default withGuestOnly(CallbackMs);
