import { CloseIcon, Flex } from "@fluentui/react-northstar";
import React, { useState } from "react";
import {
    ButtonChoosePlanning,
    TextPlanning,
} from "../sections/body/TabTimetable/index.style";
import DayCard from "../DayCard/DayCard";
import { getDaynameByIndex } from "../../../addEmployees/utils";
import { DialogStyle } from "./choose_planning.style";
import TimetableTemplates from "./TimetableTemplates";
import {
    ACTIONS_TIMETABLE,
    useStoreTimetable,
} from "../../reducers/timetable.reducer";
import { useMutation } from "react-query";
import { _api_put_set_planning } from "../../api";
import { useParams } from "react-router-dom";
import { _util_remove_seconds_from_json } from "../../utils";

const Planning = ({
    timetable = [],
    timetable_templates = [],
    onSuccessSavePlanning = () => null,
}) => {
    const { id_employee } = useParams();

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [open, setOpenDialog] = useState(null);

    const { dispatch } = useStoreTimetable();

    // enregistrer le planning choisi
    const { mutate, isLoading } = useMutation(({ id_user, planning_id }) =>
        _api_put_set_planning({ id_user, planning_id })
    );

    // FUNCTION
    // FUNCTION
    // FUNCTION
    const openDialog = () => {
        return setOpenDialog(true);
    };

    const closeDialog = () => {
        return setOpenDialog(false);
    };

    const handleClickSave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        mutate(
            {
                id_user: id_employee,
                planning_id: selectedPlan,
            },
            {
                onSuccess: (response) => {
                    if (response.success) {
                        dispatch({
                            type: ACTIONS_TIMETABLE.set_timetables,
                            payload: _util_remove_seconds_from_json(
                                response?.timetables
                            ),
                        });
                        onSuccessSavePlanning();
                        closeDialog();
                        return;
                    }
                },
            }
        );
        return;
    };

    return (
        <Flex column gap="gap.medium">
            {/* header */}
            <Flex vAlign="center" gap="gap.large">
                <TextPlanning content="planning" />
                {/* choose planning */}
                {/* choose planning */}
                <DialogStyle
                    open={open}
                    header="Choix du planning"
                    headerAction={{
                        icon: <CloseIcon />,
                        onClick: () => closeDialog(),
                    }}
                    content={
                        <TimetableTemplates
                            timetable_templates={timetable_templates}
                            managePlan={{
                                selectedPlan,
                                setSelectedPlan,
                            }}
                        />
                    }
                    cancelButton={{
                        content: "Annuler",
                        flat: true,
                        fluid: true,
                        onClick: () => closeDialog(),
                    }}
                    confirmButton={{
                        content: "Appliquer",
                        flat: true,
                        fluid: true,
                        loading: isLoading,
                        onClick: (e) => handleClickSave(e),
                    }}
                    footer={{
                        children: (Component, props) => {
                            const { styles, ...rest } = props;
                            return (
                                <Component
                                    {...rest}
                                    styles={{
                                        "& .ui-flex": {
                                            flexWrap: "nowrap!important",
                                        },
                                    }}
                                />
                            );
                        },
                    }}
                    onCancel={closeDialog}
                    trigger={
                        <ButtonChoosePlanning
                            content="Choisir un planning"
                            primary
                            text
                            onClick={openDialog}
                        />
                    }
                />
            </Flex>
            {/* body */}
            <Flex gap="gap.large">
                {timetable.map((tt) => {
                    const day_name = getDaynameByIndex(tt?.day_idx ?? 0, "ddd");
                    return (
                        <DayCard
                            key={tt.day_idx}
                            day_name={day_name}
                            slots={tt.shifts}
                            day_index={tt.day_idx}
                            duration_str={tt.duration_str}
                        />
                    );
                })}
            </Flex>
        </Flex>
    );
};

export default Planning;
