import {
    Checkbox,
    Dropdown,
    Flex,
    InfoIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { ACTIONS_TIMETABLE } from "../../reducers/timetable.reducer";

const inputItems = new Array(7).fill(0).map((n, i) => i * 5 + " min");
const inputItemsBreak = new Array(7).fill(0).map((n, i) => i * 5 + 30 + " min");

const AdditionalOptions = ({ state, dispatch }) => {
    const auto_correct_hours_activated = state?.auto_correct_hours_activated;
    const break_time_alert_activated = state?.break_time_alert_activated;

    const auto_correct_hours_before_minutes =
        state.auto_correct_hours_before_minutes + " min";
    const auto_correct_hours_after_minutes =
        state.auto_correct_hours_after_minutes + " min";

    return (
        <Flex column gap="gap.small">
            {/* header */}
            <Flex>
                <TextOptions content="Options supplémentaires" />
            </Flex>
            {/* body */}
            <Flex gap="gap.small">
                <Flex column gap="gap.smaller">
                    <Checkbox
                        label="Autocorrection Arrivée & départ"
                        toggle
                        checked={auto_correct_hours_activated}
                        onChange={(_, i) => {
                            return dispatch({
                                type: ACTIONS_TIMETABLE.toggle_auto_correct_hours_activated,
                                payload: i.checked,
                            });
                        }}
                    />
                    {auto_correct_hours_activated && (
                        <Flex gap="gap.smaller" vAlign="center">
                            <div style={{ width: "96px" }}>
                                <Dropdown
                                    fluid
                                    items={inputItems}
                                    checkable
                                    defaultValue={
                                        auto_correct_hours_before_minutes
                                    }
                                    getA11ySelectionMessage={{
                                        onAdd: (item) =>
                                            `${item} has been selected.`,
                                    }}
                                    onChange={(_, i) => {
                                        const number = parseInt(i.value);
                                        return dispatch({
                                            type: ACTIONS_TIMETABLE.toggle_auto_correct_hours_before_minutes,
                                            payload: number,
                                        });
                                    }}
                                />
                            </div>
                            <Flex>
                                <Text content="avant et" />
                            </Flex>
                            <div style={{ width: "96px" }}>
                                <Dropdown
                                    fluid
                                    items={inputItems}
                                    checkable
                                    defaultValue={
                                        auto_correct_hours_after_minutes
                                    }
                                    getA11ySelectionMessage={{
                                        onAdd: (item) =>
                                            `${item} has been selected.`,
                                    }}
                                    onChange={(_, i) => {
                                        const number = parseInt(i.value);
                                        return dispatch({
                                            type: ACTIONS_TIMETABLE.toggle_auto_correct_hours_after_minutes,
                                            payload: number,
                                        });
                                    }}
                                />
                            </div>
                            <Flex>
                                <Text content="après" />
                            </Flex>
                        </Flex>
                    )}
                </Flex>
                <Flex column gap="gap.smaller">
                    <Checkbox
                        label="Alerte Pause repas"
                        toggle
                        checked={break_time_alert_activated}
                        onChange={(_, i) => {
                            return dispatch({
                                type: ACTIONS_TIMETABLE.toggle_break_time_alert_activated,
                                payload: i.checked,
                            });
                        }}
                    />
                    {break_time_alert_activated && (
                        <Flex gap="gap.smaller" vAlign="center">
                            <Flex>
                                <Text content="Pause minimale :" />
                            </Flex>
                            <div style={{ width: "96px" }}>
                                <Dropdown
                                    fluid
                                    items={inputItemsBreak}
                                    checkable
                                    defaultValue={inputItemsBreak[0]}
                                    getA11ySelectionMessage={{
                                        onAdd: (item) =>
                                            `${item} has been selected.`,
                                    }}
                                    onChange={(_, i) => {
                                        const number = parseInt(i.value);
                                        return dispatch({
                                            type: ACTIONS_TIMETABLE.update_break_time_duration_minutes,
                                            payload: number,
                                        });
                                    }}
                                />
                            </div>
                        </Flex>
                    )}
                </Flex>
                <Flex vAlign="start">
                    <Flex vAlign="center">
                        <Checkbox
                            label="Activer la géolocalisation"
                            checked={state?.geolocation_activated}
                            onChange={(_, i) => {
                                return dispatch({
                                    type: ACTIONS_TIMETABLE.toggle_geolocation_activated,
                                    payload: i.checked,
                                });
                            }}
                        />
                        <Tooltip
                            align="end"
                            position="below"
                            autoSize="always"
                            pointing
                            subtle={false}
                            unstable_pinned
                            content={
                                "L'utilisateur doit accepter d'être géolocalisé"
                            }
                            trigger={<InfoIcon outline />}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default AdditionalOptions;

const DropdownMinutes = styled(Dropdown)`
    max-width: 96px;
`;

const TextOptions = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;
