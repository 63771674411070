import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DELEGATION_ROLES, QUERY_KEY_DELEGATION } from "../constants";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { _getAllUsers } from "../../../api/user";
import { format_employees_list_dropdown } from "../functions";
import {
    _api_assign_delegation,
    _api_delete_delegation,
    _api_get_detail_per_type,
} from "../api";
import moment from "moment";
import { useState } from "react";
import useToast from "../../../hooks/useToast";
import { ROUTES } from "../../../constants/routes";

const useLogicDelegationForm = () => {
    // * HOOKS
    // * HOOKS
    // * HOOKS
    const navigate = useNavigate();
    const params = useParams();

    const params_type_form = params?.type_form;

    // * STATES
    // * STATES
    // * STATES
    const [toast, setToast] = useToast();
    const [isUpdatingDelegation, setIsUpdatingDelegation] = useState(null);

    const title = DELEGATION_ROLES[params_type_form]?.title;

    // * USEFORMS
    // * USEFORMS
    // * USEFORMS
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            toggle_delegation: false,
            delegated_to: null,
            start_date: null,
            end_date: null,
            checked_end_date: false,
            is_updating_delegation: null,
        },
    });
    // * QUERIES && MUTATIONS
    // * QUERIES && MUTATIONS
    // * QUERIES && MUTATIONS
    // fetch employees list
    const { data: employees_list, isLoading: is_loading_employees_list } =
        useQuery(
            [QUERY_KEY_DELEGATION + "::get_list_employees"],
            () => {
                return _getAllUsers();
            },
            {
                refetchOnWindowFocus: false,
            }
        );
    // fetch detail by type ["time", "leave", "expense", "accounting"]
    const {
        data: detail_per_type,
        isLoading: is_loading_detail_per_type,
        refetch: refetch_detail_per_type,
    } = useQuery(
        [QUERY_KEY_DELEGATION + "::get_detail_per_type"],
        () => {
            return _api_get_detail_per_type({ type: params_type_form });
        },
        {
            refetchOnWindowFocus: false,
            enabled: employees_list !== undefined,
            onSuccess: (data) => {
                if (data?.success) {
                    const records = data.records;
                    methods.setValue("is_from_back", true);
                    methods.setValue("toggle_delegation", records.collaborator);
                    methods.setValue(
                        "start_date",
                        moment(records.start).format("YYYY-MM-DD")
                    );
                    methods.setValue("checked_end_date", records.end);
                    methods.setValue(
                        "end_date",
                        records.end
                            ? moment(records.end).format("YYYY-MM-DD")
                            : null
                    );

                    // trouver le délégué s'il existe
                    if (records?.collaborator) {
                        const find_employee = employees_list.find(
                            (user) =>
                                user.username === records.collaborator?.email
                        );

                        const keys = Object.keys(find_employee);

                        if (keys.length > 0) {
                            // on a trouvé quelqu'un
                            const json_find = {
                                header: find_employee.name,
                                image: find_employee.avatar,
                                content: find_employee.job_title,
                                email: find_employee.username,
                            };
                            methods.setValue("delegated_to", json_find);
                        }
                    }

                    return;
                }
            },
        }
    );
    // interumpt the delegation
    const {
        mutate: mutate_interrupt_delegation,
        isLoading: is_loading_interrupt_delegation,
    } = useMutation(
        ({ id_delegation }) => {
            return _api_delete_delegation({ id_delegation });
        },
        {
            onSuccess: () => {
                // reset the form
                methods.reset();
                // refetch the detail type
                refetch_detail_per_type();
                // show alert
                setToast({
                    visible: true,
                    content: "Délégation interrompue",
                    level: "success",
                });
                return;
            },
        }
    );
    // assign delegation to User
    const {
        mutate: mutate_assign_delegation,
        isLoading: is_loading_assign_delegation,
    } = useMutation(
        (data) => {
            return _api_assign_delegation(data);
        },
        {
            onSuccess: (data) => {
                if (data.success) {
                    // navigate to home + show alert success
                    navigate(
                        `${ROUTES.delegation_roles.home}?alert=assign_success`
                    );
                } else {
                    // show error message
                    setToast({
                        visible: true,
                        level: "danger",
                        content: data.error,
                    });
                }
                return;
            },
        }
    );

    // * FUNCTIONS
    // * FUNCTIONS
    // * FUNCTIONS
    // Envoyer le formulaire.
    const onSubmit = (data) => {
        const checked_end_date = methods.getValues("checked_end_date");
        mutate_assign_delegation({
            collaborator_email: data.delegated_to.email,
            start_date: data.start_date,
            end_date: checked_end_date ? data.end_date : null,
            type: params_type_form,
        });
        return;
    };

    // update state isUpdatingDelegation - sert a la modification du formulaire deja assigné
    const updatingFormData = (boolean) => {
        return setIsUpdatingDelegation(boolean);
    };

    // retour en arriere
    const goBack = () => {
        return navigate(-1);
    };

    // interrupt delegation function
    const interruptDelegation = () => {
        mutate_interrupt_delegation(
            {
                id_delegation: detail_per_type?.records?.id,
            },
            {
                onSuccess: () => {
                    // show alert
                    toast.setToast({
                        visible: true,
                        content: "Délégation interrompue",
                        level: "success",
                    });
                },
            }
        );
        return;
    };

    // * ASSIGNATION
    // * ASSIGNATION
    // * ASSIGNATION
    // format list employees for dropdown LIST
    const formated_employees_list =
        format_employees_list_dropdown(employees_list);

    // search defaut searchquery + default user selected.
    const index = formated_employees_list.findIndex(
        (user) => user?.email === methods.getValues("delegated_to")?.email
    );
    const findUserByIndex =
        index !== -1 ? formated_employees_list.at(index) : null;
    const defaultSearchQuery = index !== -1 ? findUserByIndex?.header : "";

    // * RETURN
    // * RETURN
    // * RETURN
    return {
        header: {
            title, // title du subheader, avec le retour en arriere
        },
        form: {
            methods,
            watch: methods.watch,
            onSubmit,
            defaultSearchQuery,
            defaultUserByIndex: findUserByIndex,
        },
        employees_list: {
            data: formated_employees_list,
            isLoading: is_loading_employees_list,
        },
        queryDetailType: {
            isLoading: is_loading_detail_per_type,
            data: detail_per_type,
        },
        is_updating_delegation: isUpdatingDelegation, // utilisé lorsqu'il clique sur le modifier les informations
        functions: {
            updatingFormData, // appel lorsqu'on clique sur
            interruptDelegation,
        },
        mutation_interrupt: {
            mutate: mutate_interrupt_delegation,
            isLoading: is_loading_interrupt_delegation,
        },
        mutation_assignation: {
            mutate: mutate_assign_delegation,
            isLoading: is_loading_assign_delegation,
        },
        today: moment().format("YYYY-MM-DD"),
        toast: {
            ...toast,
            setToast,
        },
        navigation: {
            goBack,
        },
    };
};

export default useLogicDelegationForm;
