import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../../../common/styles";
import {
    AddIcon,
    Avatar,
    Button,
    ContactGroupIcon,
    Divider,
    Flex,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import FooterActions from "./FooterActions";
import StatusBadge from "./StatusBadge";
import HeaderDates from "./HeaderDates";
import MyTasks from "../../DashboardTasks/MyTasks";
import { useQuery } from "react-query";
import { _startPreonboarding } from "../api";
import { TABVIEW } from "../constant";
import Toast from "../../../components/Alerts";
import {
    _get_collaborator_tasks,
    _get_collaborator_tasks_onboarding,
} from "../../../api/tasks";
import HeaderTasksList from "./HeaderTasksList/HeaderTasksList";
import DialogChooseAttributionTask from "../../DashboardTasks/TasksModels/DialogChooseAttributionTask";
import { DialogStyle } from "../../DashboardTasks/style";
import DialogUseModalTask from "../../DashboardTasks/TasksModels/DialogUseModalTask";
import DialogAddIndividualTask from "../../DashboardTasks/TasksModels/DialogAddIndividualTask";
import { BuildingBankFilled } from "@fluentui/react-icons";

export default function CollaboratorTasks({
    dataUserDetail,
    activeTab,
    refetchDataUserDetail,
    activeTabFromLocation,
}) {
    const [openAttributeTask, setOpenAttributeTask] = useState(false); // pour attribuer une tache
    const [openUseModalTask, setOpenUseModalTask] = useState(false); // pour afficher les modeles de taches
    const [openAddIndividualTask, setOpenAddIndividualTask] = useState(false); // pour afficher la modale d'une seule tâche

    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    const updateInformation = () => {
        return;
    };

    const changeStartDate = () => {
        alert(" Start date changed");
    };

    const userChooseType = (type) => {
        setOpenAttributeTask(false);
        switch (type) {
            case "USE_MODAL":
                setOpenUseModalTask(true);
                break;
            case "CREATE_TASK_FROM_ZERO":
                setOpenAddIndividualTask(true);
                break;
        }
    };

    const {
        data: allTasks,
        isLoading,
        refetch: refetchGetAllTasks,
    } = useQuery(
        ["getAllTasksOfEmployee", dataUserDetail?.id],
        (args) => {
            const id_user_selected = args.queryKey?.at(1);
            return _get_collaborator_tasks_onboarding({
                id_user: id_user_selected,
            });
        },
        {
            enabled: dataUserDetail?.id !== undefined,
        }
    );

    console.log({ dataUserDetail });

    return (
        <Flex column>
            <Toast
                visible={toastConfig?.visible}
                content={toastConfig?.content}
                level={toastConfig?.level}
                onStatusChange={() => {
                    return setToastConfig({
                        visible: false,
                        content: "",
                        level: "warning",
                    });
                }}
            />

            {/* UTILISER UN MODELE DE TACHE */}
            <DialogUseModalTask
                setShowAlert={() => {
                    return setToastConfig({
                        visible: true,
                        content: "Tâches bien ajoutées",
                        level: "success",
                    });
                }}
                open={openUseModalTask}
                setOpen={setOpenUseModalTask}
            />
            {/* UTILISER UNE MODAL INDIVIDUAL TASK */}
            <DialogAddIndividualTask
                setShowAlert={() => {
                    return setToastConfig({
                        visible: true,
                        content: "Tâche bien ajoutée",
                        level: "success",
                    });
                }}
                open={openAddIndividualTask}
                setOpen={setOpenAddIndividualTask}
            />
            <Body column>
                <ContainerHeader vAlign="center" fill space="between">
                    <Flex gap="gap.small" vAlign="center">
                        <Avatar size="larger" name={dataUserDetail?.name} />
                        <Flex gap="gap.small" column>
                            <NameText content={dataUserDetail?.name} />
                            <Flex gap="gap.medium">
                                <AgencyDepartementFlex
                                    vAlign="center"
                                    // key={index}
                                    // gap="gap.small"
                                >
                                    <BuildingBankFilled />
                                    <Divider vertical size={1} />
                                    <DepartementName
                                        content={dataUserDetail?.agency?.name}
                                    />
                                </AgencyDepartementFlex>
                                <AgencyDepartementFlex vAlign="center">
                                    <ContactGroupIcon />
                                    <Divider vertical size={1} />
                                    <DepartementName
                                        content={
                                            dataUserDetail?.department?.name
                                        }
                                    />
                                </AgencyDepartementFlex>
                            </Flex>
                        </Flex>
                    </Flex>
                    {/* status badge */}
                    <Flex>
                        <StatusBadge
                            status={dataUserDetail?.onboarding_status_code}
                            status_text={dataUserDetail?.onboarding_status_text}
                        />
                    </Flex>
                    {/* début de contrat et fin de période d'essai */}
                    <Flex>
                        <HeaderDates
                            contractStartDate={dataUserDetail?.start_date}
                            trialPeriodEndDate={
                                dataUserDetail?.probationary_period_end_date ||
                                "Non renseigné"
                            }
                        />
                    </Flex>
                </ContainerHeader>
                <TableContainer>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Flex column gap="gap.large" fill>
                            <Flex gap="gap.medium" vAlign="center">
                                {/* header counter */}
                                <HeaderTasksList
                                    count={allTasks?.tasks?.length}
                                />
                                {/* divider */}
                                <Flex
                                    styles={{
                                        height: "55px ",
                                    }}
                                >
                                    <Divider size={1} vertical />
                                </Flex>
                                {/* ADD TASK */}
                                <DialogStyle
                                    header="Attribuer une tâche"
                                    open={openAttributeTask}
                                    onCancel={() => setOpenAttributeTask(false)}
                                    content={
                                        <DialogChooseAttributionTask
                                            cb={userChooseType}
                                        />
                                    }
                                    trigger={
                                        <Button
                                            icon={<AddIcon />}
                                            text
                                            primary
                                            content="Ajouter une tâche"
                                            onClick={() =>
                                                setOpenAttributeTask(true)
                                            }
                                        />
                                    }
                                />
                            </Flex>
                            {/* LES TACHES */}
                            <MyTasks
                                tasks={allTasks?.tasks}
                                isPageOnboarding={true}
                            />
                        </Flex>
                    )}
                </TableContainer>
            </Body>
            <ContainerFooter>
                <FooterActions
                    activeTabFromLocation={activeTabFromLocation}
                    startDate={dataUserDetail?.start_date}
                    setToastConfig={setToastConfig}
                    refetchDataUserDetail={refetchDataUserDetail}
                    // startPreOnboarding={startPreOnboarding}
                    updateInformation={updateInformation}
                    // interruptPreOnboarding={interruptPreOnboarding}
                    changeStartDate={changeStartDate}
                    TabView={TABVIEW}
                    user_id={dataUserDetail?.id}
                    activeTab={Number(activeTab)}
                />
            </ContainerFooter>
        </Flex>
    );
}

const Body = styled(Container)`
    padding: 24px 16px;
    border-radius: 16px;
    background: #fff;
    /* margin-top: 24px; */
    margin-bottom: 32px;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
`;
const ContainerFooter = styled(Container)`
    border-bottom: 1px solid #d1d1d1;
    background: #fff;
    box-shadow: 0px -1.2px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px -6.4px 14.4px 0px rgba(0, 0, 0, 0.13);
    padding: 16px 24px;
    justify-content: flex-end;
    gap: 16px;
`;

const AgencyDepartementFlex = styled(Flex)`
    border-radius: 4px;
    background: #ebebeb;
    padding: 4px 8px;
`;

const TableContainer = styled(Container)`
    border-top: 4px solid #9299f7;
`;
const ContainerHeader = styled(Container)`
    margin-bottom: 42px;
`;
const DepartementName = styled(Text)`
    color: #424242;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const NameText = styled(Text)`
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
