import React from "react";
import { Flex } from "@fluentui/react-northstar";

import CustomBreadCrumb from "../../components/breadcrumb";

import Header from "./components/Header";
import Content from "./components/content";

import { BREADCRUMB_DOCUMENTS, I18N_NS_DOCUMENTS } from "./constants";
import { BREADCRUMB_HUMBERGER_GLOBAL } from "../../constants/routes/drawer";
import withAuth from "../../HOC/withAuth";

const HrDocuments = () => {
    return (
        <Flex column style={{ backgroundColor: "#f5f5f5", height: "100vh" }}>
            <CustomBreadCrumb
                humberger={BREADCRUMB_HUMBERGER_GLOBAL}
                navList={BREADCRUMB_DOCUMENTS}
                i18n_ns={I18N_NS_DOCUMENTS}
            />
            <Flex column gap="gap.medium">
                <Header />
                <Content />
            </Flex>
        </Flex>
    );
};

export default withAuth(HrDocuments);
