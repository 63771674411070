import { Dialog } from "@fluentui/react-northstar";
import styled from "styled-components/macro";

export const DialogStyled = styled(Dialog)`
    width: clamp(350px, 50%, 414px);
    /* outline: 4px dashed blue; */
    /* margin-inline: 32px; */
    border-radius: 4px;
    background: var(--light-theme-rest-background-default-background, #fff);

    /* shadow-32 */
    box-shadow: 0px 12.8px 28.8px 0px rgba(0, 0, 0, 0.22),
        0px 2.4px 7.4px 0px rgba(0, 0, 0, 0.18);
`;
