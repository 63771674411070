import {
    ArrowRightIcon,
    Button,
    Divider,
    Flex,
    Image,
    Input,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import EmailConnection from "./EmailConnection";
import PassewordPage from "./PasswordPage";
import { useState } from "react";

const DynamicSide = () => {
    const [state, setstate] = useState(false);
    return (
        <Content>
            {/* {state ? (
                <EmailConnection setState={() => setstate(!state)} />
            ) : (
                <PassewordPage setState={() => setstate(!state)} />
                )} */}
            <PassewordPage setState={() => setstate(!state)} />
        </Content>
    );
};

export default DynamicSide;

const Content = styled(Flex)`
    width: 315px;
    height: 584px;
`;
