import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";
import { DEVICE } from "../../../../constants/responsive";

export const Container = styled(Flex)`
    max-width: 1363px;
    width: 100%;
    /* min-height: 723px; */

    background: #fff;
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
    margin-block: 26px;
    @media ${DEVICE.mobileS} {
        padding: 24px 8px;
    }
    @media ${DEVICE.laptop} {
        padding: 24px 16px;
    }
`;
export const Title = styled(Text)`
    color: #242424;
    font-size: clamp(14px, 2vw, 18px);
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`;
export const HoursPlanning = styled(Text)`
    color: #424242;
    font-size: clamp(14px, 50%, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
export const ProgressBar = styled(Flex)`
    width: 340px;
    height: 22px;
    background: #e8ebfa;
    ::after {
        content: "";
        width: ${({ progress }) => progress + "%"};
        background: var(--color-primary);
    }
`;

export const WrappedHeader = styled(Flex)`
    /* border: 3px dashed cyan; */
    flex-direction: row;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
`;

export const MainHeader = styled(Flex)`
    @media ${DEVICE.mobileS} {
        justify-content: space-between;
    }
    @media ${DEVICE.tablet} {
        justify-content: flex-start;
    }
`;
