import moment from "moment";

export const ACTIONS_TIMECLOCK = {
    open_menu: "open_menu",
    set_timer: "set_timer",
    increment_timer: "increment_timer",
    set_coordinates: "set_coordinates",
};

export const STATE_TIMECLOCK = {
    start: "start",
    idle: "idle",
    stop: "stop",
};

export const DESIGN_TIMECLOCK = {
    openMenu: false,
};

// * FUNCTIONS
// * FUNCTIONS
// * FUNCTIONS
const format_timer_count = (timer_count) => {
    const duration = moment.duration(timer_count); // Crée une durée basée sur le temps écoulé en millisecondes
    const hours = Math.floor(duration.asHours()); // Convertir en heures
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours.toString().padStart(2, "0")} : ${minutes
        .toString()
        .padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
};

export const reducer = (state, action) => {
    switch (action.type) {
        // ouvrir/fermer le menu
        case ACTIONS_TIMECLOCK.open_menu: {
            return { ...state, open_menu: !state.open_menu };
        }
        // incrementer le timer
        case ACTIONS_TIMECLOCK.increment_timer: {
            return {
                ...state,
                timer_count: state.timer_count + 1000,
                format_timer_count: format_timer_count(state.timer_count),
            };
        }
        // commencer le timer a partir d'une heure donnee
        case ACTIONS_TIMECLOCK.set_timer: {
            const timer =
                action.payload.timer_count < 0 ? 0 : action.payload.timer_count;
            return {
                ...state,
                timer_count: timer,
                format_timer_count: format_timer_count(timer),
                is_running:
                    !action.payload.is_paused &&
                    !action.payload.is_stopped &&
                    action.payload.timer_count,
            };
        }
        case ACTIONS_TIMECLOCK.set_coordinates: {
            return {
                ...state,
                localisation: {
                    ...state.coordinates,
                    latitude: action.payload.latitude,
                    longitude: action.payload.longitude,
                    error: action.payload.error,
                    error_message: action.payload.error_message,
                },
            };
        }
        default:
            return state;
    }
};
