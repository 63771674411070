import {
    Alert,
    Attachment,
    Avatar,
    AvatarImage,
    Button,
    ContactCardIcon,
    Divider,
    FilesPdfColoredIcon,
    Flex,
    Image,
    OpenOutsideIcon,
    PowerPointColorIcon,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { Container, ContainerFluid } from "../../../common/styles";
import styled, { keyframes } from "styled-components/macro";
import WelcomeImg from "../img/bgBulle.svg";
import { Copy24Regular } from "@fluentui/react-icons";
import Footer from "./Footer";
import DocumentComponent from "./DocumentComponent";
import { _preonboarding } from "../api";
import { useQuery } from "react-query";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";

import { DEVICE, SIZES } from "../../../constants/responsive";
import MobileFooter from "./MobileFooter";
import RhContactCard from "./RhContactCard";
import HeaderPreonboarding, {
    MobileHeaderPreonboarding,
} from "./HeaderPreonboarding";
import { useMediaQuery } from "react-responsive";
import withAuth from "../../../HOC/withAuth";

const WelcomePreonboarding = () => {
    const [copied, setCopyToClipBoard] = useCopyToClipboard("");

    const copiedToClipboard = (Contact_Hr) => {
        return setCopyToClipBoard(Contact_Hr);
    };

    const { isLoading, isFetching, data } = useQuery(
        ["preonboarding_information"],
        () => _preonboarding()
    );

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileS})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: 500px)`,
    });

    const isMobile = isMobileS || isMobileM;

    return (
        <FooterContent fill column>
            {/* responsive header preonboarding */}
            {isMobile ? (
                <MobileHeaderPreonboarding
                    arrival_date={data?.me?.arrival_date}
                />
            ) : (
                <HeaderPreonboarding arrival_date={data?.me?.arrival_date} />
            )}

            {/* <HeaderPreonboarding />
            <MobileHeaderPreonboarding /> */}
            <ContainerStyle fill hAlign="center" styles={{ gap: "40px" }}>
                <LeftSide fill column>
                    <Header vAlign="center" fill space="between">
                        {data?.contract_document_url && (
                            <Button
                                flat
                                icon={<ContactCardIcon outline />}
                                content="Afficher mon contrat"
                            />
                        )}
                    </Header>
                    <Content fill column>
                        {/* bonjour Text  */}
                        <Flex>
                            <BonjourText
                                content={`Bonjour ${data?.me?.first_name} et félicitation pour votre recrutement !`}
                            />
                        </Flex>
                        {/* description text  */}
                        <Flex
                            style={{
                                gap: "20px",
                            }}
                            fill
                            column
                        >
                            <DescriptionText>
                                Voici la liste de vos actions RH à mener pour
                                compléter votre dossier administratif.
                            </DescriptionText>
                            <DescriptionText>
                                N’hésitez pas à me contacter si besoin
                            </DescriptionText>
                        </Flex>
                        {data?.contract_document_url?.length !== 0 && (
                            <Flex
                                styles={{
                                    paddingBlock: "32px",
                                }}
                            >
                                <Attachment
                                    actionable
                                    action={{
                                        icon: <OpenOutsideIcon />,
                                    }}
                                    icon={<FilesPdfColoredIcon />}
                                    header={`Contrat de travail de ${data?.me?.first_name}.pdf`}
                                />
                                <DividerStyle />
                            </Flex>
                        )}

                        {!isMobile && <RhContactCard data={data} />}

                        <Flex hAlign="center" fill>
                            <ImageStyle src={WelcomeImg} />
                        </Flex>
                        <DividerStyle />
                    </Content>
                </LeftSide>

                <RightSide column>
                    <DocumentComponent
                        data={data}
                        isLoading={isLoading}
                        isFetching={isFetching}
                    />
                </RightSide>
            </ContainerStyle>
            <ContainerDesktop hAlign="center">
                <Footer data={data} />
            </ContainerDesktop>
            {isMobile && (
                <RhContactMobileFlex column>
                    <Divider />
                    <RhContactCard data={data} />
                    <MobileFooter />
                </RhContactMobileFlex>
            )}
        </FooterContent>
    );
};

export default withAuth(WelcomePreonboarding);

const FooterContent = styled(Flex)`
    background: #fafafa;
`;

const ContainerDesktop = styled(Container)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.mobileM} {
        display: none;
    }
    @media ${DEVICE.mobileL} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        /* justify-content: center; */
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

const RhContactMobileFlex = styled(Flex)`
    padding-top: 32px;
`;

const RhContactMobile = styled(Flex)`
    gap: 16px;
    margin-top: 24px;

    @media ${DEVICE.mobileS} {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #fff;
        padding: 16px 20px;
        width: 274px;
        height: 72px;
        /* shadow-2 */
        box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
            0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
    }
    @media ${DEVICE.mobileM} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.mobileL} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.tablet} {
        justify-content: center;
        align-items: center;
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;

const ContainerStyle = styled(Container)`
    /* padding: 24px; */

    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.mobileL} {
        padding: 12px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        max-width: 1440px;
        width: 100%;
        gap: 24px;
        align-items: flex-start;
    }
`;

const ImageStyle = styled(Image)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
        width: 381px;
        height: 276px;
    }
`;

const EmailText = styled(Text)`
    color: #0067b8;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
`;
const TelNumber = styled(Text)`
    color: #0067b8;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
`;

const RightSideCard = styled(Flex)`
    gap: 10px;
`;

const Name = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
const JobTitle = styled(Text)`
    color: #616161;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const LeftSideCard = styled(Flex)`
    gap: 16px;
    padding: 16px;
    width: 200px;
`;

const RhContact = styled(Flex)``;

const DescriptionText = styled(Text)`
    color: #424242;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const BonjourText = styled(Text)`
    color: #242424;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 350;
    line-height: 24px;
`;

const LeftSide = styled(Flex)`
    height: auto;
    /* margin-top: 24px; */

    gap: 24px;
    @media ${DEVICE.mobileS} {
        width: 100%;
        padding-inline: 11px;
    }
    @media ${DEVICE.tablet} {
        width: 100%;
        padding-inline: 11px;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
        max-width: 497px;
        /* padding-inline: 0px; */
    }
    @media ${DEVICE.laptopL} {
        width: 100%;
        max-width: 597px;
    }
`;
const RightSide = styled(Flex)`
    height: auto;
    @media ${DEVICE.mobileS} {
        margin-top: 0px;
    }
    @media ${DEVICE.mobileM} {
        margin-top: 0px;
        width: 100%;
    }
    @media ${DEVICE.tablet} {
        margin-top: 24px;
        width: 550px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 24px;
    }
`;
const Header = styled(Flex)`
    max-height: 61px;
`;
const Content = styled(Flex)`
    gap: 20px;
    width: 100%;
`;

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    right : 0px;
    }
    100% {
    opacity: 1;
    right: 45%
  }
`;

const fadeOutRight = keyframes`
    0% {
        opacity: 1;
        right: 45%;
    }
    100% {
        opacity: 0;
        right: 50px;
    }
`;

const AlertCopied = styled(Alert)`
    position: absolute;
    top: 50%;
    background-color: black;
    color: white;
    font-weight: 600;
    height: 36px;

    opacity: 0;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    &.fade-in-right {
        animation-name: ${fadeInRight};
    }

    &.fade-out-right {
        animation-name: ${fadeOutRight};
    }
`;
const RhContactStyle = styled(RhContact)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;
const DividerStyle = styled(Divider)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;
