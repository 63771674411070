import { Button, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const BadgeNumber = styled.span`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;

export const BadgeComment = styled(Flex)`
    border-radius: 100px;
    background: var(
        --Light-theme-Rest-Background-Default-Background-3,
        #f0f0f0
    );

    display: flex;
    height: 16px;
    padding: 2px 6px;
    gap: 2px;
`;

export const Wrapper = styled(Flex)`
    position: absolute;
    inset: 0;
    background-color: rgba(37, 36, 35, 0.65);
    z-index: 5;
`;
export const Container = styled(Flex)`
    /* width: 190px; */
    min-height: 417px;

    height: 100%;
    gap: 4px;
    /* border: 1px solid #d1d1d1; */
    border-radius: 8px;
    background: white;
    box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13),
        0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11);
`;
export const CardHeader = styled(Flex)`
    padding: 8px;
    gap: 8px;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    min-width: 156px;
    background: ${({ is_today }) =>
        is_today ? "var(--color-primary)" : "white"};
    box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
        0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
`;
export const CardBody = styled(Flex)`
    overflow-y: scroll;
    padding-block: 8px;
    position: relative;
`;
export const WrapperHours = styled(Flex)`
    padding-bottom: 19px;
    gap: 8px;
    width: 90%;
    border-bottom: 1px solid
        var(--light-theme-rest-border-default-border, #d1d1d1);
`;
export const HoursCounter = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
`;
export const HoursText = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;
export const Day = styled(Text)`
    color: ${({ is_today }) => (is_today ? "white" : "#424242")};
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;
export const ButtonModify = styled(Button)`
    border-radius: 4px;
    background: #f5f5f5;
`;
