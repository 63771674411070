import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import styled from "styled-components/macro";
import LogoIcon from "../../assets/logos/logo.svg";

import { Form } from "../Login/index.style";
import { checkActivationCode, updatePassword } from "../../api/onBoarding/user";
// import ERR_PASSWORDS from "../../constants/messages";
import { useAuthDetailStore, useAuthStore } from "../../stores/useAuthStore";

import { useMutation } from "react-query";

import LoaderPage from "../../components/loaders/LoaderPage";
import {
    Input,
    Button,
    Text,
    Flex,
    Box,
    Image,
} from "@fluentui/react-northstar";
import { Container } from "../../common/styles";
import PreOnboarding from "../../features/pre_onboarding";
import StaticSide from "../../features/pre_onboarding/components/StaticSide";
import DynamicSide from "../../features/pre_onboarding/components/DynamicSide";
import Footer from "../../features/pre_onboarding/components/Footer";
import PassewordPage from "../../features/pre_onboarding/components/PasswordPage";
import GradiantSVG from "../../features/pre_onboarding/img/gradiant.svg";
import Logo_yeetch from "./logo.svg";
import { useMediaQuery } from "react-responsive";
import { SIZES } from "../../constants/responsive";
import MobileResetPassword from "./MobileResetPassword";
import withGuestOnly from "../../HOC/withGuestOnly";
const UpdatePassword = () => {
    // get the token on THE URL.
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const step = searchParams.get("s");

    const setUser = useAuthStore((state) => state.setUser);
    const setDetailUser = useAuthDetailStore((state) => state.setDetailUser);

    const refPassword = useRef(null);
    const refCPassword = useRef(null);
    const [password, setPassword] = useState("");
    const [CPassword, setCPassword] = useState("");
    const [error, setError] = useState("");
    const [globalError, setGlobalError] = useState("");
    const [rules, setRules] = useState({
        show_errors: false,
        length: {
            value: 8,
            error: true,
            message: "8 caractères minimum",
        },
        lowercase: {
            value: 1,
            error: true,
            message: "1 caractère en minuscule",
        },
        uppercase: {
            value: 1,
            error: true,
            message: "1 caractère en majuscule",
        },
        number: {
            value: 1,
            error: true,
            message: "1 chiffre",
        },
        symbol: {
            value: 1,
            error: true,
            message: "1 symbole",
        },
    });

    const [SuccessPasswordUpadte, setSuccessPasswordUpadte] = useState(false);

    const [loader, setLoader] = useState(true);

    // verify if the Token is in Params URL
    useEffect(() => {
        if (!token) {
            navigate("/login?error=Token not found");
            return;
        }

        // verify the token of the user there.
        checkActivationCode(token)
            .then((data) => {
                if (!data.success) {
                    // afficher une erreur recu sur Message.
                    setGlobalError(data.message);
                }
                setLoader(false);
            })
            .catch((err) => console.log(err.stack));
    }, []);

    const { isFetching, isLoading, mutate, reset } = useMutation(
        (data) => updatePassword(data),
        {
            onSuccess: (data) => {
                if (data?.success === true && step !== "reset") {
                    // modified successfully
                    setUser({
                        access: data.access_token,
                    });
                    setDetailUser(data.user);
                } else {
                    return setSuccessPasswordUpadte(true);
                    // navigate("/login");
                }
                setGlobalError();
                reset();
            },
        }
    );

    const ChangePassword = (e) => {
        const new_password = e.target.value;
        const minisculeExiste = /[a-z]/.test(new_password);
        const containUppercase = /[A-Z]/.test(new_password);
        const containNumber = /[0-9]/.test(new_password);
        const containSymbol = /[^a-zA-Z0-9]/.test(new_password);

        setPassword(e.target.value);
        if (!rules.show_errors) {
            setRules((state) => {
                return {
                    ...state,
                    show_errors: true,
                };
            });
        }

        if (new_password.length >= rules.length.value) {
            setRules((state) => {
                return {
                    ...state,
                    length: {
                        ...state.length,
                        error: false,
                    },
                };
            });
        } else {
            setRules((state) => {
                return {
                    ...state,
                    length: {
                        ...state.length,
                        error: true,
                    },
                };
            });
        }

        if (minisculeExiste) {
            setRules((state) => {
                return {
                    ...state,
                    lowercase: {
                        ...state.lowercase,
                        error: false,
                    },
                };
            });
        } else {
            setRules((state) => {
                return {
                    ...state,
                    lowercase: {
                        ...state.lowercase,
                        error: true,
                    },
                };
            });
        }

        if (containUppercase) {
            setRules((state) => {
                return {
                    ...state,
                    uppercase: {
                        ...state.uppercase,
                        error: false,
                    },
                };
            });
        } else {
            setRules((state) => {
                return {
                    ...state,
                    uppercase: {
                        ...state.uppercase,
                        error: true,
                    },
                };
            });
        }

        if (containNumber) {
            setRules((state) => {
                return {
                    ...state,
                    number: {
                        ...state.number,
                        error: false,
                    },
                };
            });
        } else {
            setRules((state) => {
                return {
                    ...state,
                    number: {
                        ...state.number,
                        error: true,
                    },
                };
            });
        }

        if (containSymbol) {
            setRules((state) => {
                return {
                    ...state,
                    symbol: {
                        ...state.symbol,
                        error: false,
                    },
                };
            });
        } else {
            setRules((state) => {
                return {
                    ...state,
                    symbol: {
                        ...state.symbol,
                        error: true,
                    },
                };
            });
        }

        return;
    };

    const getProgressStrongPassword = () => {
        const values = Object.values(rules);
        const no_errors_len = values?.filter(
            (el) => el?.error === false
        )?.length;
        const percent = (no_errors_len * 100) / 5;
        return percent;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("step=", step);
        return mutate({
            token,
            password,
            password_confirmation: CPassword,
            step,
        });
    };

    const progress = getProgressStrongPassword();
    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileS})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });

    const isMobile = isMobileS || isMobileM;
    // console.log("isMobile", isMobile);

    if (loader)
        return <LoaderPage label="Chargement des données de la page." />;
    return (
        // <ContainerFluidStyle column hAlign="center">
        //     <Flex styles={{ gap: "111px" }} vAlign="center">
        //         <StaticSide />
        //         <PassewordPage
        //             handleSubmit={handleSubmit}
        //             globalError={globalError}
        //             error={error}
        //             refPassword={refPassword}
        //             password={password}
        //             ChangePassword={(e) => ChangePassword(e)}
        //             progress={progress}
        //             rules={rules}
        //             CPassword={CPassword}
        //             refCPassword={refCPassword}
        //             setCPassword={setCPassword}
        //             isLoading={isLoading}
        //             isFetching={isFetching}
        //         />
        //     </Flex>
        //     {/* footer  */}
        //     <Container hAlign="center">
        //         <Footer hideLogout={true} />
        //     </Container>
        // </ContainerFluidStyle>
        <Flex fill column>
            {isMobile ? (
                <Flex
                    fill
                    styles={{ paddingBlock: "16px" }}
                    hAlign="center"
                    column
                >
                    <MobileResetPassword />
                    <PassewordPage
                        SuccessPasswordUpadte={SuccessPasswordUpadte}
                        handleSubmit={handleSubmit}
                        globalError={globalError}
                        error={error}
                        refPassword={refPassword}
                        password={password}
                        ChangePassword={(e) => ChangePassword(e)}
                        progress={progress}
                        rules={rules}
                        CPassword={CPassword}
                        refCPassword={refCPassword}
                        setCPassword={setCPassword}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        showImage={false}
                    />
                </Flex>
            ) : (
                <ContainerFlex>
                    <MainContent vAlign="center" hAlign="center" column>
                        <Image
                            width={152}
                            height={46}
                            src={Logo_yeetch}
                            alt="logo yeetch"
                        />

                        <PassewordPage
                            SuccessPasswordUpadte={SuccessPasswordUpadte}
                            handleSubmit={handleSubmit}
                            globalError={globalError}
                            error={error}
                            refPassword={refPassword}
                            password={password}
                            ChangePassword={(e) => ChangePassword(e)}
                            progress={progress}
                            rules={rules}
                            CPassword={CPassword}
                            refCPassword={refCPassword}
                            setCPassword={setCPassword}
                            isLoading={isLoading}
                            isFetching={isFetching}
                            showImage={true}
                        />
                    </MainContent>
                </ContainerFlex>
            )}
        </Flex>
    );
};

export default withGuestOnly(UpdatePassword);

const MainContent = styled(Flex)`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2.4px 7.4px,
        rgba(0, 0, 0, 0.22) 0px 12.8px 28.8px;
    border-radius: 8px;
    min-height: 600px;
    min-width: 450px;
    gap: 40px;
    padding-block: 32px;
`;

const ContainerFlex = styled(Flex)`
    justify-content: center;
    align-items: center;
    /* flex: 1; */
    height: 100dvh;
    background: linear-gradient(
        300.89deg,
        rgb(91, 95, 199) 0%,
        rgb(91, 95, 199) 0.01%,
        rgb(197, 203, 250) 100%
    );
    box-shadow: rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px,
        rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px;
`;

const ContainerFluidStyle = styled(Flex)`
    background-image: url("${GradiantSVG}");
    background-repeat: no-repeat;
    background-position-x: calc(50% - 300px);
    background-position-y: 100px;
    z-index: 1;
`;

const InputProgress = styled.div`
    width: ${({ progress }) => progress + "%"};
    height: 8px;
    background-color: #75e675;
    border-radius: 0px 0px 4px 4px;
    transition: width 0.3s;
`;

const TextStatus = styled(Text)`
    color: ${({ status }) => (status ? "var(--color-danger)" : "#21ad21")};
`;

const Logo = styled.img`
    width: 150px;
    margin-bottom: 50px;
`;

const CardStyle = styled(Box)`
    padding: 50px 40px;
    background-color: white;
    width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
`;

const StackStyle = styled(Box)`
    background-color: #f8f8f8;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
