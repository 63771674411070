import React, { useEffect, useState } from "react";
import { Container, ContainerFluid } from "../../../../common/styles";
import CustomBreadCrumb from "../../../../components/breadcrumb";
import { NAVLIST_SUIVI } from "./Constant";
import { useQuery } from "react-query";
import { Flex, Loader } from "@fluentui/react-northstar";
import SubHeader from "../../../../components/breadcrumb/SubHeader";
import ContentSuiviPage from "./ContentSuiviPage";
import HeaderUserDetail from "../commun/HeaderUserDetail";
import styled from "styled-components";
import SuiviAction from "./SuiviAction";
import { _getAllAgencies } from "../../api";
import withAuth from "../../../../HOC/withAuth";
import withRole from "../../../../HOC/withRole";
import { ROLES_CODE } from "../../../../constants/roles";

const Suivi = () => {
    const { isLoading, data } = useQuery(["agencys"], () => _getAllAgencies());

    const [TabView, setTabView] = useState(data ? data[0]?.id : []);

    const menuItems = data?.map((agency) => ({
        content: agency.name,
        value: agency.id,
    }));

    return (
        <ContainerStyled fill column>
            <CustomBreadCrumb navList={NAVLIST_SUIVI} />
            {isLoading ? (
                <Loader content="Loading ....." />
            ) : (
                <>
                    <Container column>
                        <HeaderUserDetail />
                        <ContentSuiviPage />
                    </Container>
                    <Container fill>
                        <SuiviAction />
                    </Container>
                </>
            )}
        </ContainerStyled>
    );
};

export default withAuth(withRole([ROLES_CODE.hr])(Suivi));

const ContainerStyled = styled(ContainerFluid)`
    background: #f5f5f5;
    min-height: 100vh;
`;
