import moment from "moment";

export const _get_str_status = (id) => {
    switch (id) {
        case 1:
            return "On-boarding";
        case 2:
            return "Suivi";
        case 3:
            return "Off-boarding";
        default:
            return "";
    }
};

export const _util_remove_seconds_from_json = (data) => {
    return data.map((el) => ({
        ...el,
        shifts: el.shifts.map((s) => ({
            ...s,
            start_hour: moment(s.start_hour, "HH:mm:ss").format("HH:mm"),
            end_hour: moment(s.end_hour, "HH:mm:ss").format("HH:mm"),
        })),
    }));
};

export const comparerJSON = (obj1, obj2) => {
    // Vérifiez si les deux références sont identiques
    if (obj1 === obj2) return true;

    // Vérifiez les types : si les types diffèrent, ils ne sont pas égaux
    if (typeof obj1 !== typeof obj2) return false;

    // Vérifiez si ce sont des objets ou des tableaux
    if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
        // Récupérez les clés de chaque objet
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // Vérifiez si les objets ont le même nombre de clés
        if (keys1.length !== keys2.length) return false;

        // Vérifiez récursivement chaque clé et valeur
        for (let key of keys1) {
            if (!keys2.includes(key) || !comparerJSON(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true;
    }

    // Si ce n'est ni un objet ni un tableau, comparez directement les valeurs
    return false;
};
