import React from "react";
import {
    AcceptIconStyle,
    MenuStyle,
    TextTab,
    WrapperIcon,
} from "./index.style";
import { Flex, Loader } from "@fluentui/react-northstar";
import { ACTION_TYPES } from "../../reducers/reducer.weeks";
import { useMediaQuery } from "react-responsive";

const WeeksTabsChecker = ({ weeks, loading, currentWeek, dispatch }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    if (loading) return <Loader />;
    const format_items = weeks?.map((week) => {
        const week_number = week?.week_number;
        const isChecked = week?.status?.value !== 0;
        const isCurrentWeek = currentWeek === week_number;
        return {
            key: week_number,
            content: (
                <Flex styles={{ gap: "6px" }} vAlign="center">
                    <TextTab
                        $active={isCurrentWeek}
                        content={`S.${week_number}`}
                    />
                    {isChecked && (
                        <WrapperIcon
                            vAlign="center"
                            hAlign="center"
                            $active={isCurrentWeek}
                        >
                            <AcceptIconStyle size={"smallest"} />
                        </WrapperIcon>
                    )}
                </Flex>
            ),
            week_number,
            selected: isCurrentWeek,
        };
    });

    return (
        <MenuStyle
            styles={{
                border: isMobile ? "2px solid white" : "2px solid #fafafa",
            }}
            id="slider_weeks"
            activeIndex={format_items.findIndex((w) => w.selected)}
            items={format_items}
            onItemClick={(e, i) => {
                const week_number = i?.week_number;
                dispatch({
                    type: ACTION_TYPES.goto_week,
                    payload: {
                        week: week_number,
                    },
                });
                return;
            }}
            underlined
            primary
        />
    );
};

export default WeeksTabsChecker;
