import { Flex } from "@fluentui/react-northstar";
import React from "react";
import TimeField from "react-simple-timefield";
import {
    InputTimePicker,
    TextDeleteSlot,
} from "../DialogModifyWeek/Content/index.style";
import {
    ArrowRightIcon,
    Button,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import { Clock20Regular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { useMediaQuery } from "react-responsive";
const SlotTimeInput = ({
    index,
    start_time,
    end_time,
    onDelete,
    onChangeSlot,
    error_start_time,
    error_end_time,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    return (
        <Flex gap="gap.medium" vAlign="center">
            <TimeField
                value={start_time}
                input={
                    <InputTimePicker
                        error={error_start_time}
                        placeholder="00:00"
                        fluid
                        disabled={false}
                        iconPosition="end"
                        icon={
                            <Clock20Regular
                                primaryFill={"var(--color-primary)"}
                            />
                        }
                    />
                }
                colon=":"
                onChange={(e) =>
                    onChangeSlot({
                        index,
                        value: e.target.value + ":00",
                        type: "field_1",
                    })
                }
            />
            <ArrowRightIcon />
            <TimeField
                value={end_time}
                input={
                    <InputTimePicker
                        error={error_end_time}
                        placeholder="00:00"
                        fluid
                        disabled={false}
                        iconPosition="end"
                        icon={
                            <Clock20Regular
                                primaryFill={"var(--color-primary)"}
                            />
                        }
                    />
                }
                colon=":"
                onChange={(e) =>
                    onChangeSlot({
                        index,
                        value: e.target.value + ":00",
                        type: "field_2",
                    })
                }
            />
            <Button
                styles={{
                    minWidth: "fit-content",
                }}
                icon={
                    <TrashCanIcon
                        size="medium"
                        styles={{ color: "var(--color-danger)" }}
                    />
                }
                content={
                    !isMobile && (
                        <TextDeleteSlot
                            content={t("v2.home.buttons.delete_slot")}
                        />
                    )
                }
                iconPosition="before"
                text
                onClick={() => onDelete()}
            />
        </Flex>
    );
};

export default SlotTimeInput;
