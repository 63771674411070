import React, { useState } from "react";
import {
    PeopleTeam16Filled,
    BuildingBank16Filled,
    BookNumber16Filled,
} from "@fluentui/react-icons";

import Header from "./components/sections/Header";
import { Container, ContainerFluid } from "../../common/styles";
import CustomBreadCrumb from "../../components/breadcrumb";
import { useQuery } from "react-query";
import { BREADCRUMB_EMPLOYEE_FOLDER, QUERY_KEY } from "./constants";
import { _api_get_header_informations } from "./api";
import { useParams } from "react-router-dom";
import Body from "./components/sections/body";
import { useAuthDetailStore } from "../../stores/useAuthStore";
import { useEmployeeFolderSetIdUser, useEmployeeFolderSetIsMe } from "./store";
import { useEffect } from "react";
import { ROLES_CODE, roles_access } from "../../constants/roles";
import withAuth from "../../HOC/withAuth";

export const ALL_TABS = {
    about: { content: "À propos", id: "about" },
    identity: { content: "Identité", id: "identity" },
    contract: { content: "Contrat", id: "contract" },
    plan: { content: "Planning", id: "plan" },
    timetable: { content: "Emploi du temps", id: "timetable" },
};

const EmployeeFolder = () => {
    const { id_employee } = useParams();

    const { id } = useAuthDetailStore((state) => state.detail);
    const set_user = useEmployeeFolderSetIdUser();
    const set_user_is_me = useEmployeeFolderSetIsMe();

    const [selectedValue, setSelectedValue] = useState(ALL_TABS.about.id);

    // * if the id in params equals to the id user connected
    const IS_ME = parseInt(id) === parseInt(id_employee);

    useEffect(() => {
        set_user(id_employee);
        set_user_is_me(IS_ME);
    }, [id_employee]);

    const { data: header_data, isLoading: header_loading } = useQuery(
        [QUERY_KEY + "::header", id_employee],
        () => _api_get_header_informations(id_employee),
        {
            refetchOnWindowFocus: false,
            onSuccess: (header_data) => {
                if (header_data) {
                    document.title = ` Dossier de  ${header_data?.display_name}`;
                }
            },
        }
    );

    const BACKGROUND_COLOR = selectedValue !== "timetable" ? "#f0f0f0" : "white";

    return (
        <ContainerFluid
            column
            styles={{
                height: "100dvh",
                backgroundColor: BACKGROUND_COLOR,
            }}
        >
            <CustomBreadCrumb navList={BREADCRUMB_EMPLOYEE_FOLDER} />
            <ContainerFluid styles={{ backgroundColor: "#f5f5f5" }}>
                <Container column>
                    <Header
                        full_name={header_data?.display_name}
                        status={header_data?.status?.value}
                        avatar={header_data?.avatar}
                        poste={header_data?.job_title}
                        departement={{
                            content: header_data?.department,
                            icon: <PeopleTeam16Filled color=" #616161" />,
                        }}
                        headOffice={{
                            content: header_data?.agency,
                            icon: <BuildingBank16Filled color=" #616161" />,
                        }}
                        email={header_data?.email}
                        mobile={header_data?.phone_number}
                        linkedin={header_data?.linkedin_profile}
                        matricule={{
                            content: header_data?.staff_code,
                            icon: <BookNumber16Filled color=" #616161" />,
                        }}
                        isExternal={header_data?.is_external}
                        missingInformationsNumber={
                            header_data?.missing_infos_count
                        }
                        isMe={IS_ME}
                        isHR={true}
                        isLoading={header_loading}
                    />
                </Container>
            </ContainerFluid>
            <ContainerFluid
                styles={{
                    backgroundColor: BACKGROUND_COLOR,
                }}
            >
                <Body
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                />
            </ContainerFluid>
        </ContainerFluid>
    );
};

export default withAuth(EmployeeFolder);
