import React, { useState } from "react";
import { CheckmarkCircle32Regular } from "@fluentui/react-icons";
import { StackStyle } from "../../pages/Login";
import {
    AcceptIcon,
    ArrowLeftIcon,
    Button,
    CloseIcon,
    Flex,
    Image,
    Input,
    Text,
} from "@fluentui/react-northstar";
import Logo_yeetch from "../../assets/logos/logo.svg";
import styled from "styled-components";
import { DEVICE } from "../../constants/responsive";
import Signin from "./Signin.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { _forget_my_password } from "./api";
import { useMutation } from "react-query";
import withGuestOnly from "../../HOC/withGuestOnly";
const ForgetPassword = () => {
    const location = useLocation();
    const { email } = location.state || {};

    const navigate = useNavigate();
    const GoBack = () => {
        navigate(-1);
    };

    const { mutate: mutate_forgotten_password, isLoading: loadingRequest } =
        useMutation(() => _forget_my_password(email), {
            onSuccess: (response) => {
                if (response.success) {
                    console.log("datassss", response);
                    return setChangeContent(true);
                }
            },
            onError: (error) => {
                console.log("error", error);
                return setChangeContent(false);
            },
        });

    const [changeContent, setChangeContent] = useState(false);
    const SendEmail = (email2) => {
        // send email<

        return mutate_forgotten_password({ email2 });
    };

    return (
        <StackStyle>
            <CardStyle hAlign="center" column>
                <Image
                    style={{ marginTop: "24px" }}
                    width={200}
                    src={Logo_yeetch}
                    alt="logo yeetch"
                />
                <Flex column styles={{ gap: "40px" }}>
                    <Image styles={{ maxHeight: "180px" }} src={Signin} />
                </Flex>
                <ChangePasswordText content={"Mot de passe oublié"} />
                <Flex gap="gap.large" column>
                    {changeContent ? (
                        <Flex gap="gap.large" column>
                            <Flex gap="gap.small" column>
                                <Flex hAlign="center" column>
                                    <CheckmarkCircle32Regular
                                        style={{ color: "green" }}
                                    />
                                    <LinkSendedText content="Lien envoyé" />
                                </Flex>
                                <ForgetPasswordescription
                                    content={
                                        "Consultez votre boite mail et ouvrez le lien pour définir un nouveau mot de passe"
                                    }
                                />
                            </Flex>
                            <Button
                                // styles={{ paddingBottom: "10px" }}
                                text
                                icon={<ArrowLeftIcon size="small" />}
                                onClick={GoBack}
                                fluid
                                content="Revenir à la connexion"
                            />
                        </Flex>
                    ) : (
                        <>
                            <ForgetPasswordescription
                                content={
                                    "Entrez votre email et nous vous enverrons un lien pour réinitialiser votre mot de passe"
                                }
                            />
                            <Flex gap="gap.medium" column>
                                <Flex gap="gap.medium" column fill>
                                    <Input
                                        label="Email"
                                        value={email}
                                        fluid
                                        icon={
                                            <CloseIcon
                                                size="small"
                                                onClick={GoBack}
                                            />
                                        }
                                        content={email}
                                        disabled
                                    />
                                    <Button
                                        loading={loadingRequest}
                                        content={
                                            "Envoyer le lien sur mon email"
                                        }
                                        fluid
                                        primary
                                        onClick={() => SendEmail(email)}
                                    />
                                </Flex>
                                <Button
                                    // styles={{ paddingBottom: "10px" }}
                                    text
                                    icon={<ArrowLeftIcon size="small" />}
                                    onClick={GoBack}
                                    fluid
                                    content="Revenir à la connexion"
                                />
                            </Flex>
                        </>
                    )}
                </Flex>
            </CardStyle>
        </StackStyle>
    );
};

export default withGuestOnly(ForgetPassword);

const ChangePasswordText = styled(Text)`
    color: #424242;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

const CardStyle = styled(Flex)`
    /* border: 5px solid magenta; */
    background-color: white;
    /* width: 288px; */
    box-shadow: 0px 2.4px 7.4px rgba(0, 0, 0, 0.18),
        0px 12.8px 28.8px rgba(0, 0, 0, 0.22);
    border-radius: 8px;
    min-height: 600px;
    /* min-width: 450px; */
    width: clamp(300px, 100%, 450px);
    /* max-width: 500px; */
    /* min-width: 280px; */
    gap: 40px;
    padding-bottom: 32px;
    padding-inline: 12px;
    @media ${DEVICE.mobileS} {
        width: 280px;
    }
    @media ${DEVICE.mobileM} {
        width: 340px;
    }
    @media ${DEVICE.mobileL} {
        width: 390px;
    }
    @media ${DEVICE.tablet} {
        width: 450px;
    }
`;

const ForgetPasswordescription = styled(Text)`
    max-width: 350px;
    color: #242424;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* width: 100px; */
`;

const LinkSendedText = styled(Text)`
    color: #242424;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
