import { AcceptIcon, Flex, Label, Menu, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const MenuStyle = styled(Menu)`
    width: 100%;
    justify-content: space-between;

    .ui-menu__itemcontent {
        margin-bottom: 5px;
    }
`;
export const LabelStyle = styled(Label)`
    background-color: #616161;
    display: flex;
    align-items: center;
`;
export const AcceptIconStyle = styled(AcceptIcon)``;
export const WrapperIcon = styled(Flex)`
    padding: 6px;
    gap: 2px;
    width: 24px;
    border-radius: 25px;
    background-color: ${({ $active }) =>
        $active ? "var(--color-primary)" : "#616161"};
    color: white;
`;
export const TextTab = styled(Text)`
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    color: ${({ $active }) => ($active ? "var(--color-primary)" : "#616161")};
    font-weight: ${({ $active }) => ($active ? "bold" : "400")};
`;
