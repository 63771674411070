import {
    Button,
    CloseIcon,
    Dialog,
    EditIcon,
    ExclamationTriangleIcon,
    Flex,
    MenuButton,
    MoreIcon,
    TaskListIcon,
    Text,
    Tooltip,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import { string } from "prop-types";
import { useRef } from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { queryClient } from "../../..";
import { AVATAR_DEFAULT, BASE_URL_ILLUSTRATIONS } from "../../../constants";
import AvatarUser from "../components/avatars";
import DialogModifyTask from "../components/dialogs/ModifyTask";
import { I18N_NS_ADD_EMPLOYEE } from "../constants";
import {
    f_all_tasks,
    f_set_resources,
    f_set_templates,
    useSetAllSelectedTask,
    useStepper,
    useTemplates,
} from "../stores";
import moment from "moment";
import { _str_capitalize } from "../../../utils/functions";

export const getDaynameByIndex = (day_index, format = "dddd") => {
    return _str_capitalize(moment().weekday(day_index).format(format));
};

export function getDaysOfWeek() {
    // Get the current date
    const currentDate = moment();

    // Set the start of the week to Monday
    currentDate.startOf("isoWeek");

    // Create an array to store the days of the week
    const daysOfWeek = [];

    // Loop to get the next 7 days
    for (let i = 0; i < 7; i++) {
        // Add the formatted day name to the array
        daysOfWeek.push(_str_capitalize(currentDate.format("dddd")));

        // Move to the next day
        currentDate.add(1, "days");
    }

    return daysOfWeek;
}
/**
 * FOR ACCORDION RESOURCES
 */
export const _checkedHeaderList = (_, i) => {
    const { data: dataElement, checked } = i;
    queryClient.setQueriesData("getResources", (data) => {
        return data.map((el) => {
            return el?.id === dataElement?.id
                ? {
                      ...el,
                      checked: checked,
                      resources: el?.resources?.map((el) => {
                          return { ...el, checked: checked };
                      }),
                  }
                : el;
        });
    });
    // save this data into the context STEPPER
    const new_state = queryClient.getQueryData("getResources");
    f_set_resources(new_state);
};

export const _checkedChildrens = (_, i) => {
    const { "data-parent": parent, checked, "data-children": child } = i;
    const { id } = parent;
    queryClient.setQueriesData("getResources", (data) => {
        // check or uncheck child element.
        const new_state = data.map((el) => {
            return el?.id === id
                ? {
                      ...el,
                      resources: el?.resources?.map((el) => {
                          if (child?.id === el?.id) {
                              return { ...el, checked: checked };
                          } else {
                              return el;
                          }
                      }),
                  }
                : el;
        });
        // get the current new resources of children.
        const filter = new_state?.filter((el) => {
            return el?.id === id;
        });
        // verify if is all checked or not.
        const isAllChecked = filter[0]?.resources?.every(
            (el) => el?.checked === true
        );
        const isSomeChecked = filter[0]?.resources?.some(
            (el) => el?.checked === true
        );
        // render the final data there.
        const last_state = data.map((el) => {
            return el?.id === id
                ? {
                      ...el,
                      checked: isAllChecked
                          ? true
                          : isSomeChecked
                          ? "mixed"
                          : false,
                      resources: el?.resources?.map((el) => {
                          if (child?.id === el?.id) {
                              return { ...el, checked: checked };
                          } else {
                              return el;
                          }
                      }),
                  }
                : el;
        });

        // save this data into the context.
        f_set_resources(last_state);
        return last_state;
    });
};

/**
 * FOR ACCORDION TEMPLATES RESOURCES
 */
export const _checkedHeaderListTemplate = (_, i) => {
    _.stopPropagation();
    _.preventDefault();
    const { data: dataElement, checked } = i;

    // save the data to the temporary storage.
    const templates = useStepper.getState().templates;

    const new_state = templates?.map((el) => {
        return el?.id === dataElement?.id
            ? {
                  ...el,
                  checked: checked,
                  tasks: el?.tasks?.map((el) => {
                      return { ...el, checked: checked };
                  }),
              }
            : el;
    });
    f_set_templates(new_state);
};

export const _checkedChildrensTemplate = (_, i) => {
    _.stopPropagation();
    _.preventDefault();

    try {
        const { "data-parent": parent, checked, "data-children": child } = i;

        const { id } = parent;

        const templates = useStepper.getState().templates;

        // check or uncheck child element.
        const new_state = templates.map((el) => {
            return el?.id === id
                ? {
                      ...el,
                      tasks: el?.tasks?.map((el) => {
                          if (child?.id === el?.id) {
                              return { ...el, checked: checked };
                          } else {
                              return el;
                          }
                      }),
                  }
                : el;
        });

        // get the current new tasks of children.
        const filter = new_state?.filter((el) => {
            return el?.id === id;
        });

        // verify if is all checked or not.
        const isAllChecked = filter[0]?.tasks?.every(
            (el) => el?.checked === true
        );
        const isSomeChecked = filter[0]?.tasks?.some(
            (el) => el?.checked === true
        );

        // render the final data there.
        const last_state = templates.map((el) => {
            return el?.id === id
                ? {
                      ...el,
                      checked: isAllChecked
                          ? true
                          : isSomeChecked
                          ? "mixed"
                          : false,
                      tasks: el?.tasks?.map((el) => {
                          if (child?.id === el?.id) {
                              return { ...el, checked: checked };
                          } else {
                              return el;
                          }
                      }),
                  }
                : el;
        });

        // save this data into the context.
        return f_set_templates(last_state);
    } catch (error) {
        console.log({ error });
    }
};

export const _format_tasks_definition_table = (tasks = []) => {
    // const tasks = useStepper.getState().all_selected_task;
    return tasks
        ?.filter((el) => el?.error === false || !el?.error)
        .map((el, index) => {
            const document_required = [2, 3, 4].includes(
                parseInt(el?.document_action)
            );
            return {
                key: index,
                items: [
                    {
                        key: index + "_1",
                        content: showTheRightName({
                            document_required,
                            name: el?.name,
                            documents_template: el?.documents_template,
                        }),
                        truncateContent: true,
                    },
                    {
                        key: index + "_2",
                        content: el?.due_date,
                        truncateContent: true,
                    },
                    {
                        key: index + "_3",
                        content: (
                            <AvatarUser
                                key={`avatar_${index}`}
                                image={
                                    el?.assigned_to_user?.avatar ||
                                    el?.assigned_to?.image ||
                                    el?.assigned_to?.avatar ||
                                    AVATAR_DEFAULT
                                }
                                name={
                                    el?.assigned_to_user?.name ||
                                    el?.assigned_to?.name ||
                                    el?.assigned_to?.header
                                }
                                showName={true}
                            />
                        ),
                        truncateContent: true,
                    },
                    {
                        key: index + "_4",
                        content: (
                            <MoreItem key={`more points ${index}`} task={el} />
                        ),
                    },
                ],
            };
        });
};

const MoreItem = ({ task }) => {
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const refSubmitButton = useRef(null);
    const set_all_tasks = useSetAllSelectedTask(); // show the list of table tasks.
    const [open, setOpen] = useState(false); // for menu list.
    const [openModifyModal, setOpenModifyModel] = useState(false);
    const queryClient = useQueryClient();
    const _closeModal = () => {
        _reset_query_modal_modify_task();
        setOpenModifyModel(false);
    };

    const {
        last_name: last_name_current_employee,
        name: name_current_employee,
        email: email_current_employee,
    } = useStepper((state) => state.step_personal_informations);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            document_action: 5, // aucun document requis
            saved_files: [], // document uploaded from Dialog add task
            type: task?.type, // type is the value of the slidertabs on dialog add task
        },
    });

    /**
     * FUNCTIONS
     */
    const onSubmit = async (data) => {
        //* verify if the task is document_required and the user doesnt upload a required document.
        if (data?.document_required && data?.saved_files?.length === 0) {
            methods.setError("document_action", {
                type: string,
                message:
                    "Le document est obligatoire. Merci d'ajouter le document associé à cette action.",
            });
            return;
        }

        // todo: add the new task to context "all_selected_task".
        _modify_task_from_selected_list(data, set_all_tasks);
        closeAddTaskResetForm();
        _reset_query_modal_modify_task();
        setOpen(false);
    };

    const _reset_query_modal_modify_task = () => {
        // reset all queries on the modal, modifytask
        queryClient.removeQueries("getCategoryOnModifyTask", {
            exact: true,
        });
        queryClient.removeQueries("getCollaboratorOnModifyTask", {
            exact: true,
        });
        return;
    };

    const closeAddTaskResetForm = () => {
        methods.reset(); // reset all form task
        setOpenModifyModel(false);
    };

    return (
        <MenuButton
            open={open}
            onOpenChange={(e, { open }) => {
                if (openModifyModal) {
                    return;
                }
                setOpen(open);
            }}
            trigger={
                <Button
                    text
                    icon={<MoreIcon />}
                    title={t("more option")}
                    onClick={() => setOpen(true)}
                />
            }
            menu={[
                <FormProvider {...methods} key="forms">
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Dialog
                            open={openModifyModal}
                            cancelButton={t("common.cancel")}
                            confirmButton={t("Modifier une tâche")}
                            content={
                                <DialogModifyTask
                                    task={task}
                                    emailCurrentUser={email_current_employee}
                                    nameCurrentUser={`${_str_capitalize(
                                        last_name_current_employee
                                    )} ${name_current_employee?.toUpperCase()}`}
                                />
                            }
                            header={t(
                                "pages.add_employee.step_tasks.dialog.modify_title"
                            )}
                            headerAction={{
                                icon: <CloseIcon />,
                                title: t("common.close"),
                                onClick: _closeModal,
                            }}
                            onCancel={_closeModal}
                            onConfirm={(e) => {
                                e.stopPropagation();
                                refSubmitButton.current.click();
                                return;
                            }}
                            trigger={
                                <Button
                                    icon={<EditIcon />}
                                    content={t("Modifier la tâche")}
                                    text
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setOpenModifyModel(true);
                                    }}
                                />
                            }
                            styles={{
                                width: "700px",
                            }}
                        />
                        {/* this input is used for submitting form */}
                        <input
                            type="submit"
                            value="submit"
                            hidden
                            ref={refSubmitButton}
                        />
                    </form>
                </FormProvider>,
                <Button
                    key="form_btn"
                    styles={{ color: "red" }}
                    icon={<TrashCanIcon color="" />}
                    content={t("Supprimer la tâche")}
                    text
                    onClick={() => {
                        remove_selected_task(task?.id);
                    }}
                />,
            ]}
        />
    );
};

export const remove_selected_task = (id_task) => {
    // get function we need to remove the task
    const f_all_tasks = useStepper.getState().all_selected_task;
    const f_set_all_tasks = useStepper.getState().setAllSelectedTask;

    // remove the task from selected task localstorage.
    const without_removed_task = f_all_tasks.filter((el) => el.id !== id_task);
    f_set_all_tasks(without_removed_task);

    // uncheck the tasks from dialog templates.
    _uncheck_task_from_template(id_task);
};

const _uncheck_task_from_template = (id_task) => {
    const f_templates = useStepper.getState().templates;

    let find_parent = null;
    for (let i = 0; i < f_templates.length; i++) {
        const parent = f_templates[i];
        const tasks = parent?.tasks;
        for (let j = 0; j < tasks?.length; j++) {
            const task = tasks[j];
            if (task?.id === id_task) {
                find_parent = parent;
                break;
            }
        }
    }
    const tasks_without_current_task = find_parent?.tasks?.filter(
        (el) => el?.id !== id_task
    );
    // verify if is all checked or not.
    const isAllUnchecked = tasks_without_current_task?.every(
        (el) => el?.checked === false
    );

    // we loop through the local data.
    const task_unchecked = f_templates?.map((el) => {
        if (el?.id === find_parent?.id) {
            return {
                ...el,
                checked: isAllUnchecked ? false : "mixed",
                tasks: el?.tasks?.map((task) => {
                    if (task?.id === id_task) {
                        return {
                            ...task,
                            checked: false,
                        };
                    }
                    return task;
                }),
            };
        }
        return el;
    });

    const f_set_templates = useStepper.getState().setTemplates;
    f_set_templates(task_unchecked);
    const f_set_templates_last_updated =
        useStepper.getState().setTemplatesLastUpdated;
    f_set_templates_last_updated(task_unchecked);
};

export const getOutersectionTwoArrays = (first = [], second = []) => {
    const new_tasks = [];
    for (let i = 0; i < first.length; i++) {
        const first_element = first[i];
        const find_on_second = second.indexOf(first_element);
        if (find_on_second === -1) {
            new_tasks.push(first_element);
        }
    }
    return new_tasks;
};

/**
 *
 * @param {Object} task the new task to add
 * @param {Function} set_all_tasks the context function to modify the list of tasks.
 */
export const _add_task_to_selected_list = (task, set_all_tasks) => {
    const f_all_tasks = useStepper.getState().all_selected_task;
    set_all_tasks([...f_all_tasks, task]);
};

export const _modify_task_from_selected_list = (task, set_all_tasks) => {
    const f_all_tasks = useStepper.getState().all_selected_task;
    const new_array_tasks = f_all_tasks?.map((el) => {
        if (el?.id === task?.id) {
            return task;
        }
        return el;
    });
    set_all_tasks(new_array_tasks);
};

const showTheRightName = ({ name, document_required, documents_template }) => {
    if (document_required && documents_template?.length === 0) {
        return (
            <Flex fill styles={{ width: "240px" }}>
                <Tooltip
                    content="Veuillez modifier la tâche pour renseigner le document à consulter, compléter ou signer "
                    subtle={false}
                    trigger={
                        <Flex
                            vAlign="center"
                            gap="gap.small"
                            fill
                            space="between"
                            styles={{
                                cursor: "pointer",
                                overflow: "hidden",
                            }}
                        >
                            <Text
                                truncated
                                styles={{ color: "rgba(196,49,75,0.9)" }}
                                content={name}
                            />
                            <ExclamationTriangleIcon
                                outline
                                variables={{
                                    color: "rgba(196,49,75,0.9)",
                                }}
                            />
                        </Flex>
                    }
                />
            </Flex>
        );
    }
    return (
        <Flex
            styles={{
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
            grow
        >
            <Text truncated content={name} />
        </Flex>
    );
};
