import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";

export const Wrapper = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        justify-content: space-between;
    }
    @media ${DEVICE.tablet} {
        width: 250px;
    }
`;
export const TextYearName = styled(Text)`
    color: #242424;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;
