import { CloseIcon, Dialog, Divider, Flex } from "@fluentui/react-northstar";
import React, { startTransition, useEffect, useState } from "react";
import SlotLeave from "../../../../new_time_reporting/components/SlotLeave";
import SlotTimeInput from "../../../../new_time_reporting/components/SlotTimeInput";
import AddSlot from "../../../../new_time_reporting/components/AddSlot";
import { Title } from "../../../../new_time_reporting/components/DialogModifyWeek/Content/index.style";
import {
    ACTIONS_TIMETABLE,
    useStoreTimetable,
} from "../../../reducers/timetable.reducer";
import moment from "moment";
import { extendMoment } from "moment-range";
import SlotNotWorking from "../../../../new_time_reporting/components/SlotNotWorking";

const DialogModifyDay = ({ children, shifts = [], day_index = null }) => {
    const [open, setOpen] = useState(false);
    const [shiftsSlots, setShiftsSlots] = useState(shifts); // pour pouvoir modifier ici.
    const extend_moment = extendMoment(moment);

    const { dispatch } = useStoreTimetable();

    useEffect(() => {
        setShiftsSlots(shifts);
    }, []);

    const closeDialog = () => {
        startTransition(() => {
            setShiftsSlots(shifts);
            return setOpen(false);
        });
    };

    const closeDialogOnSave = () => {
        startTransition(() => {
            setShiftsSlots(shiftsSlots);
            return setOpen(false);
        });
    };

    const openDialog = () => {
        startTransition(() => {
            return setOpen(true);
        });
    };

    const add_new_slot = () => {
        setShiftsSlots((state) => {
            return [
                ...state,
                {
                    id: new Date().getTime(),
                    start_hour: "00:00",
                    end_hour: "00:00",
                    status: {
                        value: 1,
                        value_str: "Jour de travail",
                    },
                },
            ];
        });
        return;
    };

    const delete_slot = (current_slot_id) => {
        setShiftsSlots((state) => {
            return state.filter((slot) => slot.id !== current_slot_id);
        });
        return;
    };

    const is_start_after_end = (shift) => {
        const moment_start = moment(shift?.start_hour, "HH:mm:ss");
        const moment_end = moment(shift?.end_hour, "HH:mm:ss");

        const isStartAfterEnd =
            shift.end_time !== "00:00:00"
                ? moment_start.isAfter(moment_end) ||
                  moment_start.format("HH:mm:ss") ===
                      moment_end.format("HH:mm:ss")
                : false;

        if (isStartAfterEnd) {
            return true;
        }
    };

    const isNewRangeBetweenSlots = ({ new_range, index, shifts }) => {
        const collisions = [];
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            const start = shift.start_hour;
            const end = shift.end_hour;

            if ((start !== "00:00:00" || end !== "00:00:00") && i !== index) {
                const new_start = new_range.start;
                const new_end = new_range.end;

                const range1 = extend_moment.range(
                    "2023-01-02T" + new_start,
                    "2023-01-02T" + new_end
                );
                const range2 = extend_moment.range(
                    "2023-01-02T" + start,
                    "2023-01-02T" + end
                );

                const isIntersect =
                    range1.overlaps(range2, {
                        adjacent: false,
                    }) ||
                    range2.overlaps(range1, {
                        adjacent: false,
                    });

                if (isIntersect) {
                    collisions.push(i);
                }
            }
        }
        return collisions.length !== 0;
    };

    const isIntersect = (new_shifts, shift, index) => {
        // verifier les deux field sur la meme ligne.
        const isStartAfterEnd = is_start_after_end(shift);
        if (isStartAfterEnd) {
            return true;
        }
        // verifier que cette ligne n'est pas intersect avec les autres champs.
        const isNewRangeInterfering = isNewRangeBetweenSlots({
            new_range: {
                start: shift.start_hour,
                end: shift.end_hour,
            },
            index,
            shifts: new_shifts,
        });
        return isNewRangeInterfering;
    };

    const on_change_slot = ({ index, type, value }) => {
        const new_shifts = shiftsSlots.map((shift, i) => {
            if (i === index) {
                return {
                    ...shift,
                    start_hour:
                        type === "field_1"
                            ? moment(value, "HH:mm:ss").format("HH:mm")
                            : shift?.start_hour,
                    end_hour:
                        type === "field_2"
                            ? moment(value, "HH:mm:ss").format("HH:mm")
                            : shift?.end_hour,
                };
            } else {
                return shift;
            }
        });
        const shifts_with_errors = new_shifts.map((shift, j) => {
            const is_collision = isIntersect(new_shifts, shift, j);

            return {
                ...shift,
                errors: {
                    f1: is_collision,
                    f2: is_collision,
                },
            };
        });

        return setShiftsSlots(shifts_with_errors);
    };

    const confirmDialog = () => {
        dispatch({
            type: ACTIONS_TIMETABLE.update_slot_day,
            payload: {
                day_index,
                slots: shiftsSlots,
            },
        });
        closeDialogOnSave();
        return;
    };

    const disable_add_button =
        shiftsSlots.filter((slot) => slot.status.value === 1).length >= 4;

    return (
        <Dialog
            open={open}
            header={"Modifier un jour"}
            confirmButton={{
                flat: true,
                content: "Enregistrer le jour",
            }}
            cancelButton={{
                flat: true,
                content: "Annuler",
            }}
            onCancel={closeDialog}
            onConfirm={confirmDialog}
            content={
                <Flex column gap="gap.large" styles={{ marginTop: "16px" }}>
                    <Flex column gap="gap.medium">
                        {/* SLOTS */}
                        <Flex
                            gap="gap.medium"
                            column
                            styles={{ marginBottom: "16px" }}
                        >
                            {shiftsSlots?.map((shift, index) => {
                                const isLeave =
                                    shift?.status?.value !== 1 &&
                                    shifts?.status?.value !== -1;
                                if (isLeave) {
                                    return (
                                        <SlotNotWorking
                                            start_time={shift.start_hour}
                                            end_time={shift.end_hour}
                                        />
                                    );
                                }
                                return (
                                    <>
                                        <SlotTimeInput
                                            key={shift.id}
                                            index={index}
                                            shift_id={shift.id}
                                            start_time={shift.start_hour}
                                            end_time={shift.end_hour}
                                            onDelete={() =>
                                                delete_slot(shift.id)
                                            }
                                            onChangeSlot={on_change_slot}
                                            error_start_time={shift?.errors?.f1}
                                            error_end_time={shift?.errors?.f2}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                            <AddSlot
                                onClick={() => add_new_slot()}
                                disabled={disable_add_button}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            }
            trigger={<div onClick={openDialog}>{children}</div>}
            headerAction={{
                icon: <CloseIcon />,
                onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return closeDialog();
                },
            }}
            styles={{
                width: "415px",
            }}
        />
    );
};

export default DialogModifyDay;
