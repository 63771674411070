import { BASE_URL_API } from "../../../constants";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import fetcher from "../../../utils/fetcher";
import { useEmployeeFolder } from "../store";

export const _api_put_set_planning = ({ id_user, planning_id }) => {
    const url = `${BASE_URL_API}/user/${id_user}/profile/timetable_template/${planning_id}/apply`;
    return fetcher(url, {
        method: "PUT",
        credentials: "include",
    });
};

export const _api_get_timetable = ({ id_user }) => {
    const url = `${BASE_URL_API}/user/${id_user}/profile/timetable`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_post_timetable = ({ data, id_user }) => {
    const url = `${BASE_URL_API}/user/${id_user}/profile/timetable`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const _api_get_header_informations = async (id_user) => {
    const url = `${BASE_URL_API}/user/${id_user}/profile/header`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_about_informations = async (id_user) => {
    const url = `${BASE_URL_API}/user/${id_user}/profile/about`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_identity = async (id_user) => {
    const url = `${BASE_URL_API}/user/${id_user}/profile/identity`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_contract = async (id_user) => {
    const url = `${BASE_URL_API}/user/${id_user}/profile/contract`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_update_personal_information = async (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/identity`;
    return fetcher(url, {
        method: "PATCH",
        body: JSON.stringify({
            step: "personal_infos",
            ...data,
        }),
        credentials: "include",
    });
};

export const _api_get_package_type_standard_ids = () => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/package_types`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_leave_policy = async (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/leave_policies`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_type_contracts = async () => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/contract_type/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_contract_status = async () => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/contract_status/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_update_dates = async (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/contract`;
    return fetcher(url, {
        method: "PATCH",
        body: JSON.stringify({
            step: "dates",
            ...data,
        }),
        credentials: "include",
    });
};

export const _api_update_qualification_job = async (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/contract`;
    return fetcher(url, {
        method: "PATCH",
        body: JSON.stringify({
            step: "qualification",
            ...data,
        }),
        credentials: "include",
    });
};

export const _api_update_surrounding_people = async (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/personal_infos_and_contacts`;
    return fetcher(url, {
        method: "PATCH",
        body: JSON.stringify({
            step: "edit_contacts",
            ...data,
        }),
        credentials: "include",
    });
};

export const _api_update_civil_status = async (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/identity`;
    return fetcher(url, {
        method: "PATCH",
        body: JSON.stringify({
            step: "civil_status",
            ...data,
        }),
        credentials: "include",
    });
};

export const _api_update_my_personal_information = async (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/personal_infos_and_contacts`;
    return fetcher(url, {
        method: "PATCH",
        body: JSON.stringify({
            step: "personal_infos",
            ...data,
        }),
        credentials: "include",
    });
};

export const _api_update_contract = async (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/contract`;
    return fetcher(url, {
        method: "PATCH",
        body: JSON.stringify({
            step: "contract",
            ...data,
        }),
        credentials: "include",
    });
};

export const _api_update_manager = async ({ manager_email }) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/about`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            step: "change_manager",
            manager_email,
        }),
        credentials: "include",
    });
};

export const _api_update_about_me = async ({ about_me }) => {
    const url = `${BASE_URL_API}/user/profile/about`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            step: "edit_about_me",
            about_me,
        }),
        credentials: "include",
    });
};

export const _api_update_roles = async ({ roles }) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/about`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            step: "change_roles",
            role_codes: roles,
        }),
        credentials: "include",
    });
};

export const _api_update_hr = async ({ hr_email }) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/about`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            step: "change_hr",
            hr_email,
        }),
        credentials: "include",
    });
};

export const _api_update_agency_department = async ({
    agency_id,
    department_id,
}) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    const url = `${BASE_URL_API}/user/${id_employee}/profile/about`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            step: "change_department_agency",
            department_id,
            agency_id,
        }),
        credentials: "include",
    });
};

export const api_planning_manager = () => {
    const id_employee = useEmployeeFolder.getState().id_user;
    return fetcher(`${BASE_URL_API}/user/${id_employee}/profile/planning`, {
        method: "GET",
        credentials: "include",
    });
};
export const api_project_list = () => {
    const id_employee = useEmployeeFolder.getState().id_user;
    return fetcher(
        `${BASE_URL_API}/user/${id_employee}/profile/planning/project/list`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const api_time_table_template_list = () => {
    const id_employee = useEmployeeFolder.getState().id_user;
    return fetcher(
        `${BASE_URL_API}/user/${id_employee}/profile/planning/timetable_template/list`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const api_selection_timetable_template = (id) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    return fetcher(
        `${BASE_URL_API}/user/${id_employee}/profile/planning/timetable_template/set`,
        {
            method: "POST",
            body: JSON.stringify({
                timetable_template_id: id,
            }),
            credentials: "include",
        }
    );
};
export const _api_post_planning = (data) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    return fetcher(`${BASE_URL_API}/user/${id_employee}/profile/planning`, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const api_update_day = ({ shifts, dayIndex }) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    return fetcher(
        `${BASE_URL_API}/user/${id_employee}/profile/planning/day/${dayIndex}`,
        {
            method: "POST",
            body: JSON.stringify({
                shifts,
            }),
            credentials: "include",
        }
    );
};

export const api_selection_project = (id) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    return fetcher(
        `${BASE_URL_API}/user/${id_employee}/profile/planning/project/set`,
        {
            method: "POST",
            body: JSON.stringify({
                project_id: id,
            }),
            credentials: "include",
        }
    );
};

export const api_update_day_type = (dayId, type_day_id) => {
    const id_employee = useEmployeeFolder.getState().id_user;
    return fetcher(
        `${BASE_URL_API}/user/${id_employee}/profile/planning/day/${dayId}`,
        {
            method: "PUT",
            body: JSON.stringify({
                day_type: type_day_id,
            }),
            credentials: "include",
        }
    );
};
