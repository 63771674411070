import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import validator from "validator";
import LogoIcon from "../../assets/logos/yeetch-logo.svg";
// import Logo_yeetch from "./img/Logo_yeetch.svg";
import Signin from "./img/Signin.svg";
// import Yeetch from "./img/Yeetch.svg";
import Logo_yeetch from "../../assets/logos/logo.svg";
import { useQuery, useQueryClient } from "react-query";
import { login } from "../../api/auth";
// import AskPassword from "./AskPassword";
import { useStepsLogin } from "./Context.login";
import ConnectForceMs from "./ConnectForceMs";
import InitLogin from "./InitLogin";
import ConnectMs from "./ConnectMs";
import AskPassword from "./AskPassword";
import { DEVICE } from "../../constants/responsive";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import { app, authentication } from "@microsoft/teams-js";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import withGuestOnly from "../../HOC/withGuestOnly";

const RESPONSE_SERVER = {
    CONNECT_WITH_MICROSOFT: "CONNECT_WITH_MICROSOFT",
    ASK_PASSWORD: "ASK_PASSWORD",
    USER_NOT_FOUND: "USER_NOT_FOUND",
    ASK_ADD_MICROSOFT_ACCOUNT: "ASK_ADD_MICROSOFT_ACCOUNT",
};

const QueryKey = "loginAPI";

const Login = () => {
    useEffect(() => {
        document.title = "Authentification";
    }, []);

    const navigate = useNavigate();

    // vérifier que le collaborateur est sur teams ou pas
    useEffect(() => {
        // Ne sera jamais appelé en dehors de Teams
        async function initSSO() {
            try {
                await app.initialize();
                // le collab est sur teams
                return navigate(ROUTES.teams);
            } catch (err) {
                console.log({ err });
            }
        }
        initSSO();
        //**** End - Implement SSO with teams ****//
    }, []);

    const refEmail = useRef(null);

    const clientQuery = useQueryClient();

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const {
        INIT,
        CONNECT_WITH_MICROSOFT,
        ASK_PASSWORD,
        codeForMs,
        ASK_ADD_MICROSOFT_ACCOUNT,
        showConnectMS,
        showAskPassword,
        showAddMicrosoftAccount,
    } = useStepsLogin();

    // const INIT = useStepsLogin((state) => state.INIT);
    // const CONNECT_WITH_MICROSOFT = useStepsLogin(
    //     (state) => state.CONNECT_WITH_MICROSOFT
    // );
    // const codeForMs = useStepsLogin((state) => state.codeForMs);
    // const ASK_PASSWORD = useStepsLogin((state) => state.ASK_PASSWORD);
    // const ASK_ADD_MICROSOFT_ACCOUNT = useStepsLogin(
    //     (state) => state.ASK_ADD_MICROSOFT_ACCOUNT
    // );
    // const showConnectMS = useStepsLogin((state) => state.showConnectMS);
    // const showAskPassword = useStepsLogin((state) => state.showAskPassword);
    // const showAddMicrosoftAccount = useStepsLogin(
    //     (state) => state.showAddMicrosoftAccount
    // );

    // functions
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isEmail = validator.isEmail(email);
        if (!isEmail) {
            refEmail?.current?.focus();
            return setError("Veuillez saisir un bon email");
        }
        setError("");
        refetch();
    };

    const {
        isFetching,
        isLoading,
        error: errorApi,
        data,
        refetch,
        isError,
    } = useQuery(
        [QueryKey],
        () =>
            login({
                email,
            }),
        {
            enabled: false,
        }
    );

    if (errorApi || isError) {
        setError("Veuillez vérifier votre connexion a Internet !");
        clientQuery.resetQueries(QueryKey).then(() => {});
    }

    if (data?.success === true) {
        switch (data.status_code) {
            case RESPONSE_SERVER.CONNECT_WITH_MICROSOFT:
                showConnectMS();
                break;
            case RESPONSE_SERVER.USER_NOT_FOUND:
                setError("Utilisateur non enregistré !");
                break;
            case RESPONSE_SERVER.ASK_PASSWORD:
                showAskPassword();
                break;
            case RESPONSE_SERVER.ASK_ADD_MICROSOFT_ACCOUNT:
                showAddMicrosoftAccount(data?.code);
                break;
            default:
                setError("Email mal formaté !");
                break;
        }

        clientQuery.resetQueries(QueryKey).then(() => {});
    }

    if (data?.success === false) {
        setError("Veuillez vérifier vos identifiants.");
        clientQuery.resetQueries(QueryKey).then(() => {});
    }

    const render_text_depending_step = () => {
        if (INIT) {
            return (
                <Text
                    content={"Se connecter avec votre compte pro Microsoft®"}
                />
            );
        }
        if (ASK_PASSWORD) {
            return (
                <Flex column styles={{ gap: "40px" }}>
                    <Image styles={{ maxHeight: "180px" }} src={Signin} />
                    <Text content={"Se connecter avec son email"} />
                </Flex>
            );
        }
        return <Text content={"Se connecter à son compte"} />;
    };

    return (
        <StackStyle>
            <CardStyle column vAlign="center">
                {/* logo Asendit */}
                <Flex column hAlign="center">
                    {/* logo */}
                    <Flex
                        style={{
                            marginBottom: "16px",
                        }}
                    >
                        <Image width={200} src={Logo_yeetch} alt="logo" />
                    </Flex>
                    <Flex
                        column
                        styles={{
                            gap: "16px",
                            width: "315px",
                        }}
                    >
                        <MSconnectText content={render_text_depending_step()} />
                        {INIT && (
                            <MsDescription
                                content={
                                    "Pour bénéficier d'un maximum de fonctionnalités"
                                }
                            />
                        )}
                    </Flex>
                    <Flex column styles={{ marginTop: "24px" }}>
                        {/* {INIT && (
                        <DescriptionStyle content="Connectez-vous avec votre compte Microsoft pour bénéficier de toutes les fonctionnalités :" />
                    )} */}

                        {/* INIT SCREEN */}
                        {INIT && (
                            <InitLogin
                                email={email}
                                error={error}
                                setEmail={setEmail}
                                handleSubmit={handleSubmit}
                                refEmail={refEmail}
                                isLoading={isLoading}
                                isFetching={isFetching}
                            />
                        )}
                        {/* CONNECT WITH MS */}
                        {ASK_ADD_MICROSOFT_ACCOUNT && (
                            <ConnectMs code={codeForMs} email={email} />
                        )}
                        {/* FORCE TO CONNECT WITH MS */}
                        {CONNECT_WITH_MICROSOFT && (
                            <ConnectForceMs email={email} />
                        )}
                        {/* GIVE ME PASSWORD */}
                        {ASK_PASSWORD && <AskPassword email={email} />}
                    </Flex>
                </Flex>
            </CardStyle>
        </StackStyle>
    );
};

export default withGuestOnly(Login);

const MSconnectText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
`;

const LogoTitle = styled(Text)`
    font-family: "Fredoka";
    font-style: normal;
    font-weight: 500;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -0.085em;
`;

const DesktopVersion = styled(Flex)`
    display: none;

    @media ${DEVICE.mobileS} {
    }
    @media ${DEVICE.mobileS} {
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: block;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("https://images.pexels.com/photos/509922/pexels-photo-509922.jpeg");
    }
`;
const Title = styled(Text)`
    @media ${DEVICE.mobileS} {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #242424;
        /* padding-inline: 6px; */
    }
    @media ${DEVICE.mobileS} {
        text-align: center;
        margin-block: 14px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
        /* margin-top: 70px; */
        text-align: left;
        /* align-items: start;
    justify-content: start; */
    }
    @media ${DEVICE.laptop} {
        /* margin-top: 14px; */
        /* align-items: start; */
        /* text-align: center; */
    }
`;

const DescriptionStyle = styled(Text)`
    @media ${DEVICE.mobileS} {
        margin-block: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #424242;
        width: 240px;
    }
    @media ${DEVICE.mobileM} {
        /* font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #424242;
    text-align: center; */
    }
    @media ${DEVICE.mobileL} {
        /* border: 1px solid pink; */
    }
    @media ${DEVICE.laptop} {
        text-align: left;
        margin: 0px;
        width: 315px;
        color: #424242;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
`;

const ImageFlex = styled(Flex)`
    @media ${DEVICE.mobileS} {
        /* border: 1px solid red; */
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.laptop} {
    }
`;
const ImageStyle = styled(Image)`
    @media ${DEVICE.mobileS} {
        /* border: 1px solid red; */
    }
    @media ${DEVICE.mobileM} {
        /* margin-top: 10px; */
        /* width: 180px;
    height: 60px; */
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.laptop} {
        height: 70px;
    }
`;

const CardStyle = styled(Flex)`
    /* border: 5px solid magenta; */
    background-color: white;
    /* width: 288px; */
    box-shadow: 0px 2.4px 7.4px rgba(0, 0, 0, 0.18),
        0px 12.8px 28.8px rgba(0, 0, 0, 0.22);
    border-radius: 8px;

    @media ${DEVICE.mobileS} {
        min-width: 200px;
        padding: 24px 12px;
        min-height: 600px;
    }
    @media ${DEVICE.mobileM} {
        padding: 24px 12px;
        /* margin-inline: 12px; */
        /* margin: 20px; */
        /* height: 800px; */
        min-width: 300px;
    }
    @media ${DEVICE.mobileL} {
        /* height: 580px; */
        width: 380px;
    }
    @media ${DEVICE.laptop} {
        /* width: 568px;
        height: 507px; */
        padding-block: 26px;
        min-width: 450px;
        /* min-height: fit-content; */
    }
`;

export const StackStyle = styled(Flex)`
    background-image: url("/bg/background-login.svg");
    background-size: cover;

    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px rgba(0, 0, 0, 0.13);

    @media ${DEVICE.mobileS} {
        width: 100%;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.mobileM} {
        justify-content: center;
        align-items: center;
        min-width: fit-content;
    }

    @media ${DEVICE.laptop} {
        justify-content: center;

        min-height: 100dvh;
    }
    @media ${DEVICE.desktop} {
    }
`;

const MsDescription = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;
